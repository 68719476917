
import { AppContext } from "@/util/context";
import { Component, Vue } from "vue-property-decorator";
import { LoginResponse, NotificationType } from "@/util/scopevisiotypes";
import { persistState } from "@/store/store";
import { showNotification } from "@/util/eventbus";
import store from "@/store/store";
import { Util } from "@/util/util";

@Component
export default class PasswordLogin extends Vue {
    customer: string | null = null;
    organisation: string | null = null;
    password: string | null = null;
    rules = {
        required: (value: any) => !!value || "",
        email: (value: any) => Util.isEmailValid(value) || 'Der Benutzername ist keine gültige E-Mail Adresse'
    };
    saveLogin = false;
    showPassword = false;
    username: string | null = null;

    beforeUnmount() {
    }

    async mounted() {
        if (!AppContext.instance) {
            this.$router.push("/")
        }
    }
    
    async onLogin() {
        if (store.state.connected) {
            return;
        }

        let response: Response | null = null;
        try {
            response = await AppContext.instance.callFormPost("token", {
                customer: this.customer,
                username: this.username,
                password: this.password,
                grant_type: "password",
                requestcookie: true,
            });
        } catch (e) {
            console.error("failed to invoke token call: " + e);
            showNotification({
                type: NotificationType.ERROR,
                message: "Internal-Error: " + e,
            });
            return false;
        }
        if (!response.ok) {
            showNotification({
                type: NotificationType.ERROR,
                message:"Bei der Anmeldung ist ein Fehler aufgetreten.",
            })
            throw new Error("call returned with " + response.status)
        }
        const loginResponse: LoginResponse = await response.json();
        try {
            await store.dispatch("switchOrganisation", loginResponse);
        } catch (err) {
            showNotification({
                type: NotificationType.ERROR,
                message:"Bei der Anmeldung ist ein Fehler aufgetreten.",
            })
            return;
        }
        
        if (this.saveLogin) {
            persistState(store.state)
        }
        
        this.$router.push("/scanner")
    }
}
