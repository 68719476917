/*
 * (c) Scopevisio 2019; all rights reserved
 */

/*
 * central global event bus
 */
import Vue from 'vue'
import { NotificationInfo, ConfirmInfo, SaveInfo } from './scopevisiotypes'

export const ACTION_LOGIN = "action.login"
export const ACTION_NOTIFICATION = "action.notification"
export const ACTION_CONFIRM = "action.confirm"
export const ACTION_SHOWSEARCH = "action.showsearch"
export const ACTION_SAVE = "action.save"

export const EVENT_BUS = new Vue()

export function showNotification(notificationInfo: Partial<NotificationInfo>) {
    EVENT_BUS.$emit(ACTION_NOTIFICATION, notificationInfo)
}

export function showConfirm(confirmInfo: Partial<ConfirmInfo>) {
    EVENT_BUS.$emit(ACTION_CONFIRM, confirmInfo)
}

export function showSave(saveInfo: Partial<SaveInfo>) {
    EVENT_BUS.$emit(ACTION_SAVE, saveInfo)
}