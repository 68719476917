
import { AppContext } from "@/util/context";
import { CenterdeviceCache } from "@/util/centerdevicecache";
import {
    CenterdeviceCollection,
    CenterdeviceDocument,
    DocumentsRequest,
    DocumentsResponse,
    SortField,
    SortOrder,
} from "@/util/centerdevice";
import { CenterdeviceUtil } from "@/util/centerdeviceutil";
import { Component, Vue } from "vue-property-decorator";
import ContentWrapper from "@/components/ContentWrapper.vue";
import LoadingTransition from "@/components/LoadingTransition.vue";
import EmptyImage from "../../public/images/empty.png";
import FilterIcon from "../../public/images/filter.svg";
import LargeGridIcon from "../../public/images/grid.svg";
import LargeGridIconYellow from "../../public/images/grid-primarycolor.svg";
import ListIcon from "../../public/images/multiline-text.svg";
import ListIconYellow from "../../public/images/multiline-text-primarycolor.svg";
import MenuDotted from "../../public/images/menu-dotted.svg";
import Search from "../../public/images/search-white.svg";
import SmallGridIcon from "../../public/images/data-grid.svg";
import SmallGridIconYellow from "../../public/images/data-grid-primarycolor.svg";
import { StateType } from "@/store/store";
import { Store } from "vuex";
import { Util } from "@/util/util";

export enum SubinformationContent {
    DOCUMENT_SIZE,
    VERSION_DATE,
}

export const LARGETHUMBPREFIX = "docimage";
export const SMALLTHUMBPREFIX = "docthumb";
export const NORMALPREFIX = "docicon";
export const PAGESIZE = 100;

@Component({
    components: {
        ContentWrapper,
        LoadingTransition,
    },
})
export default class DocumentsView extends Vue {
    callSequence = 1;
    collections: CenterdeviceCollection[] = [];
    documents: CenterdeviceDocument[] = [];
    documentsResponse: DocumentsResponse = {
        hits: 0,
        documents: [],
    };
    emptyImageUrl = EmptyImage;
    filterIconUrl = FilterIcon;
    largeGridIconUrl = LargeGridIcon;
    largeGridIconUrlYellow = LargeGridIconYellow;
    listIconUrl = ListIcon;
    listIconUrlYellow = ListIconYellow;
    menudotted = MenuDotted;
    offset = 0;
    options = ["Name - A bis Z", "Name - Z bis A", "Datum - Neueste zuerst", "Datum - Äteste zuerst"];
    search = Search;
    selectedCollection: string | null = null;
    selectedSortField = "";
    selectedSortMode = "";
    selectedSortOrder = "";
    selectedViewMode = "";
    showFilter = true;
    showFooterRegion = true;
    showQueryRegion = false;
    sortField: SortField = "version-date";
    sortOrder: SortOrder = "desc";
    smallGridIconUrl = SmallGridIcon;
    smallGridIconUrlYellow = SmallGridIconYellow;
    subinformationContent = SubinformationContent.DOCUMENT_SIZE;
    query: DocumentsRequest | null = null;
    queryText = "";
    fileOptions = [
        {
            title: "Datei Ansehen",
            onClick: (document: CenterdeviceDocument, index: number) => {
                this.documentClicked(document, index);
            },
        },
        {
            title: "Option 2",
            onClick: (document: CenterdeviceDocument, index: number) => {
                this.documentClicked(document, index);
            },
        },
    ];

    async mounted() {
        const storeDocumentsView = this.$store as Store<StateType>;
        this.collections = this.getCollections();

        const coll = CenterdeviceCache.instance.collections.find(
            (e) => e.name == "Spesen2GoFiles"
         );
         this.selectedCollection = coll!.id
        //this.selectedCollection = storeDocumentsView.state.currentCollectionId;

        this.selectedSortMode = storeDocumentsView.state.currentSortMode;
        if (this.selectedSortMode == "") {
            this.selectedSortMode = "Name - A bis Z";
            this.selectedSortField = "filename";
            this.selectedSortOrder = "desc";
            storeDocumentsView.commit("currentSortMode", this.selectedSortMode);
        }
        this.sortChanged();
        this.selectedViewMode = storeDocumentsView.state.currentViewMode;
        if (this.selectedViewMode == "") {
            this.selectedViewMode = "list";
            storeDocumentsView.commit("currentViewMode", this.selectedViewMode);
        }
        this.selectedViewMode = storeDocumentsView.state.currentViewMode;
        this.doSearch();
        this.handleScrolling();

        console.log(this.$refs);
    }

    collectionChange() {
        const storeCollection = this.$store as Store<StateType>;
        storeCollection.commit("currentCollectionId", this.selectedCollection);
        this.doSearch();
    }

    computeSubinformationForListView(document: CenterdeviceDocument) {
        if (this.subinformationContent == SubinformationContent.DOCUMENT_SIZE) {
            return Util.formatSize(document.size);
        } else if (this.subinformationContent == SubinformationContent.VERSION_DATE) {
            return Util.formatDate(new Date(document["version-date"]).getTime());
        }
        return "";
    }

    documentClicked(document: CenterdeviceDocument, index: number) {
        console.log("open " + index + " " + document.filename + " " + document.id);

        // load full copy of the document
        const loadingTransition = this.$refs.loadingtransition as LoadingTransition;
        const accessToken = AppContext.instance.getAccessToken();

        loadingTransition.enable();
        AppContext.instance
            .callGet(
                "teamworkbridge/document/" + document.id, // + "?includes=version-details,users,groups,collections",
                {
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken,
                },
                {}
            )
            .then((response) => {
                return response.json();
            })
            .then((v: any): void => {
                loadingTransition.disable();
                //console.log("--->" + JSON.stringify(activeDocument));
                this.$router.push({
                    name: "Details des Dokuments",
                    params: {
                        selectedDoc: v,
                    },
                });
            })
            .catch((reason) => {
                loadingTransition.disable();
                console.error("failed to load full document: " + reason);
            });
    }

    doSearch(reset?: boolean) {
        if (reset) {
            this.offset = 0;
        }
        this.doSearchWorker();
    }

    async doSearchWorker(): Promise<DocumentsResponse | null> {
        const cache = CenterdeviceCache.instance;
        await cache.build();
        const myCallSequence = this.callSequence;
        this.query = {
            action: "search",
            params: {
                offset: this.offset,
                rows: PAGESIZE,
                query: {
                    text: this.queryText || "",
                },
                sort: {
                    field: this.sortField,
                    order: this.sortOrder,
                },
                filter: {
                    size: {},
                },
            },
        };
        //empty-selection got the value -1
        if (this.selectedCollection && this.selectedCollection != "-1") {
            const collectionsArr = [this.selectedCollection];
            this.query.params.filter.collections = collectionsArr;
        }
        const headers = AppContext.instance.getDefaultHeaders();
        const response = await AppContext.instance.callJsonPost("teamworkbridge/documents", headers, this.query);
        if (!response.ok) {
            console.error("search failed: " + response.status + " " + response.statusText);
        }
        this.callSequence++;
        const raw = await response.json();
        if (myCallSequence != this.callSequence - 1) {
            /* overtaken result from earlier call, discard */
            console.log("query discarded: " + this.queryText);
            return null;
        }
        this.documentsResponse = raw as DocumentsResponse;
        
        if (this.offset <= 0) {
            this.documents.splice(0);
        }
        if (this.documentsResponse.documents) {
            for (let i = 0, n = this.documentsResponse.documents.length; i < n; i++) {
                this.documents.push(this.documentsResponse.documents[i]);
            }
        }
        this.$nextTick(() => {
            this.loadVisibleThumbs();
        });
        return this.documentsResponse;
    }

    formatIcon(document: CenterdeviceDocument) {
        return ["documenttype-icon", CenterdeviceUtil.formatIcon(document.filename)];
    }

    getCollections(): CenterdeviceCollection[] {
        const empty: CenterdeviceCollection = {
            id: "-1",
            name: "Alle Sammlungen",
            owner: "",
            public: true,
        };
        const collections: CenterdeviceCollection[] = [...CenterdeviceCache.instance.collections];
        collections.push(empty);
        return collections;
    }

    getViewMode() {
        return "list";
    }

    handleScrolling() {
        const pane = this.$el.querySelector("#documentspane");
        if (!pane) {
            return;
        }
        let last_known_scroll_position = 0;

        const doSomething = (pos: number) => {
            const bottom = pane.scrollHeight <= Math.ceil(pos) + pane.clientHeight;
            if (bottom) {
                this.nextPage();
            }
        };
        let ticking = false;
        pane.addEventListener("scroll", (e) => {
            last_known_scroll_position = pane.scrollTop;
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    doSomething(last_known_scroll_position);
                    ticking = false;
                });
                ticking = true;
            }
        });
    }

    /* called via vue, large grid selected */
    largeGridClicked() {
        this.selectedViewMode = "largegrid";
        const storeViewMode = this.$store as Store<StateType>;
        storeViewMode.commit("currentViewMode", this.selectedViewMode);
        this.offset = 0;
        this.loadVisibleThumbs();
    }

    /* called via vue, list view selected */
    listClicked() {
        this.selectedViewMode = "list";
        const storeViewMode = this.$store as Store<StateType>;
        storeViewMode.commit("currentViewMode", this.selectedViewMode);
        this.offset = 0;
        this.loadVisibleThumbs();
    }

    loadSinglePreviewImage(document: CenterdeviceDocument, size: number, prefix: string): void {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            Authorization: "Bearer " + accessToken,
            Accept: "image/jpeg, image/png",
        };
        //let imageElement = window.document.getElementById() as HTMLImageElement;
        const imageElement = this.$el.querySelector("#" + prefix + "_" + document.id) as HTMLImageElement;
        if (!imageElement) {
            return;
        }
        if (imageElement.getAttribute("data-loaded") == document.id) {
            return;
        }
        imageElement.setAttribute("data-loaded", document.id);
        imageElement.src = EmptyImage;
        AppContext.instance
            .callGet(
                "teamworkbridge/document/" + document.id + ";preview=" + size + "x" + size + ";pages=1",
                headers,
                {}
            )
            .then((res) => res.blob())
            .then((blob) => {
                if (imageElement && imageElement.parentElement) {
                    imageElement.parentElement.style.opacity = "1";
                    imageElement.parentElement.style.transform = "none";
                    imageElement.src = URL.createObjectURL(blob);
                }
            });
    }

    /* reload the thumbs/Preview-images via fetch from the server */
    loadVisibleThumbs() {
        // if we make a new search (offset = 0 means just that)
        // we must reset the thumbs
        if (this.selectedViewMode == "largegrid") {
            if (this.offset == 0) {
                this.$el.querySelectorAll("." + LARGETHUMBPREFIX + "-parent").forEach((element) => {
                    (element as HTMLElement).style.transitionDuration = "0ms";
                    (element as HTMLElement).style.opacity = "0";
                    (element as HTMLElement).style.transform = "translateY(-50%) rotateX(90deg)";
                    (element as HTMLElement).style.transitionDuration = "250ms";
                });
                this.$el.querySelectorAll("." + LARGETHUMBPREFIX + "-image").forEach((element) => {
                    element.removeAttribute("data-loaded");
                });
            }
            for (let i = 0, n = this.documents.length; i < n; i++) {
                const document = this.documents[i];
                this.loadSinglePreviewImage(document, 240, LARGETHUMBPREFIX);
            }
        } else if (this.selectedViewMode == "smallgrid") {
            if (this.offset == 0) {
                this.$el.querySelectorAll("." + SMALLTHUMBPREFIX + "-parent").forEach((element) => {
                    (element as HTMLElement).style.transitionDuration = "0ms";
                    (element as HTMLElement).style.opacity = "0";
                    (element as HTMLElement).style.transform = "translateY(-50%) rotateX(90deg)";
                    (element as HTMLElement).style.transitionDuration = "250ms";
                });
                this.$el.querySelectorAll("." + SMALLTHUMBPREFIX + "-image").forEach((element) => {
                    element.removeAttribute("data-loaded");
                });
            }
            for (let i = 0, n = this.documents.length; i < n; i++) {
                const document = this.documents[i];
                this.loadSinglePreviewImage(document, 120, SMALLTHUMBPREFIX);
            }
        } else if (this.selectedViewMode === "list") {
            if (this.offset == 0) {
                this.$el.querySelectorAll("." + NORMALPREFIX + "-parent").forEach((element) => {
                    (element as HTMLElement).style.transitionDuration = "0ms";
                    (element as HTMLElement).style.opacity = "0";
                    (element as HTMLElement).style.transform = "translateY(-50%) rotateX(90deg)";
                    (element as HTMLElement).style.transitionDuration = "250ms";
                });
                this.$el.querySelectorAll("." + NORMALPREFIX + "-image").forEach((element) => {
                    element.removeAttribute("data-loaded");
                });
            }
            for (let i = 0, n = this.documents.length; i < n; i++) {
                const document = this.documents[i];
                this.loadSinglePreviewImage(document, 120, NORMALPREFIX);
            }
        }
    }

    nextPage() {
        if (this.query && this.query.params.offset + PAGESIZE < this.documentsResponse.hits) {
            this.offset += PAGESIZE;
            this.doSearch();
        }
    }

    /* called via vue, small grid selected */
    smallGridClicked() {
        this.selectedViewMode = "smallgrid";
        const storeViewMode = this.$store as Store<StateType>;
        storeViewMode.commit("currentViewMode", this.selectedViewMode);
        this.offset = 0;
        this.loadVisibleThumbs();
    }

    sortChanged() {
        const storeDocumentsView = this.$store as Store<StateType>;
        switch (this.selectedSortMode) {
            case "Name - A bis Z":
                this.sortField = "filename";
                this.sortOrder = "asc";
                storeDocumentsView.commit("currentSortMode", this.selectedSortMode);
                break;
            case "Name - Z bis A":
                this.sortField = "filename";
                this.sortOrder = "desc";
                storeDocumentsView.commit("currentSortMode", this.selectedSortMode);
                break;
            case "Datum - Neueste zuerst":
                this.sortField = "version-date";
                this.sortOrder = "desc";
                storeDocumentsView.commit("currentSortMode", this.selectedSortMode);
                break;
            case "Datum - Äteste zuerst":
                this.sortField = "version-date";
                this.sortOrder = "asc";
                storeDocumentsView.commit("currentSortMode", this.selectedSortMode);
                break;
            default:
                console.error("unhandled sort");
        }
        this.offset = 0;
        this.doSearch(true);
    }

    /* called via vue when the query input has changed */
    queryChanged() {
        this.doSearch(true);
    }

    formatDate(date: Date) {
        return date.toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    }
}
