
import { Component, Vue } from "vue-property-decorator";
import { redirectDisconnected } from "@/router";
import { StateType } from "@/store/store";
import { Store } from "vuex";

@Component
export default class Documents extends Vue {
    mounted() {
        const store = this.$store as Store<StateType>
        if (redirectDisconnected(store.state.connected)) {
            return
        }
    }
}
