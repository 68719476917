
import store from "@/store/store";
import { AppContext } from "@/util/context";
import { TravelEntry, TravelEntryResponse } from "@/util/scopevisiotypes";
import { Component, Vue, Prop } from "vue-property-decorator";
import Done from "../../public/images/done.svg";
import { MetadataAddExtra } from "@/util/centerdevice";
import { ScopeUtil } from "@/util/scopeutil";
import { StateType } from "@/store/store";
import { Store } from "vuex";

@Component
export default class DocumentDetails extends Vue {
  // Parameter fehlen noch
  // Muss noch metadata und savedmetadata liefern
  @Prop({ type: Object as () => MetadataAddExtra })
  done = Done;
  travelEntryId: any;

  selectedItem = 1;
  items: TravelEntry[] = [];

    @Prop({ type: Object as () => MetadataAddExtra }) readonly actualMetadata!: MetadataAddExtra;
    @Prop({ type: Object as () => MetadataAddExtra }) readonly savedActualMetadata!: MetadataAddExtra;

    savedMetadata: MetadataAddExtra = {
        Scopevisio_TravelEntry_CompanyName: "",
        Scopevisio_TravelEntry_ReceiptDate: "",
        Scopevisio_TravelEntry_ReceiptNumber: "",
        Scopevisio_TravelEntry_Amount: "",
        Scopevisio_TravelEntry_TaxAmount: "",
        Scopevisio_TravelEntry_Rate: "",
        Scopevisio_TravelEntry_ReceiptType: "",
        Scopevisio_TravelEntry_TravelAbsenceDuration: "",
        Scopevisio_TravelEntry_TravelNumberOfNights: "",
        Scopevisio_TravelEntry_TravelCountry: "",
        Scopevisio_TravelEntry_ProjectId: "",
        Scopevisio_TravelEntry_Refundable: "",
        Scopevisio_TravelEntry_Type: undefined,
        Scopevisio_TravelEntry_RefundOption: "",
        "Scopevisio_Eigene Gesellschaft": "",
        Scopevisio_TravelEntry_PositionValue: "",
    };

        metadata: MetadataAddExtra = {
        Scopevisio_TravelEntry_CompanyName: "",
        Scopevisio_TravelEntry_ReceiptDate: "",
        Scopevisio_TravelEntry_ReceiptNumber: "",
        Scopevisio_TravelEntry_Amount: "",
        Scopevisio_TravelEntry_TaxAmount: "",
        Scopevisio_TravelEntry_Rate: "",
        Scopevisio_TravelEntry_ReceiptType: "",
        Scopevisio_TravelEntry_TravelAbsenceDuration: "",
        Scopevisio_TravelEntry_TravelNumberOfNights: "",
        Scopevisio_TravelEntry_TravelCountry: "",
        Scopevisio_TravelEntry_ProjectId: "",
        Scopevisio_TravelEntry_Refundable: "",
        Scopevisio_TravelEntry_Type: undefined,
        Scopevisio_TravelEntry_RefundOption: "",
        "Scopevisio_Eigene Gesellschaft": "",
        Scopevisio_TravelEntry_PositionValue: "",
    }; 

  methods(){
      
  }

    async mounted() {        
        this.metadata = this.actualMetadata;
        this.savedMetadata = this.savedActualMetadata;
        const storeAll = this.$store as Store<StateType>;
        storeAll.commit("routedFromSubSite", true);
        this.travelEntryId = this.metadata.Scopevisio_TravelEntry_ProjectId;
        const accessToken = AppContext.instance.getAccessToken();
        const accInfo = await ScopeUtil.getMyAccountInfos(
            {
            Authorization: "Bearer " + accessToken,
            }
        );
        const travelEntryResponse = await ScopeUtil.getTravelEntries(
            {
            Authorization: "Bearer " + accessToken,
            }
        );
        const userName = accInfo.user.firstName + " " + accInfo.user.lastName;
        for (let i = 0; i < travelEntryResponse.records.length; i++) {
            if(userName == travelEntryResponse.records[i].employeeName){
            this.items.push(travelEntryResponse.records[i]);
            console.log(this.items)
            }
        }
    }  

    onItemClick(item: any) {
        this.metadata.Scopevisio_TravelEntry_ProjectId = item.note;
        store.state.selectedTravelEntry = item
        this.$router.push({
            name: "Details des Dokuments",
            params: {
            subMetadata: this.metadata as any,
            subSavedMetadata: this.savedMetadata as any,
            },
        });
    }
}
