/*
 * (c) Scopevisio 2019, all rights reserved
 */

/*
 * scopevisio types
 *
 * these interfaces and classes are types that are either input for
 * the scopevisio apis or returned by the api
 */

export enum NotificationType { ERROR, WARNING, INFO }

export interface SaveInfo {
    saveButton: boolean;
}

export interface NotificationInfo {
    type: NotificationType;
    message: string;
    timeoutMs?: number;
}

export interface ConfirmInfo {
    message: string;
    onSuccess: () => void;
    onCancel?: () => void;
    hideCancelButton?: boolean;
}

export interface Credentials {

    customer: string;
    username: string;
    password: string;
    organisation?: string;
    saveLogin?: boolean;
    baseUrl?: string;
}

export interface CustomerInfo {
    customer: string;
    username: string;
}

export interface LoginResponse {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    organisationId: number;
    organisationName: string;
    teamworkTenantId: string;
    token_type: string;
    uid: string;
}

export interface MyAccountResponse {
    customer: {
        name: string;
        publicId: string;
        status: string;
    };
    organisation: {
        id: number;
        name: string;
        teamworkTenantId: string;
        teamworkTenantName: string;
    };
    teamwork: {
        teamworkAppRootUrl: string;
    };
    user: {
        firstName: string;
        lastName: string;
        login: string;
        status: string;
        uid: string;
    };
}

export interface OrganisationsResponse {
    records: Organisation[];
}

export interface HypatosResponse {
    access_token: string;
    scope: string;
    session_state: string;
    token_type: string;
    entities: {
        currency: string;
        date: string;
        tip: string;
        payment: {
            amount: string;
            method: string;
        };
        vendor: {
            address: string;
            city: string;
            companyName: string;
            receiptNumber: string;
        };
        totals: {
            gross: number;
            net: number;
            tax: [{
                amount: string;
                net: number;
                rate: string;

            }];
        };
    };
}

export interface Organisation {
    id: number;
    name: string;
}

export interface ManagedDataEntry {
    id: number;
    key: string;
    value: string;
}

export interface TravelEntryResponse {
    records: TravelEntry[];
}

export interface TravelEntry {
    "id": number;
    "legacynumber": string;
    "organisation": number;
    "createdTs": number;
    "creatorUid": string;
    "creatorName": string;
    "modifierUid": string;
    "modifierName": string;
    "modifiedTs": number;
    "zoneId": number;
    "zoneNumber": string;
    "zoneName": string;
    "employeeId": number;
    "employeeName": string;
    "accountType": string;
    "contactId": number;
    "contactName": string;
    "projectId": number;
    "projectName": string;
    "orderId": number;
    "orderDisplay": string;
    "documentDate": number;
    "documentNumber": string;
    "billable": boolean;
    "startTs": number;
    "startDateTs": number;
    "startTimeTs": number;
    "endTs": number;
    "endDateTs": number;
    "endTimeTs": number;
    "duration": number;
    "note": string;
    "travelEntryRunId": number;
    "travelEntryRunType": number;
    "travelEntryRunTypeDisplay": string;
    "travelEntryRunDoneUserDisplay": string;
    "travelEntryRunDoneUid": string;
    "travelEntryRunDoneTs": number;
    "ignoreTravelEntryRun": boolean;
    "ignoreTravelEntryRunTs": number;
    "ignoreTravelEntryRunUid": string;
    "ignoreTravelEntryRunUserDisplay": string;
    "confirmed": boolean;
    "confirmedDisplay": string;
    "confirmedTs": number;
    "confirmedUid": string;
    "confirmedUserDisplay": string;
    "errorLevel": number;
    "errorMessage": string;
    "internalNote": string;
    "stateDone": boolean;
    "allowanceCountry": string;
    "allowanceRegion": string;
    "city": string;
    "country": string;
    "street": string;
    "postcode": string;
    "federalState": string;
    "addressExtra": string;
    "addressEdited": string;
    "startContactId": number;
    "startContactName": string;
    "startCity": string;
    "startCountry": string;
    "startStreet": string;
    "startPostcode": string;
    "startFederalState": string;
    "startAddressExtra": string;
    "startAddressEdited": string;
    "distance": number;
    "dimensionNumber1": number;
    "dimensionName1": string;
    "dimensionNumber2": number;
    "dimensionName2": string;
    "dimensionNumber3": number;
    "dimensionName3": string;
    "dimensionNumber4": number;
    "dimensionName4": string;
    "dimensionNumber5": number;
    "dimensionName5": string;
    "dimensionNumber6": number;
    "dimensionName6": string;
    "dimensionNumber7": number;
    "dimensionName7": string;
    "dimensionNumber8": number;
    "dimensionName8": string;
    "dimensionNumber9": number;
    "dimensionName9": string;
    "dimensionNumber10": number;
    "dimensionName10": string;
    "positionsAmount": number;
    "advanceAmount": number;
    "amount": number;
    "invoiceId": number;
    "invoiceDocumentNumber": string;
    "invoiceLeadFileId": string;
    "invoiceLeadFileIdDisplay": string;
    "permissionDisplay": string;
    "ownerUid": string;
    "permissionType": number;
    "readPermissionProfiles": string;
    "readPermissionUids": string;
    "writePermissionProfiles": string;
    "writePermissionUids": string;
    "entryType": number;
    "entryTypeDisplay": string;
}