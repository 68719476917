/*
 * (c) Scopevisio 2019, all rights reserved
 */

import Vue from 'vue';
import { Util } from '../util/util';
import { Store } from 'vuex';
import { StateType } from '@/store/store';

export type StringMap = {
    [P in string]: string;
}

/*
 * global application context, contains central state
 */

const STORAGEKEY_ACCESS_TOKEN = "sv.access_token"
const CREDENTIALS_MODE = 'omit'   // omit or include

export class AppContext {

    /* singleton instance */
    static instance: AppContext


    store: Store<StateType>
    baseUrl = "https://appload.scopevisio.com/rest"

    //localhost
    //baseUrl = "http://localhost:30000/rest"

    //android emulator
    //baseUrl = "http://10.0.2.2:30000/rest"
    
    restClientId = "documentapp2"


    constructor(store: Store<StateType>) {
        this.store = store
    }

    isDebug() { return !true }

    makeUrl(path: string) {
        return this.baseUrl + "/" + path;
    }

    makeSoapUrl(path: string) {
        //return "http://localhost:4000/api/soap/" + path;
        return "https://appload.scopevisio.com/api/soap/" + path; 
    }

    getDefaultHeaders() {
        const accessToken = this.store.state.loginResponse.access_token
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
        return headers
    }

    getAccessToken() { 
        return this.store.state.loginResponse.access_token;
    }

    isConnected() {
        return this.store.state.connected;
    }

    /*
    removeToken() {
        window.localStorage.removeItem(STORAGEKEY_ACCESS_TOKEN);
        console.log("removed access-token");
    }

    saveToken() {
        if (!this.store.state.loginResponse || !this.store.state.loginResponse.access_token) {
            return;
        }
        let token = this.store.state.loginResponse.access_token;
        window.localStorage.setItem(STORAGEKEY_ACCESS_TOKEN, token);
        console.log("save token: " + (token ? token.substring(0, 4) + "..." : null))
    }

    getToken() {
        let token = window.localStorage.getItem(STORAGEKEY_ACCESS_TOKEN);
        console.log("returning token: " + (token ? token.substring(0, 4) + "..." : null))
        return token;
    }
    */



    async callGet(path: string, headers: StringMap, args: any): Promise<Response> {
        return this.callSimple(path, "GET", headers, args);
    }

    async callPut(path: string, headers: StringMap, args: any): Promise<Response> {
        return this.callSimple(path, "PUT", headers, args);
    }

    async callDelete(path: string, headers: StringMap): Promise<Response> {
        return this.callSimple(path, "DELETE", headers, null);
    }

    private async callSimple(path: string, method: string, headers: StringMap, args: any): Promise<Response> {
        const fetchHeaders: Record<string, string> = {
            "Content-Type": "application/json",
            //"X-Requested-With": "XMLHttpRequest",
        };
        if (headers) {
            for (const k of Object.keys(headers)) {
                const v = headers[k];
                if (v) {
                    fetchHeaders[k] = v;
                }
            }
        }
        let url = AppContext.instance.makeUrl(path);
        const query = Util.formEncode(args);
        if (query)
            url += "?" + query;
        const response = fetch(
            url,
            {
                method: method,
                credentials: CREDENTIALS_MODE,
                headers: fetchHeaders,
            });
        return response;
    }

    async callFormPost(path: string, args: any): Promise<Response> {
        const response = fetch(
            AppContext.instance.makeUrl(path),
            {
                method: 'POST',
                credentials: CREDENTIALS_MODE,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    //"X-Requested-With": "XMLHttpRequest",
                },
                body: Util.formEncode(args)
            });
        return response;
    }

    async callFormPostHypatos(url: string, args: any): Promise<Response> {
        const response = fetch(
            url,
            {
                method: 'POST',
                credentials: CREDENTIALS_MODE,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    //"X-Requested-With": "XMLHttpRequest",
                },
                body: Util.formEncode(args)
            });
        return response;
    }

    async getImageInfoFromHypatos(url: string, img: any, token: string): Promise<Response> {
        const response = fetch(
            url,
            {
                method: 'POST',
                credentials: CREDENTIALS_MODE,
                headers: {
                    "Content-Type": "image/jpeg",
                    "Authorization": "bearer " + token,
                },
                body: img
            });
        return response;
    }

    async callJsonPut(path: string, headers: StringMap, args: any): Promise<Response> {
        const fetchHeaders: Record<string, string> = {
            "Content-Type": "application/json",
            //"X-Requested-With": "XMLHttpRequest",
        };
        if (headers) {
            for (const k of Object.keys(headers)) {
                const v = headers[k];
                if (v) {
                    fetchHeaders[k] = v;
                }
            }
        }
        const response = fetch(
            AppContext.instance.makeUrl(path),
            {
                method: 'PUT',
                credentials: CREDENTIALS_MODE,
                headers: fetchHeaders,
                body: JSON.stringify(args)

            });
        return response;
    }

    async callJsonPost(path: string, headers: StringMap, args: any): Promise<Response> {
        const fetchHeaders: Record<string, string> = {
            "Content-Type": "application/json",
            //"X-Requested-With": "XMLHttpRequest",
        };
        if (headers) {
            for (const k of Object.keys(headers)) {
                const v = headers[k];
                if (v) {
                    fetchHeaders[k] = v;
                }
            }
        }
        const response = fetch(
            AppContext.instance.makeUrl(path),
            {
                method: 'POST',
                credentials: CREDENTIALS_MODE,
                headers: fetchHeaders,
                body: JSON.stringify(args)

            });
        return response;
    }


    async callRawPost(path: string, headers: StringMap, raw: any): Promise<Response> {
        const fetchHeaders: Record<string, string> = { };
        if (headers) {
            for (const k of Object.keys(headers)) {
                const v = headers[k];
                if (v) {
                    fetchHeaders[k] = v;
                }
            }
        }
        const response = fetch(
            AppContext.instance.makeUrl(path),
            {
                method: 'POST',
                credentials: CREDENTIALS_MODE,
                headers: fetchHeaders,
                body: raw
            });
        return response;
    }

    async callRawPostSoap(path: string, headers: StringMap, raw: any): Promise<Response> {
        const fetchHeaders: Record<string, string> = {
            "Content-Type": "application/xml",
        };
        if (headers) {
            for (const k of Object.keys(headers)) {
                const v = headers[k];
                if (v) {
                    fetchHeaders[k] = v;
                }
            }
        }
        const response = fetch(
            AppContext.instance.makeSoapUrl(path),
            {
                method: 'POST',
                credentials: 'include',
                headers: fetchHeaders,
                body: raw
            });
        return response;
    }
}


