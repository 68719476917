
import { Component, Vue } from "vue-property-decorator";
import { GenericCamera } from "@/util/genericcamera";
import { AppContext } from "@/util/context";
import { Util } from "@/util/util";
import { showConfirm, showNotification } from "@/util/eventbus";
import { HypatosUtil } from "@/util/hypatosutil";
import {
  HypatosResponse,
  LoginResponse,
  NotificationType,
} from "@/util/scopevisiotypes";
import jsPDF from "jspdf";
import LoadingTransition from "./LoadingTransition.vue";
import { CenterdeviceUtil } from "@/util/centerdeviceutil";
import { CenterdeviceCache } from "@/util/centerdevicecache";

@Component({
  components: {
    LoadingTransition,
  },
})
export default class IosCamera extends Vue implements GenericCamera {
  images: HTMLImageElement[] = [];
  base64 = "";
  accessToken = "";
  imgInfo: any = "";

  mounted() {
    this.images = [];
    this.onClick();
  }

  getImages() {
    return this.images;
  }

  getDetectedImgInfo() {
    return this.imgInfo;
  }

  async onClick() {
    const loadingTransition = this.$refs.loadingtransition as LoadingTransition;

    const success = (imageData: any) => {
      if (imageData == "") {
        Vue.nextTick(() => {
          this.$router.push("/documents");
        });
      } else {
        loadingTransition.enable();
        const img: HTMLImageElement = document.createElement("img");
        img.src = "data:image/jpeg;base64," + imageData;
        this.images = [img];
        this.base64 = imageData;

        HypatosUtil.getHypatosToken().then((token) => {
          if (token) {
            this.accessToken = token;
            HypatosUtil.getImgInfo(this.accessToken, this.base64).then(
              (result) => {
                if (result) {
                  loadingTransition.disable();
                  this.imgInfo = result;
                  this.$emit("photo", this);
                } else {
                  showConfirm({
                    message:
                      "Etwas ist schiefgelaufen. Bitte versuchen Sie es noch ein mal.",
                    onSuccess: () => {
                      this.onClick();
                    },
                    hideCancelButton: true,
                  });
                }
              }
            );
          }
        });
      }
    };

    const failure = (error: any) => {
      console.error(failure);
    };

    const visionKit = (window as any).VisionKit as any;
    visionKit.scan(success, failure);
  }

  onLoadDocument() {
    showNotification({
      type: NotificationType.ERROR,
      message: "Internal-Error: " + "Die Funktion ist noch in der Bearbeitung",
    });
  }
}
