import { CenterdeviceComment, CommentResponse, MetadataAdd, MetadataAddExtra } from "./centerdevice";
import { AppContext } from "./context";
import { CenterdeviceCache } from "./centerdevicecache";
import { Util } from "./util";
import { Store } from "vuex";
import { StateType } from "@/store/store";

/*
 * (c) Scopevisio 2019, all rights reserved
 */
export interface CenterdeviceUploadParameters {
    file: File;
    collectionName?: string;
    collectionId?: string;
}


/* various centerdevice utilities
 */
export class CenterdeviceUtil {

    store: Store<StateType> | null = null;

    static async setMetadata(documentId: string, metadata: MetadataAddExtra) {
        const headers = AppContext.instance.getDefaultHeaders();
        const body: MetadataAdd = {
            action: "set-extended-metadata",
            params:
                {
                    metadata: {
                        DESCRIPTION: {
                            value: "Scopevisio Metadata",
                            extra: metadata
                        }
                    }
                }
        };
        return AppContext.instance.callJsonPost("teamworkbridge/document/" + documentId, headers, body);
    }

    static async getMetadata(documentId: string) {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            "Authorization": "Bearer " + accessToken,
            "Accept": "application/json",
        }
        return AppContext.instance.callGet("teamworkbridge/document/" + documentId + ";metadata=centerdevice?data=extended-metadata", headers, null);
    }

    static async delete(documentId: string) {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
        return AppContext.instance.callJsonPost("teamworkbridge/document/" + documentId, headers, 
            {"action" : "erase"}
        );
    }

    static async rename(documentId: string, filename: string) {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
        return AppContext.instance.callJsonPost("teamworkbridge/document/" + documentId, headers, 
            {"action" : "rename",
             "params" : {
                 "filename" : filename
             }
            }
        );
    }

    static formatIcon(filename: string) {
        let cssClass = "";
        if (Util.endsWith(filename, "png", "jpg", "jpeg")) {
            cssClass = "icon-image";            
        }  else if (Util.endsWith(filename, "pdf")) {
            cssClass = "icon-pdf";
        }  else if (Util.endsWith(filename, "xls", "xlsx", "csv")) {
            cssClass = "icon-document";
        }  else if (Util.endsWith(filename, "doc", "docx", "txt")) {
            cssClass = "icon-document";
        }  else if (Util.endsWith(filename, "ppt", "pptx")) {
            cssClass = "icon-document";
        } else {
            cssClass = "icon-unknown-document";
        }
        return cssClass;
    }


    static async upload(parameters: CenterdeviceUploadParameters) {
        const accessToken = AppContext.instance.getAccessToken();
        const metadata: any = {
            document: {
                filename: parameters.file.name,
                size: parameters.file.size
            },
            actions: {          
            }
        }
        if (parameters.collectionName) {
            const collection = CenterdeviceCache.instance.collections.find(c => c.name == parameters.collectionName);
            if (collection) {
                metadata.actions["add-to-collection"] = [ collection.id ]; 
            }
        } else if (parameters.collectionId) {
            metadata.actions["add-to-collection"] = [ parameters.collectionId ]; 
        }
        const metaDataBlob = new Blob([JSON.stringify({ metadata })], { type: "application/json" })
        const formData = new FormData()
        formData.append("metadata", metaDataBlob)
        formData.append("document", parameters.file)
        return AppContext.instance.callRawPost("teamworkbridge/documents", {
            //"Content-Type": "multipart/form-data; boundary=" + boundary,
            "Accept": "*/*",
            "Connection": "Keep-Alive",
            "Transfer-Encoding": "chunked",
            "Authorization": "Bearer " + accessToken 
        }, formData);
    }

    static async loadCommentsWorker(documentId: string): Promise<CenterdeviceComment[]|null> {        
        const accessToken = AppContext.instance.getAccessToken();
        const response = await AppContext.instance.callGet("teamworkbridge/document/" + documentId + "/comments", {
            "Authorization": "Bearer " + accessToken,
        },{});
        let result: CenterdeviceComment[] | null = null;
        if (response.ok) {
            const cache = CenterdeviceCache.instance;
            const raw = await response.json();
            const c = raw as CommentResponse;

            //sort by timestamp, newest first
            c.comments.sort((a, b) => {
                const e1 = new Date(a["creation-date"]).getTime()
                const e2 = new Date(b["creation-date"]).getTime()
                if (e1 > e2)
                    return -1
                else if (e2 < e1)
                    return 1
                return 0
            });
            //format output
            c.comments.forEach(element => {
                element.author = cache.getUserDisplay(element.author)
                element["creation-date"] = Util.formatDatetime(new Date(element["creation-date"]).getTime())
                if (element["edit-date"]) {
                    element["edit-date"] = Util.formatDatetime(new Date(element["edit-date"]).getTime())
                }
            });
            result = c.comments;
        } else {
            console.error("comment-lookup failed: " + response.status + " " + response.statusText)
        }
        return result;
    }


    static async addComment(documentId: string, comment: string) {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
        return AppContext.instance.callJsonPost("teamworkbridge/document/" + documentId + "/comments", headers, 
            {"text" : comment}
        );
    }

    static async editComment(documentId: string, commentId: string, comment: string) {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
        }
        return AppContext.instance.callJsonPut("teamworkbridge/document/" + documentId + "/comment/" + commentId, headers, 
            {"text" : comment }
        );
    }

    static async deleteComment(documentId: string, commentId: string) {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
        }
        return AppContext.instance.callDelete("teamworkbridge/document/" + documentId + "/comment/" + commentId, headers);
    }

    static async createSpesenCollection() {
        const accessToken = AppContext.instance.getAccessToken();
        const headers = {
            "Authorization": "Bearer " + accessToken,
        }
            return AppContext.instance.callJsonPost("teamworkbridge/collections", headers, {"name" : "Spesen2GoFiles"}
        );
    }
    
}