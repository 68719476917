
import { Component, Vue } from "vue-property-decorator";
import Forward from "../../public/images/forward.svg";
import { Organisation } from "@/util/scopevisiotypes";
import { redirectDisconnected } from "@/router/index";
import { showConfirm } from '@/util/eventbus';
import { StateType } from "@/store/store";
import { Store } from "vuex";
import { Util } from '@/util/util';

import SettingsAction from '@/components/SettingsAction.vue';

@Component({
    components: {
        SettingsAction,
    },
}) 
export default class About extends Vue {
    customer = "";
    forward = Forward;
    login = "";
    organisation = "";
    organisations: Organisation[] = [];
    rules = {
        required: (value: any) => !!value || "",
    };

    async mounted() {
        const store = this.$store as Store<StateType>
        if (redirectDisconnected(store.state.connected)) {
            return
        }
        this.customer = store.state.customerInfo.customer;
        this.organisation = store.state.organisationName;
        this.login = store.state.customerInfo.username;
    }

    getOrganisationWithLimit() {
        return Util.limitWithEllipsis(this.organisation, 18);
    }
    
    async onLogout() {
        showConfirm({
            message: "Möchten Sie sich wirklich ausloggen?",
            onSuccess: () => {
                const store = this.$store as Store<StateType>
                store.commit("connected", false)
                window.localStorage.removeItem("state");
                this.$router.push("/")
            },
        })
    }

    onChangeOrganisation() {
        this.$router.push("/organisationchange");
    }

    onChangePassword() {
        this.$router.push("/changePassword");
    }

    onImprint() {
        this.$router.push("/imprint");
    }
}
