
import { Component, Vue, Prop } from "vue-property-decorator";
import Done from "../../public/images/done.svg";
import { MetadataAddExtra } from "@/util/centerdevice";
import { StateType } from "@/store/store";
import { Store } from "vuex";

@Component
export default class DocumentDetails extends Vue {
    @Prop({ type: Object as () => MetadataAddExtra }) readonly actualMetadata!: MetadataAddExtra;
    @Prop({ type: Object as () => MetadataAddExtra }) readonly savedActualMetadata!: MetadataAddExtra;
    
    done = Done;
    refundOption="Bar";
    metadata: MetadataAddExtra = {
        Scopevisio_TravelEntry_CompanyName: "",
        Scopevisio_TravelEntry_ReceiptDate: "",
        Scopevisio_TravelEntry_ReceiptNumber: "",
        Scopevisio_TravelEntry_Amount: "",
        Scopevisio_TravelEntry_TaxAmount: "",
        Scopevisio_TravelEntry_Rate: "",
        Scopevisio_TravelEntry_ReceiptType: "",
        Scopevisio_TravelEntry_TravelAbsenceDuration: "",
        Scopevisio_TravelEntry_TravelNumberOfNights: "",
        Scopevisio_TravelEntry_TravelCountry: "",
        Scopevisio_TravelEntry_ProjectId: "",
        Scopevisio_TravelEntry_Refundable: "",
        Scopevisio_TravelEntry_Type: undefined,
        Scopevisio_TravelEntry_RefundOption: "",
        Scopevisio_TravelEntry_PositionValue: "",
        "Scopevisio_Eigene Gesellschaft": "",
    };    
    savedMetadata: MetadataAddExtra = {
        Scopevisio_TravelEntry_CompanyName: "",
        Scopevisio_TravelEntry_ReceiptDate: "",
        Scopevisio_TravelEntry_ReceiptNumber: "",
        Scopevisio_TravelEntry_Amount: "",
        Scopevisio_TravelEntry_TaxAmount: "",
        Scopevisio_TravelEntry_Rate: "",
        Scopevisio_TravelEntry_ReceiptType: "",
        Scopevisio_TravelEntry_TravelAbsenceDuration: "",
        Scopevisio_TravelEntry_TravelNumberOfNights: "",
        Scopevisio_TravelEntry_TravelCountry: "",
        Scopevisio_TravelEntry_ProjectId: "",
        Scopevisio_TravelEntry_Refundable: "",
        Scopevisio_TravelEntry_Type: undefined,
        Scopevisio_TravelEntry_RefundOption: "",
        Scopevisio_TravelEntry_PositionValue: "",
        "Scopevisio_Eigene Gesellschaft": "",
    };

    mounted(){
        this.metadata = this.actualMetadata;
        this.savedMetadata = this.savedActualMetadata;
        const storeAll = this.$store as Store<StateType>;
        storeAll.commit("routedFromSubSite", true);
        this.refundOption = this.metadata.Scopevisio_TravelEntry_RefundOption;
    }

    onCash(){
        this.refundOption="Bar";
        this.metadata.Scopevisio_TravelEntry_RefundOption= this.refundOption;
        this.$router.push({
            name: "Details des Dokuments",
            params: {
            subMetadata: this.metadata as any,
            subSavedMetadata: this.savedMetadata as any,
            },
        });
    }

    onBankCard(){
        this.refundOption="Bankkarte";
        this.metadata.Scopevisio_TravelEntry_RefundOption= this.refundOption;
        this.$router.push({
            name: "Details des Dokuments",
            params: {
            subMetadata: this.metadata as any,
            subSavedMetadata: this.savedMetadata as any,
            },
        });
    }
}
