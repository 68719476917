
import { AppContext, } from "@/util/context";
import { Component, Vue } from "vue-property-decorator";
import { NotificationType } from "@/util/scopevisiotypes";
import { showNotification } from "@/util/eventbus";
import { StateType } from "@/store/store";
import { Store } from 'vuex';

@Component
export default class ChangePassword extends Vue {
    password = "";
    passwordRepeat = "";
    previousPassword = "";
    readyForChange = false;
    showPreviousPw = false;
    showPw = false;
    store: Store<StateType> | null = null;

    async changePassword() {
        const store = this.$store as Store<StateType>
        if (!this.checkPasswords()) {
            return;
        }
        if (this.password === this.previousPassword) {
            showNotification({
                type: NotificationType.ERROR,
                message: "Bitte geben Sie ein Passwort ein, das von dem aktuellen abweicht.",
            })
            return;
        }
        const username = store.state.customerInfo.username;
        const customer = store.state.customerInfo.customer;
        const organisationName = store.state.organisationName;
        
        if (!username || !customer || !organisationName) {
            showNotification({
                type: NotificationType.ERROR,
                message: "Fehler bei der Passwortänderung.",
            })
            return;
        }

        const soap = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>" + 
                    "<SOAP-ENV:Envelope xmlns:SOAP-ENV=\"http://schemas.xmlsoap.org/soap/envelope/\">" +
                        "<SOAP-ENV:Header/>" +
                            "<SOAP-ENV:Body>" +
                                "<ns1:req xmlns:ns1=\"http://www.scopevisio.com/\">" + 
                                    "<authn>" +
                                        "<customer>" + customer + "</customer>" +
                                        "<user>" + username + "</user>" +
                                        "<pass>" + this.previousPassword + "</pass>" +
                                        "<language>de_DE</language>" +
                                        "<organisation>" + organisationName + "</organisation>" +
                                    "</authn>" +
                                    "<args>" +
                                        "<newPassword>" + this.password + "</newPassword>" +
                                        "<rejectWeakPasswords>true</rejectWeakPasswords>" +
                                    "</args>" +
                                "</ns1:req>" +
                        "</SOAP-ENV:Body>" +
                    "</SOAP-ENV:Envelope>";

        const response: Response = await AppContext.instance.callRawPostSoap("system/ChangePassword.do", {}, soap);
        const text = await response.text();

        if (!response.ok) {
            let errorText = ""
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/xml');
            const element = doc.getElementsByTagName("description")
            if (element && element.length > 0) {
                errorText = element[0].innerHTML;
            }
            if (response.status == 401) {
                errorText += "Das aktuelle Passwort ist falsch."
            }
            showNotification({
                type: NotificationType.ERROR,
                message: "Fehler bei der Passwortänderung. " + errorText,
            })
            console.error("password change failed: " + response.status + " " + response.statusText)
            return;
        }
        showNotification({
            type: NotificationType.INFO,
            message: "Das Passwort wurde erfolgreich geändert.",
        })
        this.$router.go(-1);
    }

    checkPasswords() {
        return this.readyForChange = ((this.password === this.passwordRepeat) 
                        && this.password.trim().length > 0
                        && this.previousPassword !== "")
    }
    
    keyupPassword() {
        this.readyForChange = this.checkPasswords();
    }
}
