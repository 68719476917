
import { ACTION_LOGIN, EVENT_BUS, showNotification } from "@/util/eventbus";
import { AppContext } from "@/util/context";
import {
  Credentials,
  LoginResponse,
  NotificationType,
} from "@/util/scopevisiotypes";
import { Component, Vue } from "vue-property-decorator";
import { StateType } from "@/store/store";
import { Store } from "vuex";
import { Util } from "@/util/util";

@Component
export default class Home extends Vue {
  static listenerRegistered = false;

  created() {
    if (!Home.listenerRegistered) {
      Home.listenerRegistered = true;
      EVENT_BUS.$on(ACTION_LOGIN, () => {
        this.onLogin().then(() => {});
      });
    }
  }

  async onLogin(): Promise<boolean> {
    console.log("performing login");
    const store = this.$store as Store<StateType>;

    let credentials: Credentials = {
      customer: "1000001",
      username: "developer.internal@scopevisio.com",
      password: "password",
      saveLogin: true,
    };

    let useAutologinJson = false;
    let autologinJson: Credentials | null = null;
    try {
      autologinJson = await Util.loadJson("autologin.json");
      console.log("resolve alfile, real device=" + Util.isCordovaDevice());
      const realDevice = false && Util.isCordovaDevice();

      if (realDevice && autologinJson) {
        console.warn("al-json artefact available in device build");
      } else if (autologinJson) {
        useAutologinJson = true;
        credentials = { ...autologinJson };
        if (autologinJson.baseUrl) {
          AppContext.instance.baseUrl = autologinJson.baseUrl;
        }
      }
    } catch (e) {
      console.info("no autologin.json found");
    }

    if (!useAutologinJson) {
      if (this.$router.currentRoute?.path != "/codelogin") {
        this.$router.push("/codelogin");
      }
      return true;
    }

    let response: Response | null = null;
    try {
      response = await AppContext.instance.callFormPost("token", {
        client_id: AppContext.instance.restClientId,
        customer: credentials.customer,
        username: credentials.username,
        password: credentials.password,
        organisation: credentials.organisation,
        grant_type: "password",
        requestcookie: true,
      });
    } catch (e) {
      console.error("failed to invoke token call: " + e);
      showNotification({
        type: NotificationType.ERROR,
        message: "Internal-Error: " + e,
      });
      return false;
    }
    if (!response || !response.ok) {
      console.error(
        "login failed: Anmeldung fehlgeschlagen: " + response?.statusText
      );
      showNotification({
        type: NotificationType.ERROR,
        message: `Anmeldung fehlgeschlagen: ${response?.status} ${
          response?.statusText || ""
        }`,
      });
      return false;
    }

    // perform state changes after successful login
    const json = await response.json();
    const loginResponse = json as LoginResponse;
    try {
      await store.dispatch("switchOrganisation", loginResponse);
    } catch (error) {
      showNotification({
        type: NotificationType.ERROR,
        message: "Bei der Anmeldung ist ein Fehler aufgetreten.",
      });
      return false;
    }
    // initial start screen/view
    this.$router.push("/scanner");
    return true;
  }
}
