
import { Component, Vue, Prop } from "vue-property-decorator";
import Done from "../../public/images/done.svg";

@Component
export default class DocumentDetails extends Vue {
    done = Done;
    travelallowance="24h";

    mounted(){
    }

    on24h(){
        this.travelallowance="24h";
        this.$router.push("/documentDetails");
    }

    on8h(){
        this.travelallowance="8h";
        this.$router.push("/documentDetails");
    }

    onOvernightStay(){
        this.travelallowance="overnightstay";        
        this.$router.push("/documentDetails");
    }
}
