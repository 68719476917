import { CenterdeviceCache } from '@/util/centerdevicecache'
import { CenterdeviceDocument } from '@/util/centerdevice'
import { CustomerInfo, LoginResponse } from '@/util/scopevisiotypes'
import { ScopeUtil } from '@/util/scopeutil'
import { StoreUtil } from '@/util/storeutil'
import { SvDocument } from '@/util/documenttypes'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/*
 * global state structure
 */
export const state = {
    connected: false,
    customer: "",
    customerInfo: {
        customer: "",
        username: "",
    } as CustomerInfo,
    organisationId: -1,
    organisationName: "",
    token: "",
    loginResponse: {
        access_token: "",
        refresh_token: "",
        expires_in: 0,
        organisationId: -1,
        organisationName: "",
        teamworkTenantId: "",
        token_type: "",
        uid: "",
    } as LoginResponse,
    // documents model
    currentCollectionId: "" as string,
    documents: [] as SvDocument[],
    currentCDDocument: {} as CenterdeviceDocument,
    currentDocument: null as SvDocument | null,
    currentSortMode: "" as string,
    currentViewMode: "" as string,
    imgInfo: "" as any,
    routedFromSubSite: false as boolean,
    selectedTravelEntry: null as any,
}

export function buildCenterdeviceCache(store?: any) {
    CenterdeviceCache.instance.store = store;
    CenterdeviceCache.instance.build(true); // get user and collections etc from the new or
}
export type StateType = typeof state;
export function persistState(stateParam: StateType) {
    StoreUtil.saveState(stateParam);
}

/*
 * vuex store, all mutations go here
 */
export default new Vuex.Store({
    actions: {
        async switchOrganisation(state, loginResponse: LoginResponse) {
            this.commit("connected", loginResponse);
            this.commit("organisation", {
                organisationName: loginResponse.organisationName,
                organisationId: loginResponse.organisationId,
            });
            const headers = {
                "Authorization": "Bearer " + this.state.loginResponse.access_token,
            }
            const myAccount = await ScopeUtil.getMyAccountInfos(headers);
            if (!myAccount) {
                this.state.connected = false;
                this.commit("connected", false);
            }
            this.commit("accountInfo", {
                customer: myAccount?.customer.publicId,
                username: myAccount?.user.login
            })
        }
    },
    modules: {
    },
    mutations: {
        accountInfo(state, myAccount: CustomerInfo) {
            state.customerInfo.customer = myAccount.customer
            state.customerInfo.username = myAccount.username
        },
        connected(state, loginResponse: LoginResponse) {
            if (loginResponse) {
                state.connected = true
                state.loginResponse = { ...loginResponse }
                state.organisationId = loginResponse.organisationId
                state.organisationName = loginResponse.organisationName
            } else {
                state.connected = false
                state.organisationId = -1
                state.organisationName = ""
                state.loginResponse.organisationId = -1
                state.loginResponse.expires_in = -1
                state.loginResponse.access_token = ""
                state.loginResponse.refresh_token = ""
                state.loginResponse.teamworkTenantId = ""
                state.loginResponse.organisationName = ""
            }
        },
        currentCDDocument(state, currentCDDocument: CenterdeviceDocument) {
            state.currentCDDocument = currentCDDocument
        },
        currentDocument(state, document: SvDocument) {
            state.currentDocument = document
        },
        currentCollectionId(state, collectionId: string) {
            state.currentCollectionId = collectionId
        },
        currentSortMode(state, currentSortMode: string) {
            state.currentSortMode = currentSortMode
        },
        currentViewMode(state, currentViewMode: string) {
            state.currentViewMode = currentViewMode
        },
        routedFromSubSite(state, routedFromSubSite: boolean) {
            state.routedFromSubSite = routedFromSubSite
        },
        organisation(state, organisation: any) {
            state.organisationName = organisation.organisationName
            state.organisationId = organisation.organisationId
            buildCenterdeviceCache(this);
        }
    },
    state
})
