
import { AppContext } from "@/util/context";
import { Component, Vue } from "vue-property-decorator";
import { LoginResponse, NotificationType, Organisation, OrganisationsResponse } from "@/util/scopevisiotypes";
import { showNotification } from "@/util/eventbus";
import store from "@/store/store";

@Component
export default class OrganisationChange extends Vue {

    initialOrganisation: number | null = null;
    organisations: Organisation[] = [];
    rules = {
        required: (value: any) => !!value || "",
    };
    selectedOrganisation: number | null = null;

    async mounted() {
        if (!AppContext.instance) {
            this.$router.push("/")
        }

        this.selectedOrganisation = store?.state.loginResponse.organisationId;
        this.loadOrganisations();
    }
    
    async loadOrganisations() {
        const response = await AppContext.instance.callGet("organisations", AppContext.instance.getDefaultHeaders(), { });
        if (!response.ok) {
            console.error("search failed: " + response.status + " " + response.statusText)
            return;
        }
        const organisationResponse: OrganisationsResponse = await response.json();
        this.organisations = organisationResponse.records;
    }

    async organisationChange() {
        const response = await AppContext.instance.callPut("token/organisation/" + this.selectedOrganisation, AppContext.instance.getDefaultHeaders(), null);
        if (!response.ok) {
                showNotification({
                type: NotificationType.ERROR,
                message: "Beim Wechseln der Organisation ist ein Fehler aufgetreten.",  
            })
            console.error("organisation switch failed: " + response.status + " " + response.statusText)
            return;
        }
        const loginResponse: LoginResponse = await response.json();
        try {
            await store.dispatch("switchOrganisation", loginResponse);
        } catch(err) {
            showNotification({
                type: NotificationType.ERROR,
                message: "Beim Wechseln der Organisation ist ein Fehler aufgetreten.",  
            })
            return;
        }
        this.$router.go(-1);
    }
}
