
import About from '@/views/About.vue'
import CodeLogin from '@/views/Codelogin.vue'
import Documents from '@/views/Documents.vue'
import DocumentDetails from '@/views/DocumentDetails.vue'
import DocumentProjectId from '@/views/DocumentProjectId.vue'
import DocumentReceiptType from '@/views/DocumentReceiptType.vue'
import DocumentRefundableOption from '@/views/DocumentRefundableOption.vue'
import DocumentTravelAllowance from '@/views/DocumentTravelAllowance.vue'
import DocumentsView from '@/views/DocumentsView.vue'
import Home from '@/views/Home.vue'
import Imprint from '@/views/Imprint.vue'
import OrganisationChange from '@/views/OrganisationChange.vue'
import PasswordLogin from '@/views/PasswordLogin.vue'
import Password from '@/views/Password.vue'
import Scanner from '@/views/Scanner.vue'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)
Vue.component('documents-view', DocumentsView)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        meta: {title: "Reisekosten- und Spesen-App"},
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        meta: {title: "Einstellungen"},
        component: About
    },
    {
        path: '/documents',
        name: 'Belege',
        meta: {title: "Dateien"},
        component: Documents
    },
    {
        path: '/scanner',
        name: 'Scanner',
        meta: {title: "Scanner"},
        component: Scanner
    },
    {
        path: '/codelogin',
        name: 'Code Login',
        meta: {title: "Code-Login"},
        component: CodeLogin
    },
    {
        path: '/passwordlogin',
        name: 'Password Login',
        meta: {title: "Passwort-Login"},
        component: PasswordLogin
    },
    {
        path: '/organisationchange',
        name: 'Organisation wechseln',
        meta: {title: "Gesellschaft wechseln"},
        component: OrganisationChange
    },
    {
        path: '/documentdetails',
        name: 'Details des Dokuments',
        props : true,
        meta: {title: "Dokumentendetails"},
        component: DocumentDetails
    },
    {
        path: '/changePassword',
        name: 'Passwort ändern',
        meta: {title: "Passwort ändern"},
        component: Password
    },
    {
        path: '/imprint',
        name: 'Impressum',
        meta: {title: "Impressum"},
        component: Imprint
    },
    {
        path: '/documentprojectid',
        name: 'Projekt',
        props : true,
        meta: {title: "Projekt wählen"},
        component: DocumentProjectId
    },
    {
        path: '/documentreceipttype',
        name: 'Belegtyp',
        props : true,
        meta: {title: "Belegtyp wählen"},
        component: DocumentReceiptType
    },
    {
        path: '/documentrefundableoption',
        name: 'Erstattungsoption',
        props : true,
        meta: {title: "Erstattungsoption wählen"},
        component: DocumentRefundableOption
    },
    {
        path: '/documenttravelallowance',
        name: 'Reisekostenpauschale',
        props : true,
        meta: {title: "Reisekostenpauschale wählen"},
        component: DocumentTravelAllowance
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export function redirectDisconnected(connected: boolean) {
    if (connected) {
        return false
    }
    router.push("/")
    return true
}

export default router