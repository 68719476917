/*
 * (c) by Scopevisio AG 2021
 */
import router from '@/router/index'
import { StoreUtil } from './storeutil';
import { AppContext } from './context';

async function onDeviceReady() {
    await StoreUtil.loadStore()
    const connected: boolean = await AppContext.instance.isConnected();
    if (connected) {
        router.push("/scanner")
    }
}

if (!(window as any).cordova) {
    document.addEventListener("DOMContentLoaded", onDeviceReady, false);
} else {
    document.addEventListener("deviceready", onDeviceReady, false);
}