/*
 * (c) Scopevisio 2019, all rights reserved
 */


export interface CaptionsContributor {
    getCaptions(): string;
}


export interface AutoPopSupport {
    wantsAutoPop(): boolean;
}

export class Util {

    static limitWithEllipsis(s: string, limit: number) {
        return (s.length > limit) ? s.substring(0, Math.max(1, limit-3)) + "..." : s;
    }

    static async readFile(file: Blob): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject
            reader.onload = (event) => {
                const fileReader = event.target as FileReader
                const r = fileReader.result as string
                resolve(r)
            }
            reader.readAsDataURL(file);
        })
    }

    static deepCopy<E>(o: E): E {
        const s = JSON.stringify(o)
        return JSON.parse(s)
    }

    static async loadJson(url: string): Promise<any> {
        const response = await fetch(url)
        const data = await response.json()
        return data
    }

    static b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        })
    }

    static blobToFile(blob: Blob, filename: string): File {
        const b: any = blob;
        b.lastModifiedDate = new Date();
        b.name = filename;
        return blob as File;
    }

    static endsWith(haystack: any, ...needles: string[]) {
        if (!needles || !haystack)
            return false;
        haystack = haystack.toLowerCase();
        for (const needle of needles) {
            if (haystack.endsWith(needle.toLowerCase()))
                return true;
        }
        return false;
    }

    static isCordova() {
        const cordovaApp = !!(window as any).cordova;
        return cordovaApp;
    }


    static randomId(): string {
        const array = new Uint8Array(8);
        window.crypto.getRandomValues(array);
        return new Date().getTime().toString(16) + "-" + Util.toHex(array);
    }

    static randomCode(): string {
        const array = new Uint8Array(8);
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        let buffer = ""
        window.crypto.getRandomValues(array);
        for (let i = 0; i < 8; i++) {
            const b = array[i]
            buffer += alphabet[b % alphabet.length]
        }
        return buffer
    }

    static toHex(a: Uint8Array) {
        return Array
            .from(new Uint8Array(a))
            .map(b => b.toString(16).padStart(2, "0"))
            .join("");
    }

    static killWhitespace(s: string) {
        if (!s)
            return s;
        return s.replace(/\s/g, '')
    }

    static formatSize(size: number) {
        const kb = Math.ceil(size / 1024.0);
        return kb + "KB";
    }

    static formatDate(ts: number | string): string {
        if (!ts) {
            return ""
        }
        const d = new Date()
        let dmy: string[] = []

        if (typeof ts === "string") {
            dmy = ts.split(".")
        }
        if (dmy.length === 3) {
            d.setFullYear(parseInt(dmy[2]), parseInt(dmy[1]) - 1, parseInt(dmy[0]))
        } else {
            d.setTime(typeof ts === "string" ? parseInt(ts) : ts)
        }
        // 2-stellige Datumsfelder fuer Datepicker
        return isNaN(d.getTime()) ? "" : ("0" + d.getDate()).substr(-2, 2) + "." + ("0" + (d.getMonth() + 1)).substr(-2, 2) + "." + d.getFullYear()
    }

    static formatDatetime(ts: number, withoutSeconds?: boolean): string {
        if (!ts) {
            return ""
        }
        const d = new Date(ts)
        let dateString = ""
        if (isNaN(d.getTime())) {
            return ""
        }
        dateString = ("0" + d.getDate()).substr(-2, 2) + "." + ("0" + (d.getMonth() + 1)).substr(-2, 2) + "." + d.getFullYear() +
            "‧" + ("0" + d.getHours()).substr(-2, 2) + ":" + ("0" + d.getMinutes()).substr(-2, 2)
        if (withoutSeconds) {
            return dateString
        }
        dateString += ":" + ("0" + d.getSeconds()).substr(-2, 2)
        return dateString
    }

    static formatDateSoap(date: Date): string {
        return date.getFullYear()
            + "-"
            + ("0" + (date.getMonth() + 1)).substr(-2, 2)
            + "-"
            + ("0" + (date.getDate())).substr(-2, 2)
            + "T00:00:00+02:00"
    }

    static isOfficeFilename(name: string) {
        if (!name)
            return false;
        name = name.toLowerCase();
        return name.endsWith(".docx")
            || name.endsWith(".doc")
            || name.endsWith(".ppt")
            || name.endsWith(".pptx")
            || name.endsWith(".xls")
            || name.endsWith(".xlsx")
            || name.endsWith(".pdt")
    }

    static isEmailValid(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    static formEncode(params: any) {
        let u = ""
        if (params) {
            let i = 0;
            for (const key of Object.keys(params)) {
                const val = params[key];
                if (!val)
                    continue;
                u += (i == 0 ? "" : "&") + encodeURIComponent(key) + "=" + encodeURIComponent(val);
                i++;
            }
        }
        return u;
    }

    static isCordovaDevice() {
        const device: any = (window as any).device
        if (!device) {
            return false
        }
        return !device.isVirtual && device.platform != "browser"
    }

    static isIos() {
        const device: any = (window as any).device
        return device && device.platform && device.platform.toLowerCase() == "ios"
    }

    static getFilenameExtension(filename: string) {
        if (!filename) return;
        const splitted = filename.split('.');
        if (splitted.length > 1) {
            return splitted.pop();
        }
        return null;
    }

    static getFilenameWithoutExtension(filename: string) {
        if (!filename) return "";
        const splitted = filename.split('.');
        if (splitted.length > 1) {
            return filename.split('.').slice(0, -1).join('.')
        }
        return filename;
    }

    static getTestImg(): string {
        return  `iVBORw0KGgoAAAANSUhEUgAAAX8AAAHsCAIAAAC11DrdAAAAAXNSR0IArs4c6QAAAARnQU1BAACx
                jwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAK7RSURBVHhe7b0HnBzFtf/7Pp/33v9z370OgA2+
                Ttfh2thggsGAiQJMENGAQSByTpIACaGEcs45rxLKcZVzllY5r3IOm3OeHPp9q89s0cxKMpJ2dmal
                +tGUTledOhW6zq9P9fTM/l+WgYGBQTxg2MfAwCA+MOxjYGAQHxj2MTAwiA8M+xgYGMQHhn0MDAzi
                A8M+BgYG8cHZ2ecbAwMDg+pGhF8qcU72iUgGBgYG1QHDPgYGBvGBYR8DA4P4wLCPgYFBfGDYx8DA
                ID4w7GNgYBAfGPYxMDCIDwz7GBgYxAeGfQwMDOIDwz4GBgbxgWEfAwOD+MCwj4GBQXxg2MfAwCA+
                MOxjYGAQHxj2MTAwiA8M+xhcDghbocgRDnMa9AfICvpDlhWwrFAw6CcVzVBIdDiNHCEryCHV+V+V
                2SlawWBQKilFMjEbxHIoHFJmqWWXRhCiQFVyWFBK32kOBZWGfRyqb+GgMouZQMAd8FgW/XQXWGVe
                hKDqDJUCltdHdtBtBcq9luWxypVaCIH2/aj4vT7La2EOm25llHqM3E8ZvcS26kDYR123VaJ6YZUH
                LL/qup+Bl1d2uyJoVaDvozK2awSGfQwuBwh3IMAXQkDi/SRByMI+c3vwLMlW8PkCfj9u/S0CNhCw
                IIJker1eaAOEAoosOGgFjxXuQRY7uq7H40LAfiBgV6uEYiLyA367S7CSo6twjBqCEpRZ/q8IeyGa
                ALRS7lKFIUUwQTUcxVGKIcptTvH7YJWwVykr5lFJ5FDEw0FP/XRWGafUZim35VE9hpC0shcblfJ3
                eh1DGPYxuBzgJySpRMSllaS8T7OP1xcgtf3e8no0j4QVs4iGfQT8eCFZKmapjH1UCXxj04NiMX8g
                RMG3TSIHAqhTV8IibU36Ag0JEynSskvscEMJfjsKoR0PLVOVxskg9UMUFcheq0yFOaja7GMFfYp9
                QoyllEwU3ba5cl++UoCDQh6bGNUoyQ5bUCHsQ3tET0pE2WWFvNgiwFIkBFAmVLJZjWJ6WSMw7GNw
                +QCHlp2Oz6ccERkgqCBEohdbFgFAPeofoQEcUAnKbzm8Kn5RVYhrMBsM25xSCakBBYllFQop++oI
                RXZ58AXEFtD8BcSU8BH5dpHqIYAJglSxDYSssmC4FMluxWcbEi5xszmi1GYHt1K2SUoJStWvyMUe
                RIBRWmqXqMbEYXNXkIoWFFnBCQ0TVIWt8qBFOKeIhyr0wa+oGF02cDUBwz4GlwPEqyWOAN/6PH6t
                nK/yCBAFKIHdUiCgNiEcWlBhSyisnhnZpyonsruyguqhDFTiZxemzsNWRTkEESEyaU4oT/EE/ov/
                B1UVDp/PY9uxrdE0cZeX8MRuTvJDdMttUwI846dahQp0AiF3WMU55PsVKbkst0txCu35KmyOUUUq
                1KrwWsVkEC2pPZaUqMHaEsMNh+UZEF1nh6fatTmLqCpsuRXfYEcVsr9T0+MPwVM1AcM+BpcFlMMr
                KF9is+FSz03xVPxK7VEoxa3ZevmUd9rhie2XxDhetXHhcLm9IsAeBDVKK0ioovzSprZvn9H4fNQF
                tPTtkyOIj2KCEq9PxSzeoIopVPthmicn5FFPhKEZnJyKkV7ZD6kUlBVhCgT7CY9isrBXpeTbtfg3
                EFJkRBxGYEbk4lc0RZjjLrHVlIaiW5tbFDGhRDfc5Vap2sapOKfAHotbWSfaUa3kka1YjN7RiDpB
                hFhrAoZ9DC4PSLSi2EeBe7onmJGZl7Jp59qUbRxbtu9Zm7Jlw+Ydm7buUryg/FkFFHi7P2St27B9
                0PAxSWOnTpo2L7ugTPm72oOwn1IOHfSHXN7Q4mVrhyVNHDpywrRZi46dTKcm9BRUcZOCzx8uLvPs
                2Xd09frtq9ZtW7Vh24btqatTNq/btC09O49WFK3YaWFp2Y7d++0u7V+/aTfKKVt2rVx/eN36VeVu
                1bKVtcwq3E6EAmGq5qGqULmq6c/2nJlqlR9TNOg67crpE/SfEepQaiWHrbxtVtF8K2dlOCfFyl1l
                5cwJ5S4OeE7arOK1SnIqTne3vJj1qk/rqKbsWFbx7kDGWO/xnt4T/QLZM6yKdEVDNQLDPgaXB4hX
                1Cc7SEH7M+eevfo/+GDdOx944v5Hn7vj/rr3PfrsXQ8+8feHnnzg8WcPnsrSZLBiRcqLL799x92P
                PFz3xTvuf/Lefzx7x32PdOnZ1+UjFIKdlF8nz5z9yJP17qrz9F0PPHvPP1649x/P1Xns2Q5de5a6
                VDgDCGEKCktffPnN+x968u91nrjn4Wfue+yF2+55tM7jL3B6/4NPjBkzmSCETU1mYfH7DT+//b5/
                PPDY87ffW/fOB5++//F/kd7xUP27H/vb3DUbfGWHC9c+GN77qRWw47eQ+ky9wt5IWdnzc9fcGU7r
                r/p+IDlv05+swp0q+gl53KVbKzbf5Vr6u+DSa72Lrvct+5u1+g/elT8qXvkX/6HBlpex+K0zC3I2
                XGflHmI3R6VguNzy5IR3NK1Ye5N7+Q+s5X+0lv2pbMUvXFvqWkdGqMmJPWqOfXJzczdt2lRYWFhR
                UcHmuaioSG1lXa5p06b169dv4sSJo0aNolQe4y1btqxLly45OTkDBw4cO3bs9u3bR4wYkZGRMXjw
                4G3btkUsGhh8F2yjSKGeVWs333lf3fcatJg2K3ny9BnTZs2ZN3/J9BlzJkyctmr1evvTKy9ENXfJ
                unvuf/rpp18ZPWpi6t6D+w4dXrpyxTvvN6jz0LP9Bo1yBxWPDRs1/s67n37xpVfHT5hy4ODRg4eO
                LVi84r1Pm0Axb3z0pdsT8AVdbO/6Dh739zrPd+w1cEby/BlT5y+cPTd55qxp02dOS5730usf3n1/
                3V2pB2GNwUkz7n3on116DJw9Z+GM6bM5ZqM/fc6MOQvmz5xfXuoOQg2bXg6u/osVOoFlAhUV+wTV
                0yb/loetBddbodNBr+VNm1625s++si3qc/Jyq3jzbdbKe62D3QOnxoZOJ1nHBlsn5gbSvrEyR1ml
                mWpb57Y82dNDi38VLtkHCypOLcy11t/rXflr77YG4azFAVequ2yj+3TvQMr91uyfWfsG27FRSMVZ
                Xvv9H1WnmndksWIfeQ7HhlYexSEUFxcnJydjp1OnTl27dp0+ffrmzZspYuc8YMCAhQsXkp48eVI+
                kvB4PBDN2rVrN2zYMHr0aIxQZcqUKUOHDp0/fz4KBgZOcMeSf9VDFMvq2qP/A4/+83RWhXzCzQbG
                x24DyVbhf46TaZmPP1vv8afqpaYeoUh99G5/GOQNWNNmzt+99xDy/CXLH378mbff/6KoWD3nFWvk
                e4JWv6Fj7qxTt12nvgH1XCjUqk3X+x5+Nqu4SO2EwlbAF+Y2iiarec2mPfc9+ASsxD6tQ4/BdR57
                7sjJDEKhQFB11R9QHbK9RX3mpZ7rpE/2Lb3RvacrnqMeFvtDXtKik4Hlvw/ve98T9qgHP6dnB5f9
                1leCB4Ws4oySzb+xUpvYsRL11SMnn3qBiF1WPm24rAI6UpI52b/y18Hyg6ocN9v7iXvVn62MCaGg
                R7ko//vVJtRblOnf+2rFljvcufPpmLLoV6asoE91t1oRw9jHXgYRsDjS0tKSbDRr1oy0oKAAxiEI
                6tu378GDB4mMDhw4ACVlZmaiP3PmzPXr10sOUU95eTmcNWfOnK1bt/bs2VNsGhg4EfnISXmsNWho
                Eg4/bvI8n/qM2358IosRduLGH1APmJPGTbz7wcfmLF6pSvjfVlD7IyWGoA58uHHTNg88VPdMRi6Z
                kITNcYosvL4AUclrb39494Mv5BQUk9m1S6/Hn3y+qKJEXiNGR6gnv8w7fOyUex54fM6CxWR26jnw
                73UePX4mgyIU5EC2u67YxxUOBF351voXrGV3hQNlihJ8HpflD+/qF154bbAipUhploezvgks+5W/
                eLeqWJLu3vDH8JZn3O49hEW+sFVi5dlvS9umbeskrrSxgVX/Eyg7oDZx5fs9q35ZurMezKQ+ZrNy
                LE8YNWrwv69gUVHKT13b3lWn6sM4X1Bt8NyOd6qqB7FiH/3ChRZWr15NLLNu3brdu3dnZ2eTz8aK
                ojVr1pB/6NAhyAjq2bVrF9sxdJYvX+52u9lznThx4vDhw6tWrcrPzydcoq6T1wwMNFgYrDSiGDZE
                Tz//8kOPPvvcS681ad5m0NDRq9dtKiouj0RIVsjjtz5s9OXjz71U4vWrnU3Ao55Ds6zUR1rEENBT
                oKTEe/e9dRt90Zwyu4SIgcWsHgZjgTho3qKl9/6j3sp1Gzk9czJzx479fitc5KnYsCV14pQ5Hbr2
                /uzL5nWf+9e9deq++0HDwqIS1Dr2GPRQ3edSDx49cOT4sKRRI8Z8M5B/JkzrO3rk3u37iGnYxCmH
                OZxkLbkucGI8fARZ+lzHAyv+Zm1+EjooUxThL8kZYy273io8oAgCjYOtQ6vuqVj3J2v749ber71Z
                Y31lG6yysqD6DobfTyWMnqHK7wJFO+CQsvRB1rK/hQqWetRLjoQ8eT6r2KM+WivEXhiu2fxyeNm9
                GA+HK+xwkkr2p3rVipqIfWQzRXTDJsu+eyggsL0SGeh8gVTBAjs4ez2pSUaAj2SF2VoGBmcH7EPA
                0rVXv9febXjHfY/d94+nHnz0qb/f/9Dr73yweMUa5Ukhq/7bH9Z9vh5LUG1WlHepbZWKC6yQP1gK
                xZSWeR58+J9t2nejjKVsrzoOzgLyOf38BUvuevCf85YsUfkha926nURD9z/y9H11Xrj/oSfvffCx
                +x9+9L2PGwwcMjKvsAx9r6+iQ7dBd97/+Mn03Oat2t1X55H7H36Mnd2d9z9655N1P3+vuWomHCj1
                FVmhitLVt1rrH7FcXvVY+Uj/8uX/n5U5QzWuPo6z/FkzrUW3WzmpVphoC37KCxyfbG1/37Xkevfy
                P4fX/j289jeBlP+2DrazyomD7F6fHGMtuTFUuh2x8HSX8OJbrPLMgJWthqzcSzWu6EakjU1Cy/7s
                tyrUW5bQMyXqw/tqRqzYR+iDVGIf/VqE5OtTdl4oaJ6qqGCqv7NlA8I1mqrEgoGBE9++XhjZH0V8
                ibWSV+TZuffw+Gmz23bp9dgz/7rnoSdWb9xJacPGzR99+sWCcvXlL7ernIWGEft9xVAgyD4k5AtZ
                Dz7yzBvvflzuVfn2wiNK8vn9auOGXtKYCXc+8MS2XTvJ37Ip9YEH/4l+mw69x45bkLJp58GjJ9Dx
                qBcI7c4oLw/0GjD2b/c8duxUTmGJK3X/kX0Hj23dsW//4TMpqakFWeotZEIvyMEVsMqOdrGWX2dl
                LfcHi631D5dsusMTdFnsgTBKgJY31b/sT4HCvVZIfd/CxcYq7LM8XrpYWJIaPLXA2t/Du+np8pV/
                8ez9xBMuIjwqOzUmuPyWcMUe9b5P/uKSRf+3dXiW8qVgCQGS2lrhlJy7/UQ5/lV13FvqBMMldpxH
                uf3dDEW+1YmaiH2AcJBmEFkrkimIvELqICZnaRSFmdjHoApCih3E0dULdeprDiwyeTOQPPZOnO45
                cIzYpHnb7mROnzXv73WeGDpykiYuIQh1O7TJxR0MNm/T5t6H627ZdTSioR5OK+1yjz+v2PX0v16D
                brwhnz/oa9O+x30PP3s0LdNlv9CIBXJV0FDZBxAK+1p37vfAo88cPHYKBa/fZz/pVpooqsdRfp+q
                qR58q+c0FSv+ENz9mvfYSGvpH33Hk9QLyEFif/4JlOdOcC//VXnZPvV0J4DvBNyWV/Gc3x2EJuiD
                /fKha9trodW/tMqy6Ul++qjgihuCZbvVLsybrz6wX/OivH/I5GBCPSrCgssTKF5UvuJX7tNDrJD9
                fQ71jVbaoVpkINWFWLGPEASpDlWEQTQliaBWSeXFV1fdztQ5VVmGnKqZBgb2izmhoD8gv60BCFbs
                FRZQbqP8U5FERnZBnX88/WXzjqy7Ck+w/lsN/n7vk/PmrfJ6lOfaL+55z6RltGzZadFStUE7lnHq
                kWdeqPPYK9u37VbvEtmWiWaKSt3vftL4rjpPzp69NBCiyWDbLn3uevCJPFex/R6y7ca2Lu3yj6zZ
                YNjXc9DIO+//x8Hjx21iolQO+1sP6rVjPEJeWiokx7fv5fLlP3GvechafLNVWFhGZsgq5f+w1zo9
                35r/W6t0TwXUQVuQQ8hLUOP3l6jtQxDyLfEQz+z+NLTgt6GKTMttuXKS1HOfgu1YxolKTnUNL/19
                eMdnlqeCwEa9JaUinIJQ4bLQ0luDa/4ariiz+51HvooaEBVVVidiGPsYGNQcIo6h/sF5pyUvaNyi
                Y9PWPZp+3atBk47N2vZq2LTDV226P1f/3Tvr1J2zdB3chNrufQcff/o54pf3P202esLc4eNmdemX
                dN+jz9Z55MmpM2d7fOqdoLUbNj7+1LN3/+PpBl+1HTV59qhJM9v36P/IMy/e9cAjvQYOp8Egrm5Z
                bTp2v6fOo+ynVC/s4EtupRpyT50yZ+ndDz/1yjufNmnV5YsWXT5v3vnLVj0bt+j++VcdmrbotGL1
                RuqoT+UIOqyK0vxT1vJflqz4if9AF5iEyIz4yCbXUitrYnDFz630fQHL7/MeD2xt4NvRwtrzvrXn
                04o99T3737BSW1ir3nKt+KXrUD2roiQMZx5Ptpb82irdatMT/58qTP08uOiGUMod1v73rWNDfIf6
                V+x4zbX29sCymwPlOZbf/sTNnlMO1THFQNUJwz4GlwXCVlkZ3qI8xRPwf9220z0PPH73/XXZW933
                j2fuffjJv9d5/O4HH/vny6+OmjiFIBxf8tmfZGXmFLbv0uv+h568+8EnH3jkudvve/TNDxotWbkW
                96RUdkYHj5749MtWder+E8a57x9P3PPQ42+9//GchUvVIxH7UazbH+rUvffz9V4tKCnnVDKFfXRE
                L88WSryB7v0HPfH8i3fc9zB7wIfqPke7dz1Q94GHn3rgoboDh4xUuzAViXisMAGH27Ph1bwtz1j5
                c9guYaXc47aDPKs8e1H55j9bOWvUOwIFu6z1t5WuusZa9Ctr3u9CbJpW3li87HdlG34eOtjEKsim
                BjziypxetPYmy78n31+uwh9modSyzswr2/lw6cofWItvtxbfXbbyf9x737HyDinqifzej6YeJaqk
                +mDYx+BygvrCOn7i9QXKyr3ECS5fEGooqfD4QuFyj7ekohznVp+g24zgrvxmaWmFb/uuAxynMnJw
                OGgJbkIBNTYfODiZeUWlO1P3b9uVeuJMRoVXEYw4pDwnIkw6lZ6FYD+djHiptGIbUTmk1OIoc7sq
                vD63P1Dq8nKQw+atBMl+QoQF++tjRDl+y1Vkle+wwqVsH+mF+k0fzHhd6teCctdgno2YyyoOBTIq
                rBLL71E/oEFTdMHnsQIldNEmmXyVSU7O0qD6WN3Nf9J59asdAR9bM3/RBn/pFstfoMaiijAR2cPa
                gxAY9jEwqAJ5sot7h8NB+4UdmxsIhBxPCSmy/UcdYfu1ZXhBveIsbzCrr1+oZzrIgZB8v5TadnXb
                /bQpBP0BiP2lBRUfQVgQiiha4W9/7VBqse1StlRF9QRKeqJZSVsDOlPkoB/KKYcGvOrnM6hrPwam
                TcVY6omzUvaUkameGau3dyz15nK4wLJy6DbMEg7kURKyytQjdfUziCQhmCpsecuD2coO+RiBztTe
                kCZUSChgLuSoRKRj1QXDPgaXCSp91nYm+Ykve88Q9IcCPr/HZd/uw/avINtCeSlOG3Gs734kgkcr
                TtEWbKezf4XH57GjEnUuOynhEUUulU5a+WtB3354ImYjH79AiIRNZGBYCM+W6TD27d/0EDMRHsSE
                /cNjyrpdtUJFPWwzCdesQvWYmDL1XVf+zVVxDkxDjk2JbivoskX07S/eQmAqzlK/GaRsqIfsAavQ
                EyoOBiA19coSEZYnoEiOpqlAXmV3FOzBVicM+xhcJtB+YscO9oF/s1khv/JQ7lOZiiZxh+jj+aSV
                P2koPzmmEPkQTX2CL5T0La1AKHYtBTKFOOwwJyCybVmxj64SaT0ECYpZ9kN+uz92rmo3Upf/FTsR
                lNhfGLO/5cD/7rB6yiQvPKsjHEZWARVMRFATDBH9qKfTqiH1k2P253iBEpf64UKIiYIKt1UA7fj8
                LmVBxhRWu1F2gnarKtPu2ndArxRlVSsM+xhcJsBnZf9l88C3Dq9l3E95IO4W2QFFIN5uU4mKSuzf
                NlR+5lF/BkKBkEflhMNUpy5RiXw1VP1v54sF4RoNyQdySimyBFlORGIim7aknyKLYD8Z9gozwkLy
                tAmmqvDBNciBMvUeoHqITDc9IcI6FcIwSr+9l4J7sY5mUNmxvG5fwKM+8lJNKq5Tv6OofrqVPtoH
                I/KF2bqpX0qM9NsGPVdcHjmrNhj2MbgcgOOL7+vfGCQlZrB3DyrVh9ev3mMW5xNoggB2GGLXtTc+
                HPKkhiJ5ulxSWj5h4uRps+acTMtcsGQ5+aoBm7YULUVoSJmaPHkyKUUpKSmkJSUle/fulc7Qh2A4
                UFRSKF3yqxf5IrVAJfWocAx62J+atn79LrszSlkFZfaJ/YaivwzmCVo+9lj2fom6Nt2wwypU7y9z
                gl7YS9xG5cGjR+1PzSrKVs+fsS8jZA8asoptRkKlXD3qtkcDadtbLTVRduCnvnRRvTDsY2Dw72H7
                qeXx+qGesnIXbgrVJM+ee/TY6WXL1yxavCIjM48jv6D0TFp2yoaty1es6tmrj1DYlKnTSfPyC1es
                XL1w0fIVK9etXrOBdPKUmdk5hXPnLZ43f0mFyzdx0rTNW3Ykz56/fMWajZu2FRWXz5w1d8HiFTt2
                7+/ao2/y3EUl5d5FS1ctWb5m/KTpVNm3/witL1m6ClMHDx1XPxg0c255hXfa9ORVq9enZ+TMX7Bk
                T+qB1L0HFyxcmrJp+67Ug1+37bR63abJ05JXrtnAzm/2vMUMZMvWXal7D02fMaewqHzxkpWzkufn
                5hUvW7luyYrVdNt+A8BmqqD6lok9GdUGwz4GBv8eeKn8QZ527TtGAqtgeM7c+RMmTqVo8pQZEMfR
                Y6dOnc44eOjY4CEjKB33zQTUqDVjZjI7teKSstVr1n0zfjIbuxkz52zavH3N2g0cSaPGdercvaCw
                FCPoDx2WhEGYCMvwSIfOPVat3bhuw9bT6TkbNu/o2WcgnRg1diI6s+csoC4MRZWUDVtOn8lcsXJt
                Xn7xmLET9u47tGNn6rLlq8+kZY1MGovZsRMmwyJTZiTnFBRv2LJ95dqU9Zu2du/dD5pbuWodnaF1
                WA9lnz9MOnHqjA5dunvsL29UVKjfbFNjtmPAaoRhHwODfw9cT46ly1Zs3LTF7fERy8xfsAinJUgZ
                MXLMtu27oYC16zbi+YMGDy8sKhk+IkkeD42fMKmgsHjBwsWHDh+FUGAfiAOSWrpsFfq79+yHegqL
                yghYUIaYSOEUTo+fOANZ7Nizb9W6DWz0YI2+A4f4w1byvIUwIJpYyy8ooToshoWFi5YhQxmQF6cw
                EfQ0ZepMMseMn+T2h2bMnnc6I3vpyjVFZa5GjZtm5RV+9HHDsnLP6DHj6faixcupSOtz5y06dirN
                7QnQCgdQH/AxFDWa6oRhHwODfw92UIFASJ5q7927f968BR6P7/jxk7AM+6nMrBz8cu26lF27U4uK
                S9MzslatWnPs2Amp63Z7Fy5cfODAIeQTJ0/jwtAQm7hVq9e63F64LGXDJoS09EyMUITDn0nLIMXy
                +pSNMBenhE7ZOXk7du4WHYwcP3EKdlu2fOW+/QfzC4o4cnLzsbN4ybKdu/bQMciRKthZuGgJPUT5
                8JFjVKSfpWUVm7dsQ962XX3dn6BsT+o+un302AlOyyvc5EhbVHe51NdHnE/HqguGfQwMLgz2VzoU
                1BNrewsGlSCILIL68lcwbP9eh4LQFqmU6gNGsL8Kr2SP+h37iCBfkeXw+XzyOZ2GvGcEKUQU/OpP
                BmKHlFP1yVTl3251eSJ/LIiDzkh19cGZ/VdVBeRKgMbBKLRZEeCyiJpNPZWPw6sNhn0MDP495Mdh
                NBFACuKKOgf/lE/To1wUspDP1J366hUexwvTyKTi4foDeDK1KTSdpSLTnFSsCvKlM0ALAINSxe12
                I2NH90FITRuE/kQAqEmHI+fVB8M+BgYXCc0UQhNCCgKnYwPnqaYSDU7Ft4XjItGNg3pEAJo+gCiT
                ijXpjKRaR4qwoLuqhSjofGErOaW6tk/TRFVKo/pg2MfA4HsBl9bOr32VU2EHcrTPa5AjmaKDG4sn
                A11UichXwPSfXQ4G7b/1jLp6yUdtq9Qb1JTYgnRA0rPaQdVjv7cd+eaHbUf+lKv67Nx+rVl9tcPm
                OEm1YKf2Z+y2ZfqsvvjGv7ZONSJW7KPHwwD0jGvYV0FlqitQ5Zp9f0RFlRHp3NDNaUGgmV5Oo0zJ
                vQhQJapIKpKprSFjR07tUX7nS4MiSC0taLPauPNUDCJE1ZJ8Z7siAJ0pUJ347iVAQeeINU6dFnRb
                gKILnWcDg++DGMY+aslXAeveufQF5ESki4JYwyvOY0f7uZyKC3GKIDJG0FFddPSNoqo2pRZqUSEx
                aZR9Z6MoI1BLAmYgpezAxY6o2e2rDjiNU6RriR2RBdIWwCBFYlbbiQIKgCJq6YpakyIydVtA9CMn
                jk7KqYHBpSCG7MM6jlqmznUM9OnFrWYJSajrvDOfB9rZxLvEVyXHCdwbm+LDQPqmc3S+rqt9Vboh
                H0/qtkTQpwJ96pyBs44CzaotCoSG9MNC3ZDusAhOSE6UHUATVZVRA7oKwxS5qqaBwcUhhuwDWKks
                3KjbtV6+LPoot7w4aCPndwxdKmGFdEncFc8nU+ycy4hzCEBIQTdNqaYJsaBHjY60KJmk5EuRaEpF
                iuSUVOtroE8nRUEILgrOKrpXTlBXqlOqu+rUpAkUdA+lSPrpVDurcQODi0Cs2Odca5T1zdKXNS2n
                QOQLgtzz9RMZQIvabFWIv2mnkorI2tlINUSHVOIaZyuAKlILSEWtoIcjFigFkoMQZQdIr0hFBzgt
                Y4QUWZcKtE1BSUkJKTkSPUnTGlF1Bc5M6YMzR2Sdo7uEoMclOQYGl4JYsY9eu7JeOXWSjriWlkW4
                CIhjYBx8TzuooSyC5ACxI34I6BsQNaD9k0yppYsAmSJIkZxKfyTlFN4RI5xqZxZNp0HdAWG9qkXk
                6G2j5OvWOdX91Dno69Mo0I2oIrdbfZ1HT0WUnarUaWBwiYgV+whYsto9ZFnrXYN+zKEVLhT6lVNw
                HjfT0L5NiyJLl6Ie3ADxN5HFJ50QNeqKjvZ54GyCU5G1IJBakjrHrt07aiycOs2S6lLJJxVrgCKg
                1YDkA53vnHltGegOyLQASrUMRCHKrIHBRSO2sY+kLOJNmzb179+/U6dOu3btEm/Xjudc398f27Zt
                W7hwodPxgFOOgvRE+9uCBQtoNzk5uU+fPuPGjbNVItCuhZeeOXNmy5YtuqvYLygomDt3LjpajcyU
                lJSPP/64b9++8+fPZ4Bkdu/enSYaNmzYqFGj0aNHozxx4sSWLVsePHhw1qxZX3zxxcmTJysqKlq3
                bo0y9tEhB1NiFgFlqmP26FH1p+yysrIGDRrUpUsXui39kRR9JrawsFDziO6tE6hRkZmnP1999dWS
                JUtETWZMV+Ey0e3OnTszdj1AzVbTpk07fPgwgm7LwOBSENvYRxa38AvO9sYbb5SWlm7evBl53759
                GzZsmD59OtHQ+PHj9+zZk5mZOWfOnBUrVkyYMCEjI2PGjBmLFy9mxeOxKI8dO5Z7L1Vw4xMnTtSv
                Xx9XkVNaWbRo0fLly48fP44pKAMnwZnT0tKWLVuGNZqAqjCID0t/IB2ETz75pKioCLOpqanYgVkm
                TZqEqeLiYjhi9erV9ApfnTp1KkUwC52h/3AN8qpVq44dO4ZlOvPggw+ePn16/fr10CvVsf/Xv/61
                vLycPnfs2DE/P58AqmfPnlR57733nnnmGajzlVde6dWr1+9//3ssMOTXX39dUwAVSYns7r//fgZO
                68wS7Y4cOfKpp57C/pgxY7Zv337gwIHBgwenp6e/8MILEB+zymC5TJDj8OHDaXHv3r0ME2KiCj2B
                RBg+rdMfaIipZrB0lUnGIAI5n376KbxZp06dAQMGYBmSSkpK4mKtXbt2xIgRTMXWrVvpj1xWA4NL
                RGzZh5ukvk+yoNetW8dpq1at8Hnc8p133sFnIB1IhPv8vHnzYAoyca1u3bq9//773OpZ7uQ8/fTT
                5OBsH3744ZEjR9q2bdusWTP8isCkSZMmhw4d+te//oWjkg9r4JZDhw7Fl5YuXQq/wBSYpdF27drl
                5eVxS6chdCAdnK1r166wyWuvvQZhkS8BC1UIT5544gm617t3b4gJ9yYiwCwe3qFDB/QhJsKckpIS
                SIo+CMMK+yDceOONpLAVOeKrNPfRRx/BIAwB56fDEMQ999yDfMcddzRt2pTOoEYQxFTDFEwUTAEv
                wEGPP/74mjVr3nrrrcdsQLtMyEMPPfT111/Xq1cP5oJBUKPntCj59BkGpHUafeSRR6AkDMJof/jD
                H6jC5Nx9993YHzJkyO233/7yyy8zQAgdtoITH374YaJCwi7Mvvjii8wGU8FFgX3oCaGiDNbA4BIR
                K/ZhrevQHffDURs3bpyTk4Oz4as7duzA7Yk48A38DR/77LPPWOsrV6788ssvcRJ4hF0JN15CGNyS
                /QLREEFNmzZtcFp4BDLinkx1SuEjuAAn5N4OYWGNlPs/RABT7Ny5k4AIL2KHQnwBevToIR2jOjEL
                LEaXOKVX+CEchHNOmTKFLQ8u169fP/wN3uGeD7UBXJrmIJFTp04xTGKEu+66C2KlRYIUOAKK+ec/
                /8moMcIwsUxX0W/QoAHxCPSB2rvvvgtz3XfffYROTz75JBRGNIQ1eEc/z4I1Jk+eTLhEf6BLLDOK
                559/nmmEBSiFK2kXdqYn8JFs01AYNWoUU0dERsQHQWOf0In+ZGdnw3q0gjUiJuaHeK1u3brwC0xN
                Re4QxGjwES1+8MEHMNqrr77K8BG4DTBLLVq0gMelewYGl4gYxj6wD1wjMrdrnJM7LeueDQhugx8S
                +ePqeC/7AgIQiAAvYldCLUJ9CIt4BJm6yOigiS+RQ0omZtlMcZeGdLjVw1yQBRzBXoYQAA9n54XP
                4OTc+aEzKAlnI+wiJRLBG+kJvaKU5vBJ3AxrhACQBdXpW25uLgIkQq39+/ezs4MsIE34BbffuHEj
                TMEwCb7YsMCedAkLUBUEgYw+PUcBxoQOCMHYyxDoDRs2jBxGiiato4PbM3Y0JVASYJDOQwR0kkEx
                QGiCbRqdhyzQpxZ1oRis0VviI/oDkTHJNM0M0xAyCrKtY4zEbmIcs1wOJpNrwa6KATLMmTNnUmv2
                7NlYhpTpMNeI6ZWdF9eIQJUB0pwYMTC4FMSKffSjyiiPErDoJVOXIkgqfqKr42lyKmpRMT+neBSC
                qAGpLmrIUgrgKW71+DCytKWhuwfXQED4GDJ1xZRA23FmAgZCqlsHukvSZ9HHXaUVXV16qMercwAV
                dabum9gUg1JKji7VcPZEc4SeW5rQdUlFdjYnYRdqYkfscyoWRE3308DgEhEr9tGOIQ6DII7qdBjt
                0k44F7csevEiZ0XxDV1dLAPqOtUEZIpNbUfMImhlUdCn2rJUkVK8FCCgRk5V56cb2kuV6crSKAEd
                bd9p0CkI9DC1vtgHMmRO0RezUTb1GO2877CSlumnPOEGWlND2sWOs4hTTVUGBpeIWLEPEMeQtet0
                KlYwQJBM8RagV7kWUNMyi55TrQyoTqa2LE4llqUWKXsoVfZdaP8Rj9XKdl6ke9qsGDyPy0mXREGU
                BVqOak4gji2CTqUPku/sjz6VtpwtAupKjm4RQWTypSI6zibId14RJ6go1kRfANnp/hgYVBdixT6y
                +gVRjkSql74ILHcp0ppRAp6m60qOVgCUOk+1LFWAtCLuJLLuA8q6acmRFDjdDwi7aeO6S1Wb1jko
                iBFtiiIyo6poHtHQFaVI9KuqycClG0BYQ6CboFQrCDiVUtHXmlWr0wdnXelAlDUDg4tGDGMfAwMD
                g/PAsI+BgUF8YNjHwMAgPjDsY2BgEB8Y9jEwMIgPDPsYGBjEB4Z9DAwM4gPDPgYGBvFBHNgn6Hh1
                UAuS6jff9NtucqoR9cYdCroKwIJWqCpIK1qgokAyBc4+6NbFgq4uIDPK4HkQ1ZDzvb6zImrgVedB
                Q48O+7obZDqbkPxzdfjfduaskOHYw/rOBMYLekT0R8+Jhl4n55nJi4PToP4Ki3N6LxGYEmv2TEem
                +uIuWQKiptmHlcEkOi+PzKlzQv321xpFR19dLdhX4du/JKMznTbl+1nk6GUHaJpaZ11/6NABvWo5
                FdlpX3pO37QakFNn01WBZVFAGWibZ0VUu/qboueHswP6W2+MlPHSIrI2SKZWliLgHNH3h/OKiHBx
                dqoRjEhGR0pn6JjuG9Bz+z1n9XtCppGrLG05r8WlQ6zpDjuHcxmgptlHT59zlUgOwFtEQTsGEB29
                1vXVFTdDX4rIpxbQmhrkVM2koixQbRABNX0KoqxJkXTme95/dHVNCtJJkc8KWtFzIgIpmWeFpjag
                m0Bff31Ulzo1tX2ZbX36/cG4ZBTYdE5RHEFPZDikzqujQxIQi67K7Om5dTZXLZA+6547r2NtR02z
                j4a4vZ5H57KQ38GQq8ildToV0LWkCnLUkpKVR6YUiZOIDtWrej5F5CPQJREEegXTH2lR4Gxd+iPy
                eSBmxY5UPxecfXD2/PyIWvFigYaqWtCta0d1Drn2QpaTyM4RaVlKJYiodoJwtigTW43Ql08sXx7X
                SxAH9qk6fTK/+rJFrRgBOs7rKgvIGUJLLalyrtWg83FLp/Eo0BalUcuIU2lOjDhd+jwQI1Ftnadp
                KaIWnCvy+RvSMyBEQ9+0vggYETsC5k26RCoKcnoRoKImaJkT57TXMPQYdR/gei074wXnbFw6ZAaw
                qeez2sEo5AZc9cZZ2xEH9uE6ydWKogAupPaEqGWNpgik+JuoycXgkgNtR4rIEUHHTXIqRpDlFDXJ
                RE1k8kVHTvFVvbxIBVGLQDdxVuiKYlZIU+Szgg442xJNPRtnhe6PHo6cAjElCuRLEZmSr83KbFwQ
                dPggjSYC9DwwLj0JDE16qPupe15d0G3FDnK9dJo4c36JqGn20UtEppLFIT6gHUCuJSn56Ehp1AUW
                ZUqBFEmOXBV9e9cXSQuSTyrdgMiqXkhpFx2AgIJu3alMke4bmpJ5Vgg9iTUESc8D3Zx0UqpT66yQ
                3modnQLprfRNupqdnX3o0CF9GqV/oThy5EhWVpauLjbjBRksUyf9kVHrWFWmVPdQTqsFKSkpmJXW
                ZUIQnOvkEiFrYNOmTfI4Qo/l8kAcYp/8/PxBgwY98MADv/nNb6677ro//OEPzzzzzLx582RxMN3M
                74MPPnjgwAHRB05H2r59O6UsMtRuu+22//3f/73xxht/b+NPf/oT1jj94x//yOlNN91UVFRkG1CQ
                6tgfPXr0Pffcgw6g6eXLl0upXjTIY8aMueuuu/785z9ff/31b7311sGDB+WqixHtclgbMmTIP/7x
                DzmtCtE/duwYLZJKE+dxeFEgFR0WtPTzXHj66afteqohWakyM9Iu88yE5ObmckrmuHHjPv30UxmI
                7oMe9YWCaRk8eDACxs8zopqBdKBZs2a//vWvue6MmoXB5ZNZuvvuu2VywEWP96z45S9/mZeXh4DZ
                Jk2aDBs2TGa+GoFlPEX+kpoMU67gZYAYso+eI6EVOd2zZw9ron79+tw0oAaKIKMlS5Y8+uijL7/8
                sqwMMllD+/fvP+ua3rlz53//939TxHoqLi4uKCjg8hcWFpaWlv7nf/4nnIVMJiml6OjVQBXkNm3a
                QBZ79+4lp6ysbPHixb/61a/WrVundUg///zzxx57DMaheklJyfTp0+nPtm3bREdvtQiyvvzySyjv
                b3/7W1S+CGKNjkG1OGpUPpA5Ee+VsZMjmQI6QMBCPxkLw6Sfv/vd78hhdDJq5lAPMEognTRp0v33
                389sSP6oUaM++OADKZIcIL2S1sVFJQXaDik91PmAzPfeew92i5xX1tJVZIxyqkckAkVajZSmnZb1
                PIgFSTl12tfQ+aL29ttvc32ZFsC6kklDkJsQlp3VqSunzq46ZVLdYVLdASmVy/3zn/8ccpf8Ro0a
                cSsSmVTqSqrXhpzSE7GpcwRk6nzxGjn9xS9+cfLkSa0patKKTBenTju1BbFiH30BZHYkPXr0KPeK
                CRMm6J2CTBkCObt370YWcFGdf0TUCc0+kfNKoHzVVVfJjQjQAWd10T9x4gS3EVmLug9JSUkPP/yw
                yFRZuHDhLbfcIj/JKt1mHcyaNYtQCCaScQFW9htvvMFy37Bhw+23306OFElDkpIDmjZtqonVCeme
                1BJILQH52ieFINCHkSE77W9aX/Oa/iVZMU6j2j6jIOh7//33kXVd7RUCWfF63rR9LQBKxeY777zD
                rR5BTkkpQlOnZDoriiwpHZNtkR4j+VrWHZD+YFmakLpSqocMKBXNV199tW/fvgj6wqliG1pGcOYL
                tDVpyHmxNOmQynSRo8cisY90qUGDBiNHjhR9mYQoa3J1yJR8mQFtilR3TAxiSupee+21sA865Dtn
                iRyB5Oii2oIYxj4yxTI1kuKuhBXOSQf62nBp9fTBPrvsP0lcFcI+euojuXZzTvYBugPqatsgAOnQ
                oYMUyQUG3LsIbeQvXnH65JNPskNBoGPo6HX5l7/8ZdWqVQjSSYK15s2b02f6Q+yje671ZSXNmTPn
                r3/9K1Qlp3RG2tX6MnxKRcCgPSw1LumPBqeEY//zP/+j80WTuiIAydRTCiRTxjJ+/Hg8RJdKT6QI
                m9IlTpGpJacIjEjbJBWBUph3+PDhKIsdIG3pGUBTiqSHCLpFVewYoCZByXF2QKpIjvREqwFKdV3A
                AuvcubPuj0AUsEZdXYQs+eSITS1LK6IpV02gGwUoiM5Pf/pTYiup+Nlnn/Xr1w8homRD1BBYYJIj
                NiVTxgj0nOhJk1QEdpH79u2TU0GUjp7h2oUYso9ehUI3xB3/9V//JbsAZgpw+bmizosh+YBQE6/W
                FpwQ9hFZFqVOr776ankOCqRU1ASSSUNypTlFBnQMg6JMWPQf//Ef8oTPCUq7dOnCtkUvZbaQUp29
                nuy8MCuWgfT8zJkzv/3tb1NTUyVTg1pRfQP0R2cioCOyzI/0lrZgHwQ5FQX6/9VXX913331EcJ06
                dZI/JSaDZcnKn4oXjBkzRnZeALOMiB3KoUOHoCT2hmw29VXWPSHa+uSTT6Daxx9/vHv37jIz1EXh
                3XffHThwIBPesWNHqtepU6dPnz5agbRbt25paWlQdr169e69996uXbuSzwTCWWx+6TA5TJQoA0a0
                Y8eOTz/9VDozYMAAgk3yZc7Z1Jw6der48eO0e+utt0I0dN6uFxksphidxD5ySiqzJE6OGkJycvKz
                zz7LhpR02rRp5EsHRJ97XuPGjR955BGG07NnT1m6XFbskHIXYa9N98AXX3xBLMO2vby8nFJAEbEP
                ++JmzZphnzHKkLEg80m6adMmws8HH3yQKWWAkinLhu6xs37zzTepy6Xs3bu3xEr0gYCdyyRGGAI7
                cVo/duwYOTJ2QAdEoRYhVuwjE+GcDiywBJms88yRLiIYkUczVSHsU9UIOT/60Y+csY++MABZIKcs
                NS2zyF555RU5Xb9+PdsuTAEupyw+gLxs2TJhGV1RFtb27dvvuOMOyaEWK0kU8BnWKOFGZmYmMYus
                JOw4+yACmSLIKsQIqdMnRQCwCTsvp5HFixcTlC1ZsoRFSYusfhpldYrC2rVrn3jiCQROATsv/FMb
                vPPOOxk7Gyh8nio43g033MBmCk3RQf+2225bs2YN88CtWz4r0NTMNgfa+te//sVelbEwxueff/7j
                jz+W/mOBU8IBnDnDBqzRvn179qE9evSA5dPT05l2SARl6R5zxR6WeUbmLoX7PfXUUzQtBuvXr8/o
                cFroA+qfOHGidJJJQ0Gmi7H06tVL9IFkMjMYlBz6gB3uCshwGUNo06aNFIEJEyYQqK5cuZLhwCkQ
                GeOVy0RbxMi/+93viG4gXGwuXbr0ueee+z//5//IkqMbxPUMn0GtXr2aa8ECJjyERuW9c3rFiB56
                6CHmExaD1mEZdv3CrYCbAbt7ViAzTDyFMuRFQxTRLjsvBEbEcoIZ6aoUkeoLWusQK/YRR3JOTatW
                rViLsjJYDSIwmyKg5vRG+AWvPuu0CvtgWS1Ye1VJivIPf/hDlgin2CQlR2RpQuD8C1aUspKIJlgK
                kokb33333bKgJQdBurF161YCGQT6SeuaHVhkuKjIGtRimUJkLFCW40svvfTHP/4R35BHTtJhgawh
                mtAtytQJnDOAAmOHfeiAWOA2S0yO24uCmCJCwcklh3spd1G6KvOMe3/44YdSBOhey5YtZSBSd+PG
                jXCZ9ATnRGatc6q7xO191KhR5NAB7uG4AY5EPjkA+cc//rH4NqcwBbdo6opxZp5IoWHDhsjSf0Kb
                6667Dk9Dhyjp97//vTzBFdAx2A0GlNPXXnsN5zx9+rR0WIOGRKCVt956i4AL74Wz8GpSGhW6JJ01
                a1bdunXJ1LNKJhRMWEEOMeMf/vAH7JOvbRJU6ifrDEdkffnoM/2nougzOQR0+hKTSQqfTpkyhRy6
                zZUSrhELBMW0iMDwd+/eDcXIIyTdPfEIxsVWQHZeLFfW5/Tp0+3yyFUDGAS6Ym1BrNhHIJMiMgv9
                o48+EhnoCwxkcYsm+ZziVFwbXdcJYR9dhKBNXXPNNcI+ulRDrpNcHlm+4hXc3NiPYEGWxbx584iK
                EYCoSRVS2ND5zEVAQ6wbVpi0SKnos0rwNBxGdgesclYSrsgK5hRZmyXF/eg2TssNnwUtCqQaogZo
                CxaT6QKzZ88m9ECgdekYfcYCg5LTDRs2QBBK1QYjhX1kKjAC++APGNfTRXUYnDCHUjxNmEJADmDy
                5TN+qkCm7KF0XYApwlsaRSafwcrWBshkQh/z58935txzzz0pKSn0lvs83hs1cGI3giM6DIjamEAy
                pSL2gch0TARijauuugoWY/IBvo1MiEERYCtEwEItZKpIJkw9YsQIhKFDhwo1iym6hA7xIITFKaEH
                BuXZDfmUSgfIhKBFn+mSx/Ayw3IJ2F7RKwR9EalFEa1ghxXL2uAUmgOiIH1DjVSMQExHjx6FGYmy
                GYJSqlQATJq0WOsQQ/bR0w2Y0E6dOnFrQparC0TBmQpQ5qJu2bIlcv5dCPvIFRLo68TOqyr7kC+n
                knIKENgK1atXr127dkrJBgrEzNxbREG6hKzMhcOExDfeeKPO0QrclHBjBJ3JUuCOLds0yRGBtXvr
                rbfK60VAtwKD0CgUBoiSxA5wrkJJaYuFKDKtQOhUgQXwTCKRN998k8j/9ddfpxV0ALHPI488InZI
                6ZXcAKRpbvsyyc7JlDlEwBVReO+996iCZZpAJo7DG0UfXpAPmLmg0iVSdhaLFi0S++yb4EcE8Q34
                nUCMjZWeEPD444+vWLGCitgncCBlOOyP8FiaY2cEZYg1ZAm7pCLQshjECeknGxa6Byglh1RiH0AE
                wRXHLKAJxsWCpEtseShl3wQ5SqmMFwXGC33TPRYGpTJMbIoAJHaTU9gT9tFbbLvcgixgZGcVkQnK
                uEXBlex5yaQV5oFawr8yY1KFefv1r3/dsWNHlh90THUpEkGuheSIUIsQQ/aR6dCTQljBfTsqU2aQ
                SddTKdfmz3/+s7yDI/kCkVetWsWOAEHzva4usY9k6orSlpyiKbW45Hgp3us0Qsot7tprr9UGnUZw
                3WeeeQZBrjc6YnnPnj3OnZdksqzlNiinugr3tz59+mj+/f7QbTGHus9YY1HKFoNdD60gsPrJkZ7j
                M7APAqfUktjHrqooA3fasWMHshgX/Md//AfuhID/jxw5EmvYZLqojozPIKAPUCD2QVOuGgIpZAH7
                yGTCLDNnzhQFUqoQ6UDiejZI4TI6SRG0m5SURK8gC1qhRdRIaR01ANnRH5GrQmzCULAPgt1BNShJ
                6TPNwT7ywqdsxxgOKfblcsB6dEBmj8mEBRDoACml7Nr++c9/Oi0L4AX2vwg0QWhGwEipc9nAKZA4
                srQyadIkOvnss88SF3OrINKkD6j9/e9/J2bUC0OGgx2ZpV/+8pfsKLlFCd1LkepH5egks9YhVuwj
                8yJzRwrY5DOJK1euJEcIHh1mWV8qEaQiV1p/eOEEdgYPHvzcc8/p66SrU/SDH/zgrOwjkCrks/jw
                HB3o6vWNDovv+uuv14+BAGbFFHfLHj16SKYG/YcRCHOoKzkI6HMjlce35Eh1ab1p06b6ocwFQUzR
                MaJCycHswIEDaQhZjOMnzoVIzzdu3EgwQl2pjnc5n/sQxjtHChgODJ6Xl4dx5odwVToPaEKMyLUD
                uCsdkExAc6RscyS4Q409msQ+2ghRgJRKLVJ0uC0ht2jRomfPnkqpstQ5FizgrkJ25wIdoEv9+vUT
                1wViR06xxk1CXhlFU3rr7HyHDh2cnw/SosyqKBOy4fxan9GhgOUf//jHrBkUyPz000+ZYVGQRrki
                0BY3LaEw6LhVq1Y5OTmcypxQvaioCLNPPvnkWZ/miOWf/exnJ06cSE9PJ8oWQgdiAR0E1HSHaxFi
                GPsIZI5kErlRs+LlOSWXR64QoBRwKvPOJE6cOJG7AddVqsvFAyjcdNNNU6dOlVMNMcWuQT5vBnqh
                AIzotjBFYMIWXa6ZZIogKQERNyXxMclBkyCZLQ93TlkQ2gORU1NTJfZR46n8wAs/IXRHkFM9LiIR
                boZ6eV0QML53794//OEP2ESm6a1btxKNC3uSo9Vk0hAI1GkRfQHhG+wjgwJ0m9hHlClFIIXB5UOc
                hQsXsishB32KtH0BmTA4t2JtjUGh/MADD+itJc42bdo0URDjcBNuLC2STw6xz5IlSzidMWMGRIkg
                wxFH0vPMKVvL88Q+mKKHXFlCS90ignSbFIVmzZrJJksg3cCyCARlBCmiyWnUNTp9+vTVV1+tN3EC
                Kv785z9nbUgrTZo04daIjp4TFCDE/v37I8snAJIPZICwjzxLomOETjKHnOpuACbhf//3f48fP46c
                kZHBFefSIOuJ0pq1DjFkH30NABME8Pznn38eWjl8+LBcclkfyNwk2U/J+x2AGedWgwPrxUFOQUEB
                y4sdslwkcmSJINuVrP/6r//SH0DoTCAyDVEFC5999hmCFAFZB6JDiu+xuxEXkr6h8OKLLzZq1Eir
                ObFr1y79iTuQKhhhXW7fvp0cxkgO6dixYxm7s+kLxb59+37zm99ETuzO4L0SMsioab2wsFC/qLlm
                zZr77rtPZEbBRfzkk0+QqcjpX//6V/nSqXNQMDi3B64UphiXfOQkxpltdqaES6KvgxEpFbDzwjfk
                usAm4icaGIQQIyc2uOeTw+QAJoe9YaTAvuJnzpyBcKU5vdE7D15++WV2KJGTSkhn6OTBgwd/+9vf
                6o8IGSApoavcsegAG0PZLyOT0i4d0K9rMdViXNYkKfewq666SjaqgBXCcMSsYNOmTYQtrFv6wLVg
                gHomSSFT2IdhMtunTp3i9rZz505RICWT0FVZsfd3Bw4cIBNwyW6++eaop6J0FZvS7VqE2MY+XCFS
                pkZfEsIZIiC2YOyeOnfuTJxMyM1V5xZ95MgR9LkYMolcVBbT73//e+4J3GO5tEQ99erVk9DJCS4J
                KVfr2muvxffkFMiqdQrExtddd12DBg3gNZYOt1NiAUnFDwHKRAQ33HCDPObkpsQtUQjLaZm4g5XK
                be3LL79kTQ8YMICtIr6qR8rKYxP30UcfTZkyhYCcrT5xAbdQVom28/0hnAX7YJPWmSJpiNnALLE9
                fgu70U+2gfg8CjTE8n300UfRRGZuiSgZrG0v4mzwFFdEcgBq+o1QFOgteyXmHOPjxo3DOFwme2ds
                QmTQgQwHZYDA7V0+lEHGFdkmyBoQBa4yXEMVuW8zKFwaoufaccrmgi4x7Uwj4LrjrvoTNMjuPLGP
                2Oc6chWwD6giA49o2Jg/fz7BY/PmzQnKiFMYGp2U3Tp9gIYefPBBbpCMd9KkSawTboGrV69mLFij
                e9yW6AZTMWjQIHqekpLCVojpQoGG2FazGLgWTAsXnfmBJuQOBIiJGB2rnfES6L3++us04fwiNDvQ
                P/3pT4TeEyZMYMFw4dgM0i5Fv/jFL2hdusEpa4924SM9Or3qahdixT4yTSwI56meIy4YF6Br166d
                OnVioiFyZlYrA1my6ONvAwcOZLcMAeEq4oRaAbNUFIGURaMf4gBdKikYP348A8FLWUBDhw5Fn1Mo
                hnzucjSn28UOK5V7HbTC7ZHMqLFwS6Q6ToJLk2IQs2zysQBEh4WF+7GGoDBufVGecBHAIEtTZD00
                OsauoXfv3jiePKrXXc3OztafcAMYFk6kb3IhkpOTyytfhCOVPuM2ZOp5RsZmr169mApaEX0Bbul8
                bCRGID52BzLzixcvFlkUAA4mrxRoQFUSO9AlqkAB1CKaY9qZMWFGsUw35Hve5wIWqEKsJPoadEBP
                CDrcnyZPnsxwWACsLueC4cJxipEeNhivbIsARgDDZ9dP9yAO4nQu6KJFi+iz2IcoMzMz0WFJs7an
                T59OdUpltgFFLJL27dtTnUvD6LjPCfMKoD8UuJSsSXnzSABXllW+QUrKAFl+zL+0KxdLr7pahFix
                j14BTIoIMlPigTpTXxiATKmoaQUgVTCoZ7+qG2tv0TqSijUtAOmY7p4Izvu/hvRNeqKrA926zkRT
                WtTd1vaBlmWdOYd2QWCMYko3J3B2g1RaieoPQE0yndWlipjV45JMILMaNV7qilk9LhGcUyRw5usW
                tZq4PTo0RxNaQddy5tCiPj0XUBMdsaBHIcCC5Dh1BJKj54pTgZzqvkl1OkaOc2LF1Fkb1VMn0GPX
                goB5dmpG9QRBMxStyLxpfTHlHE4tQmzZR1JmUOaIVE+TTCvQF5VUl0omU6xzREFPOtWdF0lSLYgy
                0IJAqmBTzGp9VVZpXFLJFDVSrSk5gqhTqUjPnRZ0BzQ/Oqt8f0gt3Ss9D1o4K9AURM6rTIgAs2KZ
                +RFlnSOCdJ66zupRljW0haptSQ5FohNVnamjCEFSEGXhrM05IRXPqiZFmh3Esl5mdl4EaEqOpnty
                pHrV/gBdXaxJFRGklsh67DoTOA0qW9+tQqr10dSdlxZJpUiujujXLsSKfQwMDAzOD8M+BgYG8YFh
                HwMDg/jAsI+BgUF8YNjHwMAgPjDsY2BgEB8Y9jEwMIgPDPsYGBjEBzFkH/36kwi18W0oA4MrE/JC
                Iz4rLzRKWu0uHPPYh2FEvSFqYGBQK6DpJkYuHNvYRyhTI+rUwMAgkaHZR3y5lsU++psvdB2Z1MDA
                IPGhv0QpzgvktHoRK/bRX6qUflc7axoYGMQamnSEg0SuRsQw9hHG8dt/i3r58uVz586dNm3aHAMD
                g4QH3jp16tTk5GT5Y2fi0dWOGLKPs9MrVqyYOXPmlClTICADA4MEB7yDt+Kz8ouu4sXyQVg1Ilbs
                I0985FmPPjUwMKgVkNAB3hHqqX2feRkYGBicB4Z9DAwM4gPDPgYGBvGBYR8DA4P4wLCPgYFBfGDY
                x8DAID4w7GNgYBAfGPYxMDCID2LIPvJFNXnbUOSKigqXy+WxoYtEkNeZ9LfDBPoly1oNhnzq1Kn0
                9PTS0tKTJ0+SowcuL2HKK6RZWVmFhYX6tcyo97tkKkh37dolOaKJqdOnT2tZTFX7O6kGBrFADNlH
                fEy+54WAa7Vt23bgwIG42bhx44YMGYI34pbZ2dn5+fnHjh3TfyIWH6OuVL8McOTIkRtuuKFZs2ZT
                pkwZMGAAo2Okwr8MFl6GaMgcMWLE9OnTZeDMGJlCIiUlJaQowGIIjz32GDnQtEwUOf3792dKsSnz
                Ro6BQa1AzNmHVG7jeMhHH33UpEkTiGb06NF9+/bNzc1t2bLl559/3rNnz4yMjN27d9v1vq0i3lWr
                AY/s37//nnvu6d27d05OTqNGjW6++eZXX311zJgxn3766fXXX0/++++/37hx45EjR3bt2vWll156
                /vnnN2/efPfddyclJb399ttvvPFGv379vvjii48//pgc2IcZe/PNNz/55JN33nnnvffea9CgwZdf
                fvnhhx+mpqZGWjUwqA2ILftI1AMQiH1WrFgxY8aMw4cPz507F/Y5fvw4frV3795hw4ahs2PHDr3z
                koqXAfswCYz3ySefZMfE5gu+ePzxxw8dOtS0adOxY8cS77z++uutW7fu1asXUeHTTz/91ltvdenS
                ZdGiRW3atKHuCy+8ADedOHHilltuIW6aPHnyww8/TBBECoUxpaLA1albt25KSoq0eBnMm8GVgFix
                j3YA2T4AfIatVnFxMe5RVFSUmZlZUVHBzouNCfkocEoqUQ86QFWr/Th69Oi9995LqDJt2jQ4pV69
                etBuixYtYBDCn1mzZtWvX5+oh30ZISGBz7vvvksYyH6KTVmdOnUIbcgnPISn2JrBX5hq164dERDs
                g4X27dtT+vLLL2/ZskXmUJO+gUEiI4axjxAQqd5GCaFIgCOy9hPNViiLPtCZtRcyQAYrLKyfxJMv
                stvtdk4I0I/kEdiRNWzYcOnSpZIpkPlBgfnBDpadMaNT08AgkRFD9tEkoilGIK4iT1tFsLOVIB5I
                vgg6bqrtEOYVMtXTApxMwbRoHT1jcJNMBZCnzkDPmJOdMastO/MNDBIWsWIf8QS8KMpDSHEn8S6K
                xJFETdxMO95l40I2LXzLOEBO9UiBjnfkFDhlzcLaTlS45LSvZ9LAIMERw9hHoD1H2ER8Q2TxGWT8
                0Ok/KGh6ItV7FqmLTClCrYD0nBQ4hxMpNjBIVLBcZcVqkBmRqgkxZB/nw4iCgoLc3NzMzMysrCyE
                9PT0nJycU6dOIefl5WVkZOj09OnT2dnZaCKggyDQFUVAp1aAEdF5hiandD4/P1+fGhgkJliopKzV
                0tJSeSYgvly9iHnsI/TpTAUS3UhEIzkS/kSNUzJ1ZEQtQBVFzrUB9Dki2bIeTiTLwCAhwRIF4mjK
                8WKDWLGPM/ARQaC3IYDhkTopSQ816pNjmQsgp7UIjE4P2b6sMbyWBgYxAuuWlRzly5eOGMY+dBe+
                0KEKOeKHmm70IyFKhVnOyi9aTQM7yo9rA6TDDLmsrAxBrp9MiIFBwsJetjFHDNlH3/OFU3Jycnr0
                6DFkyJDy8nKGhwcKrTjDHCKmadOmCblIvkyEZiXUamxqqgUMkz536tSpXr16QrsyUgODxAe+Bli3
                AB/UblhdiBX70GlxM3osQq79ra4OHToQBQwcOLBPnz69e/det27d6tWrz5w5M2rUqMGDB6ekpPTv
                33+KDXiqZ8+e+O3mzZudX8IQH65FYAbmzp0L+zAP0nnNywYGVzJixT6aJkUgJfZJTk4eMGDAmjVr
                4B3SLVu2dO7cedasWSdPnoRooBv8c8yYMeg3bty4b9++6IwcOZLT7OxsZctG7YodhGjy8/NfeeUV
                BObBUI9B7YJEQCByXn2IFfsA6S53e9lhFRYWzp8/f/369ZwS76xcuZIUlJSUzJ49+8CBAxs3boRu
                YCIUSFesWMHpiRMnqHv06FEV/NW2qEcA40Cy119//dq1a2vvKAyuNFRlHB1SVBdiyD46SNGPjbXj
                ITASfarHSRURSOWLBfIScHl5OalA69QKKLKxIb9eJHDKBgYJCOWQZ0OkuJoQQ/bRpAPYbnAq3OnM
                B3KqB6bVooADi1C7dl4C6byMsdpvIAYGsUa1844ghuxDj6vGKeJ7Tq4hlUw7RPjOrkSIRp43O8Mi
                VVZLoEfk5FxDQAYJDpYoYPXG1N1ixT660zIGUk0fUcELRVECak4a0lOgbWqhVkCPC8ieq3b13+CK
                hXOh4oZRu5ZLR8zZB9DvkpKS7Ozs3Nxc+ZZTXl4epzk5OQUFBZmZmeQDMuXrYPJNrsLCQnK0TlFR
                kVQhEwWEWgF6zkDkq16lpaWMAoGcSLGBQUJCfA1vLSsrq3bS0YgV+wCJdyInlTjrbV9eLxTZqcCw
                JQhyhkK1C1HDEaHqtBgYJD6q3Q1jyD4adFqc0CnYJeccD/nODQv4t1USFs4hO8nIwCBh4VyoyDFa
                tzFkH91juedLKpmSkiOv3mmuQdBqUiRhAvlABNLaBYamRy3914/AJFOEqB8YE8E5XlGQadFqtQal
                pfM//Xr6U29NfKje5CffmvXEe/Pve338E69NfvyNhDomPP721Mfe+uaxV6Y8/Wbya42sE+nuoP87
                t0GD6kMM2Ud2XuIn+Mz+/fu7d+8+cuRIvIjN5L59+9auXSuaGuvWrUOfiuJ1siPTHlgbqUf6PHr0
                6LfffltyCgsLSV0uF5NDmpuby2l6ejqpUDBbbooKCgoqKiqKi4uhY9SckwnI5FTkWoHcHXs6//ft
                fX54Y/+f/63LT2/udvUtA6+6tct/39r3msQ6hlx159D/unXI1X8d/NPbOv/kpsNjZ1m+oGXoJzaI
                Ffto33DepQcNGrR3797Jkydv3LhxxYoVSUlJY8eOHTVq1Pr166dMmTJv3rzevXuPHz9+yZIlAwYM
                6Nu3Lwq4IjkzZ84cPnw4tbZt21a7OAgChWrh3FdeeUXCOobM5DRv3vzgwYMMasKECePGjZsxYwaD
                pXTEiBHTpk1jiiAsag0ePDg5OZlSinbu3Lljxw4xUutwYsvm3j+6adSPb+r1kxt6XHNT32tv6/3j
                v3S99i8Drr4loY6e19wC7/S9+i99f3JTx5/dsn7oaJ8VUl93NogBYhj7CHA/uVFz98aX4JfVq1fP
                nz8f75o4cSLORn69evXwN9wMV0SzadOmKSkpY8aM2bVrF+1SBY+VDtTGT6yhy5KSkpdffhmZwTJk
                hJUrVxIMwrAnT5586aWXUICFyR84cCDps88+CzcNGTKEuWKioGahXYiYUs3sWkh8HNq2YfCPbp58
                9W3drrq+z1U3Dv3FXXh4n2tu6PXTmxLruO7mwT+9td8PbxhyzS19r7t916CxXL/atsutNYgV+8gG
                ykkTyGysTp8+DcXgS3jXBhsUEeyQSan8PbxZs2alpaVRlJGRcezYMYqWLVtGKUXc/+XBR22B0CWs
                ceuttx4+fBgZNiHt2LEj44Jhhw4dCrkMGzaMCGjVqlXMs+RDUpA1+7Lly5dDTAREeXl5EDR19df9
                axGyDx/o+h/X9/+P63v//K89f3hDrx/f1P+qmwdedVPPn9ycUEf3a27q/l9/6v+TW4f89x3tf3Tj
                vlHT1N0jYLZeMUGs2McJLh9AEOKQRxh2yXeevwoIlETNma9zRBBrtQWMNycn5/jx42zBGO+BAwcY
                BQIkwojkLyl6PB7UEBgaMpMAbelZQiaHWEkePAvQlyq1AoUpO0f8/h/dfnRj75//beBP7+h5ze19
                fnzr0OvuGHj17Ql19L/uTrrX4yd/7fDjv3T59d2nZy2zvD6r1oSYtQyxZZ+q7qE9SheRI2wiRZpZ
                8ExkUvFScvQ9vxZ5HdBPamSAzshF9qQIjIjBytiFYSVfUoYvMwCoImq1C1ywnJ37dy9ZsnfOgmML
                Vu5Zsmrf7MX7Fy47NGdpQh0HJs/LXLD66PzlyPkpO1iOJVa49oWatQSxYh9xGyBuIx6lWUP8R3uU
                7VyKa0QBWXusBpkiyG8e1jo4hyyTo0mEUevx6hnQpc6piJpAfZr4YO9CbwNEEYzdxWoI8V8g5A1b
                oYQ6gkytFfQqtgypT7ssqyzkM595xQixYp+zbpGifAxEKWiKEcHpXcii7HRdzXGiGWUtQUAn6ZiM
                SA8QyGxIz535AH0Zi0yjs26UZq1BKOC3goEQQw4FA5BPyArj5kGVJtLBLitghVx+NzTE1NNFEnoe
                GcWVBFZa1GKTNVmNiBX7CMTBSOXbW/KVLuef8UJIT09Hpghk23/JKycnh/zTp0/r/Pz8fHIKCgrS
                0tJIqYgmkC98SeaZM2fQkfzEQWFhIeMtLy9nmHSb3sr31OiwDLy4uJiRyoTI2FGjlCEzLvlGGJqc
                YkdmhpGSjxp2Is3UBmQU5p3JzswtLErLyTmTmXUsI52c9NzshDqK0rIqcgvLcwoKM7LzMnNz0rML
                svIy02vTPFcXWGysw7KyMm6B1c47glixDzd8Z+QSRaIUATIZlU4BRRLOyEdF5AM5hcJEQeZC1Jyt
                CNNJlcQBvdU95FRGTSpPfxBkXBIfkYOghwkQZCokEx1dUefUCvi8IYvrw07LpbZfBBVhe5OjoopE
                OjyWVRr0VoT8Lp/fCqtOEhKRHxnGlQfWWOwQ29gH4E5AfIzB6GeuZIqgnVMEUvE69MXBRDNKDcin
                RUB4B+iiRIN0TA9ZX1TpuQwTWfL1wIFzRDItukqsV0b1Io8++wP4s008IV+YUYQYW9Rjl7gfXCW1
                0yINByy3j/6qX9i84sF6YynqBVxdiCH7OPsq7uR0GBkMZOR0MM0j2v2cQJ98oC1rNfFMUlFIKNBb
                6bl0VTrv5B2gCdc5GwL5hVnR1/PDTIqmaqA2gGFvaz1g4qsNpr3ecP6bjae80XDu641nv/5F8huJ
                dcx5ucG8+o0mvfbxpDcbrm3XJ5CdU2GF3P5a8/fjqguyzGKNGLKPjEFS8ZYdO3bI05x9+/Y5R4jv
                iY8BYaiSkpJt27YhLF++XDxTQKnTrDi2XRLJSTRI91avXt2qVSt6yCnz4OwqpwBBBq5ZiVHLnkuD
                ujpyFOVahLT1G4f/zwPdr7m5/89vG3z1zZ1/fP2QH98y+Jrb+v/k1sQ6fnTT6J/d1fvamzv+4Ppu
                1926+5tprqDLCtay2a4WsAgF4ryCSFk1IVbso+/Smh1wp5EjRw4dOrSwsLBRo0bTp0+fNWvW6NGj
                FyxYMG3atFWrVlG0cePGTZs2cZqamtq2bdtJkyZRZe/evTNmzJgzZw7y0aNHk5OT582bl5ubO3bs
                2BUrVgwZMmTgwIE7d+7MdvzVnYQCA2/WrNnzzz8vp/SfOfn6669PnjzJAJOSktasWTN8+HDmgUlj
                RP37909JSRk0aBCjHjVqFDMjrzhv3bo1IyODpaC3nE5eTnAc27RxwFW39bjmpm5XXT/wB3/q+Yub
                B19zS+8fq69TJdTR7bpb+l1zS/drbxp47W3Drr1j59BvvGwSI2vZoJoRw9gHQJb6lg47fPXVV02a
                NCH8gUqGDRuG4+3ateuNN95YunTp/PnzJ0+ePHPmTCgGfeKj2bNn43ucwjLl5eX455gxY6iIr+Kf
                Wh/+gp6IqqgFT6tWEwly3yCKefXVV0WWAS5evPjQoUNQDLNRv359CIUBks/QGAVUdeLECXLGjx8P
                KTMVx48fZw6ZAT3GWkQ94PSOnYP/S0U6Pa++cdjVN3X9xS09f3hDv5/9rfdPbkmoo/u1tw74sRL6
                /+CmoT++fdOQb8pYxpEb6JUIFfxUIpJVfYgt+9BjYR/u9nv27CkrK0tLSyOugXq4n69du7Zbt26L
                Fi3C+Pr166dOnbpkyRI2Zdzzcbbdu3dv2bKF/GPHjuGExEQocIrfTpw4EXpCbdmyZUQE2Fy3bh05
                0mhCgeEzCYzr3nvvPXXqFDJ0SSZDYETy11w7derEuGAW+Gjw4MGkxH3QK9zEVosIkYFPmTIFumEe
                JPol1UFlrcDhbZt7/eyOpP+8uddPCStu7f2Tm7r/7OahP7ql79U3J9TR++obO/3sL31/dvOYq/7a
                4+qbdydNslxu9XGYQQzu7rFiHxwMRE5sQD2kZGq3IfCBQcSdgH6oIbs2Z3WqyMglUxQ8Ho+cUiR1
                E9AhpdvQKBso2RsuX76cfm7fvn3z5s1sQuEgZoBT2DMrK8vlckFVqJHDKUEfpHP48GHqQt/5+fkU
                6edBMg+1Arnbdg//3cN9/98bOv/kpgHX3tbjhze0u+qGAVff2uenf02oY+QPbu1vR2Td/58/dP71
                nftmL+L6XYGhD+s2CpGCakUMYx9NDXqPoMeAv2mKAZqAgPCIrkWpk1PIJwfBWUXLFKGQUJCOOYUo
                ipQi5zCZGRmRql85XhmaphtRSMDxngsBK3Rs6sLUkVPWjv5mx/DxqcMnbh49ccfICVuGjUuo48ig
                STuHTyLkOTR04tEp833FJSxHfzhyLa4cyDITsMxA5KRaURPso7sustO1RLALvxVQ0LJALEhFHSJx
                Sr4U4ZZRVRIEcKimFd1zPXCBztFgUDJYTTeiIMOklBSzUbUSGSruZRpCIbflV3sZ9e5PIORzk5NQ
                B5NeHPZV0MmwOqXL6h6oLsWVBduxvr23RXKrG7FiH7ruFHBCxiDhjHZC599HBvibOCpbKhmwnNrD
                /5bCgJQKxD8lR9QSCtIxoRJOSWXfhKzHK/kaMiLmSgTUxIjeXTqHr9qoDfAH1FemQmFLjYHhMm4C
                isqLmziQb3axTAOKiFQ3A8HwFfius724vgMmR5ZrNSKGsY9AelxSUlJoI9P++1a5NkpLS/XXnchE
                RsjPz5dvOZHDaU5OjhRlZ2eTSfXi4mLqciqlyJSiQ2ligu5Jh8vKyrKyssjhtKioiHz5Kg0zQI6M
                BTXmiqEhoKMFmTfqoswMcCrDt1uoBWCYXNLTuTnphQV5+YXZmTlZOdm5xYXZ+XkJdZSk5xVn5ZcU
                FOZlZdNRdQlycguz1d+hu6IgXyFEkB+lEl+udsSQfdSdpBIMgFs393NolRSZEIBMbu9yk5dS4LJ/
                bl1yEMgR0iUEIAdIFTIR5Ee5RFkbTChID0llaKTMhvRWnprTZ5kf1CglRx7PU5dTdGRQYoSUIuyg
                RqbMRq1AqMIV8PnV16a8jCTAYfmDli8Q9ifW4bWCZd4KFys0GPD4vKFA0O9V39SLDOOKAQuMUQME
                lhzA4zgVuboQQ/YRvwJRnRZW0pmapPQ4xUV1vvZPOQVoUqr1gSg7cxIHXEtS5zC1LJMgQxNZUpkB
                PRwUpErUlGo7iQ+2NN6w375IamsjwwgF/d/+tkViHAG2XGEmn5kPcY8gVT2/8h78sPZkocZ0jcWK
                fcRz6LoI3KWdmToHRDmSGrStJk4rUHNQqYAgChpRpwkI6bweAh0WWbOJLpLZkLGLrJVrO9hos6nU
                KfvH8vJy5EQDuy1SekgQKrcBgxghVuzj9DfNDunp6Tk5OfBOWlqa5AB9gffs2SMCiHLLQ4cOIWBH
                IEU4p8gUiVoCgoGAFi1afPbZZwycPstANLfqHJFJ2WGRoqxHehmAQeXbT3/w7cLCQlJOSRMNwj6A
                TgI2uQm7tC4DxIp9gDiYdiHuJElJSZMnT87MzGzevPmyZctSUlImTZp09OjR8ePHb9++vXXr1jNm
                zNiwYcPcuXMpmj59Okth5syZu3fv7tGjBxUhr/nz5+/cuXPixImzZ88+cOCA2+12Lo7EdNcFCxZ0
                7tyZkcpfy2GMTMWAAQOGDx/OFDGcESNGLF26tG/fvghMxeLFixcuXAj7bN68+eTJk1RxhoG1FAyB
                q8nA8WcBckVCgo4BoSH6adgndogV+ziJgOiGq0jauHHjRo0aEf7MmjVrzJgxw4YNI9754IMP4BRO
                8cbVq1cTI1B36NChO3bsIE1NTcVCr169CH/efvvtOXPm0BOUp02blmv/FVCJF1girBvVWOKhf//+
                U6ZMWbNmTdu2bZmE0aNHM7S1a9dOmDABn2QspB06dIBzYWdmBp6Cg5gZ8pmiy4B6AKNgL8M14oYh
                T9xFVu6eSGCh0iuon5SNIf007BM7xDD2ibpsmzZtOnz4MDHO1q1b27dvD7Pgab179x45cuTUqVO5
                z3ft2pVTfA/lcePGESkcOXIEStqyZQv0BNfQBwIiNDldsWIFVJVnf7cLlxay0/u1hMLevXtfe+01
                mDc5OZmujh07liAOfunXr9+JEyfgIKI/gqNBgwYR+7DuCfdgK2YAjyXEi1ip5WDgRBP4Mx5OipOL
                nGigVwDqkegMgaVlCChGiBX7CBEIKbDySPXjDFKAa7GlIhDgMiPrWEmuNKloYkdyJMYBYi0Kibw+
                GB2br0WLFsnp8uXL6e3+/ftPnToFDZGzatUqdA4ePCh/bjDL/gHstLS09evXI5BDqapZm8F11F6t
                BVJWRUJBCEhSgjVyIgMwiAFiG/vo+4YmF6B5hMyoaEWYBQXxtyiv08oILFyRnZadcoJADxbI6Og8
                IF+KkHUKZNJE1kjAcV0oGCx0wx0Ff9aCpAkFLgSXiYVHyqlcI4MYIbbsI9SjScTpWuJvouDMB6Kv
                FbSOpNpRnaC600KigUV81m4zHFnfMmQZYxTnRp3WXmhPlmEmLHT39IpK8A7XXsSKfbRfCbQLSSbX
                VXudXFp9pUXAV3V1vdVy8hFA1rWwI/qRsoSBdIlUuiqpFBkYJDJYq7JcNciMSNWEGMY+AkgB1igr
                K5MXKNhRF9lvVRQXF+fk5CDLOyAAnTwbetedX/mVKHlgKZrkYMeuUYRmVlaWvBjGKQYxm1CgV4yC
                DsuQJYeBiGxgkJhglZLiWfqxrPBRxKurCbFiH91R4UtOYQqGIY/0ZIPNKXENcLvdkBRFIgPGjMdS
                lxw0KWIuMALLSCYCRkjFpgi6euJARi38y6AQ6CQ9jxQbGCQwvPZ3LcWRY4HYsg9MASQHJxRBgAeS
                Mja9Y9JbLQ0y8dXIiQ1ytB2xIPZ1c6og8eAchXTbwKB2AefSIUV1IVbsI4jiIE6FO3SOk3GEZUUB
                WRRE1mrixvKhiTaCWWkINclMHNAx3XnpJNCdNzBITMhC1ZBMvYCrCzFkH+lx5MTGyZMn2SVpb4RE
                RCAHijl27BjDIyUHYdGiRVu2bNED1qacgo6Dqn1eqgvSsRkzZrRt25a9JLKQrIGBQWxjH4HQBF43
                cuTIMWPGlJSUTJw4ceHChWvXrh09enRmZuasWbOOHj3arl27KVOmyB+WgZhw1wMHDkyaNCk1NXXq
                1Knp6enJyclU37Fjx+zZs/fu3Uvmxo0bd+7cKa0k7I7m4MGDL7/88uTJk3v27BnJMjBIbOCzTnAT
                FSFSXE2IFftE9ZXoBnZo1KjRl19+KX/PKykp6ciRIzNnziSFfbp16zZo0KDly5d37dqViii3adNm
                +vTpkNSePXuIHVq0aLFs2bIRI0agsGDBgn79+q1atWrUqFHsYoTaaCUxI6DS0tJnn322Tp06DEcH
                awYGBjFkH+EC4SDYZ926dfDOpk2btm/fvmbNGgKZTp06zZ07d/Xq1dOmTSPkYau1fv16iX3YoME7
                +fn5mzdvXrp0KSQlf1nw888/nzdvHvrETbt370Zmd4ZLSyuaoRMH9AoQqb344ouy5RSijBQbGCQk
                7GUbQdWc6kKs2EfgDEYqKr+DrkdCgONkKO2WqsyG5Ei8QIyzePFigiAyyRFPxoIIiRn1aBD1yA8b
                ST8TvLcGBk4o7qml7EO/nbEJZCF8ETUecUj5SAtZGAcdyZFTDbGgwakoIFAlocBYBNJV/dF7pNjA
                IOEhKzYWiBX7CBFETir5wkki7K1IRQfnRIjiFIH4LaX4LQrajQGhhLSiM89qIUFAb0WQgYhsYHAl
                I4axj2YWnQqcvueU5UMrGEo4BQhbSb5A7DhzNMSUVEwcSN9kC6kRKTMwSFToVSorVhB1eumIIfsI
                hEFcLldBQQHxDigsLCwrKyMWIC0pKamwUVRURFAgOW63G6G4uBgdlCktLS1FQfLz8/NxZk7FmlhA
                QB8FyUwc0H86Bl3SyZycHHrIPDAbkWIDgwSGvNYbu1A9VuwDTepghBQOUhxj/2guKaOCUCAOTkUG
                MmA0pYh8UvFbHFhq4b3IUA8yQBMLZCJTV04TChAlPRfCJXCj8wgyDwYGCQ4WKuzj3LhUL2IY+6i4
                zaYeei+CbKnswuiNErLzEy4NPXKnjkBXRMfJdAkFuqRvHfrRj84xMKgtcK7k6kKs2Ed3VDGEg3GE
                TZyZQNMT0BXl4yGKpIqGJiD9nEjqOokpoSAd06keKSEbqXPsAhmvpFIUpWBgcHkghrGP+E8UmwDy
                09LSMjMz2ShxKrsqwjxkiQ4gFEAOqapg52iuIRWb4pPytAgBJCABSYcXLFiQnJxMt1esWDFlyhTJ
                P3z4cFJSEp3fvHnzsGHDUlJSGFFubu6YMWOOHz9O/siRIynSk2BgcJkhVuwj1KCpB0ATONiGDRso
                wgk/+uijJUuWkLN9+/aVK1fu2LFj27ZtW7ZsOXPmzKpVqzIyMnr37n3o0KF9+/ZRun///hMnTkBA
                69evP3nyJBU3btyIfy5btuz06dO7du1q3LgxgtBZomHr1q1vvfVWy5Ytv/7663r16nXt2hV+ycrK
                qlOnzowZMz7++ON333131qxZ6enphYWF77///pw5cx566KEWLVpMmjTpmWeeITNiyMDg8kIMYx+B
                3nFwG58wYULz5s3ldNCgQU2aNBk9enTbtm3lCxP9+/efOHHimjVrhg4dOmTIEJQR4KB169bhkGPH
                jqWUbvTq1Qu6IZro2LEj3svp4sWLiR2Im44ePWq3mUCAcxmFfDXkl7/8JeOFQN94443PPvvsgw8+
                gIgfffTRHj16vPDCC6hBT7fddhu1nnvuuT59+rz00ks33nijy/5NMrFmYHA5IbbsI5svEVJTUxs2
                bDhgwADJGThwIJSxfPlyAoGFCxcSyyAQ1OCK3PNxRZiFRkeNGnXgwIHVq1ezSSGFp4iM3nvvPflj
                p0uXLiWTHAKKU6dOYUSMJw4YOLFb69at4dPXXnutWbNmdLtnz54QaN26denz888/f+TIEQJAwjfi
                O3iHIOiBBx4gsoN5Bw8erOfQwOAyQwzZJ+qOXWL/NrNkEhFw2weyV5JnOkBy3G63fthBjsiS5uXl
                4Y2EOdhhWyfv/tiKEYUEBIPt0qULIR5D69atG9EfpMN8Tp48GTomXoM6CYXIlLDuk08+gY4Jechn
                jMyA/rDMwOByQgzZRwPncVKD5gv8Ct6Rezsp+VKEy4m++J4IAAFUZRnhI4QEJCDpv0APXKDHKKcA
                BTmVIgblLDUwuMwQK/bB6wSRc9u1OBV3Qj7XhgIFKRJNpwVkcUtxY22hqhsnGjTvSJ/PNXZBVKQj
                8xY5MTC4jFATsY+BgYFBVRj2MTAwiA8M+xgYGMQHhn0MDAziA8M+BgYG8YFhHwMDg/jAsI+BgUF8
                YNjHwMAgPjDsY2BgEB8Y9jEwMIgPDPsYGBjEB4Z9DAwM4gPDPgYGBvGBYR8DA4P4wLCPgYFBfGDY
                x8DAID6IG/vIL2zp381y/oAWRUHH3x00MDC4LFHT7AOnAOeP+3EqXAMiWZWQTAMDgxhBOxouKdCZ
                NYD4xD4ywvLycjnVcI7c7/e77D/cbmBgECN4vV6Px6Nl/RPANYM4sI/8bvExG/IXuJwcrAXUoKci
                AwODGKOwsJBU/zkZIiBxw1ijptlHyBVyIa7ZvXt3SUmJzgG2yreosVkwMLjCUdX7agBxiH3gFCj2
                4MGDqampaWlpkhmXwRsYGGjYAUAEkawYo6bZRw/MGdc4/xgOChRpRHINDAxiAJtq4nbjj0PsA4Ru
                IBdGHkU9cZwLAwODmkR82MfAwMDAsI+BgUF8YNjHwMAgPjDsY2BgEB8Y9jEwMIgPDPsYGBjEB4Z9
                DAwM4gPDPgYGBvGBYR8DA4P4wLCPgYFBfGDYx8DAID4w7GNgYBAfGPYxMDCIDwz7GBgYxAeGfQwM
                DOIDwz4GBgbxQRzYR/+cmBbkZ8b074qJDOTUoAbAXIcDluWzLNKwNxguD9gZqiAUJg0EAuoycdXC
                KjW4zBAXj4sD+6gfTK38s0FCQFHDtufBrPAaRTAA8dgcpM5CioQU51ihACV+lWeD6+IL+M21uYxR
                k94XB/YBPp9a68D5s6oaNTl+gwjCxDZejxUotcLbDhzJL3L5vQEr6FMcVHktvhXkH4PaD0JafDBe
                7hYf9iH2IXX+NUHFNzYi53aOhEgGNQC1vbICLsu75czJt9p3+2rAqNP5xZG9l31R/H6/854htQxq
                O+SCxgs1zT72rktRD+m+ffskU2aBlEw9I7Cyx6Cm4A+EPOUVBw8fertlmye+7vxUh16fduuVlZdf
                VlZGKbzj9XrdbrfS9PvtGgaXA7imLperwob8KcGapKQ4xD6y22I1b9q0SXI0nOzDimdGCg1qBHn5
                hQV5xRkZBe2HjX20edsnOnV7s1OPQxl5ZRXlpaWlOTk5BQUFMBFpfn5+UUGklsFlg+LiYvYieOVl
                zj4CQpuoP6PsBJmQlA71DWKPEPeEXHe45zdT3mzTvsOEiZ/16jc0eYnL4+bycC3kT87aivbjaIPL
                COJuAmcEEGvUNPvIwCQltCGVZV1jAzY4O4Jut2VNX7/54w5ddhw+7LGsHSfT3m7bfV3Keo/Py7Vh
                XXKNwsGQYR+D6kLcYh+DOAH6UIk/EHJbYfV+T8BnhQKWz0o5ePyV1i2W7d2lFFx+QqF5uzc+27lr
                6rFMcjxBL7rU9HBvVA/uDAwuFYZ9riwQtegwk38q3C6RjhWUN+zQefLyFZwT5YTsd3zcVnDsvKXv
                dex8sqSE0wCbM+qE1DuJBgaXDsM+VxYq/H4CGHfAY4X9ltpGWZ5AuMgX+Lhf7+6jx/gIeMJWIBhW
                nwtANAErWBbqNHn8p4P6FBMM2dTD4aK6gcElw7DPlQUIBGYJsuUK+iwCnJBVZlmdx034bGDfXB+Z
                ip9Q86u3DwNqpxW08tzu9/t06zc72QfnqMAnJO8BGRhcIgz7XGFQwYsdw7CNCgRhkbGLl77ZpfPx
                ggJFNR6iHUIegiIV/qDnh4EC1rG8wvpft05euwkd9T0Lwz4G1QHDPlcWgl6fFbAfPIctr2Ut2rrl
                w/btVhw6TJHbTYbKD3rZlykWKg2wTVNEE/ZYm/cfffHrVhsOH1Hhj/p03sDgUmHY58pCUO2bJKSx
                tp5Jf7N1KwjIp8IZKQ+FQoGwFfL6feiRFwz61cPmgDoZt2rFh506pOUVukXXwODSYNjnyoLLjmVI
                cssCX/Qb0HX6VPVduwD7KfsdM/U+D9uxkB3wEA4R4yi2goHgJI4u48d93Ktbqco3MLhUGPa5XBHy
                B6ELS1IVxti7rbDPRXxTEgi1HDWm6dDhJYQ9Kv+cz3GgGV+AHRmbNbVfK3R5m/cb1Hr8JJeiLMr8
                ViionkNT6gmrl0cNDL43DPtcttA/xBP5koQiIcUZZcHQkHlzPu3a7VRBmcQw8uM+ZwXbLpu8giGf
                1wopljqUm9+gR69Ri+arR9ZBRU8QEBZUG9KegcH3g2GfyxPwgP2ARxGHHfaojZXX64Ut5m7bWr/9
                1ztPnFDMod73OdtvLFWCoMn+MUOqK22vHQit3bPn3bbtUnbthXH8RE8BVeZRVERDBgbfF4Z9Lk+o
                gAW2UA9wFPUEAurJMhyx81jW263bzd6+Vb3VQyHEEbaf8pwL9pe6glaogi2bbYqtHHYWrNr0QrOm
                hwtLlFG/zXPqdxDNW4gGFwDDPpcnbDoAii+scNDt9UAxaQWF73bvNTp5oV89w2E3xWZKRUiEPxH1
                qrCjGbffBwGp8Ed92SIUCvohna7Tp37Ys3u5N6z2Y6ip/dv5eMzAIAqGfS5PqDcK7X8VU9hRTnpp
                WbsBQ5onJZV5QjZLhDyWz+/zCL+cE+rXpqAX9dhaVSkvs5lGfdHUbVlfDR/cbOAglRFQbw+xB7Pr
                GBh8Lxj2uTwRYZ+w2g4hlgXDo2YvbNild567wu1XvxiPii/gpdzvVT/efE7Yj3Iod7vd6h9bkxDI
                F/ZbgVBGcekn3bqMmj0fTlKbLv0bQAYG3wOGfS5PCJ+o13fC6rsRi9Zvebt5ux25pZb6lilRT8hn
                Rz0en1dtus7ztNg25PPZhIVWUL0ZRM2g5VW/COS3th898UKXtjO3b5FSA4Pvj5pmn29/Io+FLeE8
                q7ry1+Pl1OD7IxzyqUDHnjuOQCgYDNsvBgaJfvyBoApYth848+bXnRbu2FGNOyPFM2Er6Pay4Ure
                tfn1dl/vT8+zfJbffjhNqSI0OoSkruq52c0gAWA7XwSRrBpBHGIfRuj3R/5EFEIUH6lX/W3IjzxG
                CgzOCfV5lp5PG3C5Tz1ODqk3cg7m5X7Sseu0Fesq1A6s2uZTPQkiirKDnaJwKGnhgk/adjhdHPkZ
                VlVkL2MSlWN+jixRUcN0E4WaZp+qhCI5Ev5IjsGFgNmLTKn6s3/299fVh1P2G8jZ7kDjkUNbjB7q
                wv8jYUj1wKYdu2WP+t2fCsvqkDSyydDBpUH7KtJ+OXkBj3kUVEsgN35xwxrzxJpmHxkYqdfr1T8a
                HzVaNfrKIEhkg3PBF/DaL/WoRzxBiTjksNSWrPs3Uz/p0ycHhgh52Av5fZG5vXSoFiWoYTtXpr4T
                f7yo6NM+PfpMnq6+Ke+D7CDCgMvyoBbymOuY0Khh0tGoafYBDFUEQnSdChi/7Lki5wb/DvZ6URSg
                oh5SPD7ASlKff09ZvfbdNp2P5RQpBcvrDyoiqDao771bLpvjVLs27+07k/Zu5y6TlqxQ1y/Mtlp9
                pqYKzvlFDoM4oyrj4H34YOQkxogD++gB5+fnyynQlOSEpmSDc8KeKL/PIx9v+ewvd7F2Vu5Jfb1V
                q41HTynO8YaCIa/92/GRSpcO+Y2fUitQElLPehS/0XDYmrdz1ycdOmw5fNhl/3C9TT3q8VCkmkGC
                gUtHiqNBOmf1wZgiDuwD/H5/cXHxpk2b9OPSqhFQeXk59JRtcF6cSU9T6emTuTlZID07J6OgaMOu
                PR926D5k6swz2dmF2bkZJ9OysnMzc3PSM09LrUtHTlZuZjbNZRWUFqalnT554lhBTm5+ds7JnNxh
                M6Z/0K7tpsPHs3JLCrMKszOzTqSdjFQzSDDk5Kh1k5mZSYq74XQ1FviAOLCPptjdu3cL+zoh8Y7I
                UBKywXkgHyfZX1Jn5tQToLSConZ9+naelKy2O34VCbELUytKLSr1lyqrBZEP1H1By+2xwqFgWH1Z
                w6t+GsgqDAQ6jhvXauDQwlL1FXjiH5dlnvskKJSbObYjNYy4xT6kZ86cEaKN1+AvA6iZ83jVn9uy
                /PyX57HaT5j01aAh8vgsHhOrfos1p7C8wZBBHaZNVZykfpnMpXaFHnXR6ZD8aofL47Y3bAZXLuLD
                PgLCPBGc2y6DC4P6Zic+7IeDCHZGLV/5XodOZ4rLZBsfxT41QEbBgMfvVX+YZ29GzocdO41fskh9
                A8P+aIyDXsm15szOMriiEQf2kfXnRE1uNS83qDcLmUH1K/Czd+x4tXWb/SfSnK8eakA9NcA+UArX
                UjXjt9bv3P16p7aL9u6hb+prYgoQJZyoOMimI4MrGnFjH9l8Re6EcdggXCZQgQ9T6LH25OS93rXz
                gtUbiIbUGzcOCO8IIlmxQ1B9vK5+iN5+C3HcqhVvtu9wOK2IPhLsqNcgYR/7V4fQMeHPFY44sA9Q
                y9Dmnaq7A4MLgmIfr5XvDr7Vo9vQ5DlEHHaGmluBopyanGH7B+rtZ80h9ZvSltXjm0mf9RuaV+FC
                9vi8lk1AHNx+zIW/wlHT7BPlCZxCQJETgwsHs0es0XTosBbDRrjK1G9lqG9YBM6696oR0LhPfQFM
                0SKX2m/RqS8GD+42YnSuxxvZYENAioNM5HOlo6bZRwc7OgWGgC4a3pA1aFbyh126F7rV7xVW+Mrt
                vVj8/DpshbyqebW54rLahHO0ML9B517jFy4vs78FFvQH1JdCUIhfNw0SATXNPkB4x/nER2SDi8DC
                rdtea9X6UEah16W+2K5+fscVzz/2p66n+rNgKq7xRv6Yj9pqrTuZ9eZXbdbu3OfjRmPrqPtQpJLB
                FYo4sI/BxcBT4bFf7lMPVixfhaUeo+w5nPlq86/X7D2o3qTBlfFn9Vf/EjKmCIWnbtz4XtsuB45k
                qEGEXVZIPfeBhRQRcQNSfzlD/aOUE7D/BjGAYZ/agSAO6/WzqVLvE/NPKHwyN//jrt2nrFidVWF/
                fVT9H/nbFQnovDCOzxsekTz3o25d00tLI0+mXb6g+tlpte+WKNjn8wUCZht+pcCwT+2A/avJhDb2
                j5K4raDbatZ/QJtvRue71B+rUAgTNoT8QcU+CUg/6jN4v1UeDDQbO6Jl0qhC+SM8Qb9iHyT1JnTk
                PQGhIYMrAYZ9agnCVkD9YIX6ExN+rzVw8txG/fpnVJRKUZCQJxwkmoj82qCdJBbUF8MCsOfpsrJ3
                evYePHMR3faHKoR6lFz54/ahkHoVyK5jcJnDsE8tgc0roaAKEL5Zte6Nzj32nMqw/db+G+r2nxsN
                qCOinHBQPznkDwbc9C01vfD1lh0WbNzsISSyf9tBCEh9dVV9ImqCnysFhn1qB4J++2lIwFqTmvpC
                l/YL9+5XLxayF1MvDNuHoh71h7eUWuK5L0RD9Ka+hgGJuq3VqYdead1q29Gj8CQZ6lcubQKSB+Yw
                qdQyuLxh2Kd2gO2I2x86kVfWoEevsSuWqeDGq6jGr3gnEjhAQsqFSRKPfYjLgl71TEp9wyYY8gZD
                g2Ylf96l//GsTB+ZlaMIBRhGIBCK39uSBjUIwz61Bf5cj7fhoOHtR0+w3MpNfYQS9peq1DMS+xUb
                gf3rzokXO9gfqAfUX2BmC+YJeV3EN82TpnUZPCDfVVH54JyDcrWLlAyDyxs1zT7cmdVrZvbWXm/w
                1Xfc7Z9fUC/n859X/Z1M8n1WSD3nCKr36NStXrkaoXtIfkwrpoePxgKhPGTiC+7cPvXXh9XnTd9V
                q/7DzR4k5IFsmAEyhFOCVqZl9ZowqW3vPqUe9Qez0CFMUJNmq1z6EQqqmIR21VtFdpvSnSi1Szmg
                GL+jw55AsMzt+ahvj0HjppJf5mfn6PdaXvWD0eqXf0IBl8vmLK5FyBVWf0g+0qfqOFxsUhmnRy0n
                GvQGAxVqnlX/yoM+tepc/pDPr6aC/+2XqKrlsF+JUO83qVN7v+zxuQkMuah0gqbUUzD12ihT8p2K
                sTrs371VD/9VdMpKD3jD9qhrBHGIfYSAtCwCV5o0GLb/MIM9Lf5gwAXZuP34A9eqjMN+hbYkoP6C
                gl7EMTvUr2TJuzUcBawIlRGlU/2H+mQdB5RZcSk3qPC5WI2jFy3/pHX70/mFFepnfOw+QYt+9eOB
                1XLYbgbFqxeK8Ems07TqRRXNSzpwY+epL3gsK+etvt3Hr1mnHmPBMF63FfaX2pdbdQkXLHXZT7jQ
                x0PUh2HVcsBvzB6Mo0jHozxdLTOa8FlFNO1Rr1aRQ7PF4QAUGFX9og81p3IEw97iMjUoO19+eg2h
                hI7ZaoWWoj9dMUZHhRXxLw664pUHb5zUCGqafaB2GOdb0pGPPCKrS1ESAgtQ3Zpgf7VG1Cp0ez1u
                RQVqFTBZal3aDhjDwx9QSx2BDnnDpVwVusm6cOrE4Ci0Cag0yDLgPOBlbYStDftTP+zYZfXBw7il
                DN+r2EERRHT9iz3UOi9XrqBuwjTA/ZDLUX0THVbPpQJyyHcv5GBEi3bverltm3XHjrspUZkBy373
                x74AavrtK6DqqEUTqXepB6YYXNgOabGvRg24vhUh+Eg1b//ZesVN6CN9t/pFH9Iu91FajPC7/bOU
                6lCthMthaG7AfqvYfnE0qnr1H5HthfI2/i8V4rGTGkBNs4+Td0QQkCtFhDz2i7Aqy1dSrr69RGzM
                Dd/PNIWyw+ojW3WLVhVieHjU36JSAj1RfbACHhiBpqtoVvNBE2pR4HrePNagxzqTll23c6sFS1fL
                nKhCf5B4HaHyIUm1HMq2z+ZYDq4Cpy52xNFqF3vI6KoeJJ7wpGUr3+7eLTOnVN1vLPzf3nyxB/UH
                2Gl61atMtrK92aymw/412iD0FywNe1hdlstdodiQfPXuQgFbMZYo+9FAKMC9L7r6RR5Bea2JIxT2
                eGjJjl794RKWG31gB6D+Dloo22+/CaUIMraHCumZigq16aNdtSX004EaQk2zjwCiCeBfTgLiLsQZ
                q9TlVfcB7n+WlWfPf2nQbW971Ltq6iGMWz3y4CrF9LCC6kFDhdf+EkMgkKu2AupHiqPUqv1gt1VK
                I37u+4p6SvIqPu7Ze9CcRWpJ0rwsmkpn9qutl1q9l3542HAx7WrCcT8PrZfZDVbXAalyiMxtVg7F
                tF510y3xB3tNmvzJgP4FZHlhnoDXCkL6pIxbdUT9+iWk8J25upQDcsdmUaCskF5gWD16xA/VZruc
                O5z9t/DVD2Wrnqi+R1W/6EOF0yqoZI/pR+CGCv2VMFjGrV4jtfugNkOkISLSqOrVfqinX0G1sMN+
                mI/9pu17atHXBOLAPj6fzxkBiQzLqM9i7ViDuXczM1DN0ezdrUbPH/DNoQVrLI8Pf/ArjlYK4n4x
                PFz+071njBk2Jn34Apf9pNBy+60K+8LE9Aj4yy3f/nELBo8au71fctNRSQ1Gj6zIDxIPUmj/EjYk
                rQ5fQP2tPiavWg5WP15Y4SvP27Tt4Be9DrUcEDh8MhjmSkVrXtxxToRCeB3LwO8KNuvVv8ngoZOH
                TDrUbao3u5BRMmrFUICIIMw+qdpiH7b5Kp5hIn1WeWkZYV7xxn1Tegya131o6ckMq9RrlaogxetX
                X+zlJqQrXuJBtCOimzDI5mJ1rzmaua3tmAX9xh1alsKMsPmCpEoC9v7TUTcmR17xpsFTFvcemzpt
                sUs5lt9DJKjoqCYQB/bRIY/X65WHPoD9v3rug49bwTz7VmBllU6r3/TFNu1e/KJpzxc+L5i1mevE
                dKHj9ar7R0yPzR/1//DTr/7Zoe2X9Rqe6DiNyId26WuUWrUfjHD3tFV9nvr8Xy1bf/pF2+fbtS7J
                KqJhbsUeXwUUQdAQwg3hBSXDhpRVx+ENZRNlbk5df+MLs394167fPbfizvqlBVnRahd7qC/fVx7O
                fDY2xLrsqwl5Vk1Z+nqHbv9o2bLBJ83Hvd/WylEfNpZWRB5/uGGEKtN10QeXstAdoFFXUD1mtnbm
                9Hvkg9faff1+sxZDHv3Yl1GeSyZ3OqLugKLmqOoXfTCEsmI3rcMsRYQ/hBqnKka/2OTJVq3ebdam
                6z8bulbsp4yYRAV76o8VxfbY/mav9xs1fbZN8w4vNzrcfwZbP/UXPiMOGnPEgX24iUFAWVlZ+/fv
                LygoIMd+A03dyNWwbZ+yPIH0E6caNGjwcvOuz3Xo+uLX7T9p1fGrsaM/HzqkS7dBTYaPaDZkWEyP
                +s1av9Sl2z8bNa/Xscubrdp26j+8xeDhzQYMj1Kr9uPr3oM+bNXluTad6n/V6am2Hep/2WRN/eab
                X2m8rn7zlNdakm58tdWG+i3Xv9x881tt177SfP3rLavlSHm5+cZ3Wy+955UFv39k4V+enP6r+xf8
                7vFNT38SpXYpx7rXWqx9tTkHgpxybH+pzZo3Wq55odHWN9r0eKflC83bPd2hy0ut2n/4eYuv+w/5
                YuCgFiOTWg0a1nrw8FbDRzYaMjhqui76aNp/cLfh45r2GtAyaXSTXv0/79jj9bbtn/i6xUvNW9Zr
                2/aL3v0/GzCo+dARbfoPazlwWJu+Q6OqX/TxxfBhXw8b2a7vkLb9hrYentRowIAmAwa99mXzl1p2
                e7F152e/atWgXddmw0cw6rYd+3w9fFRU9Wo/XmvW5pk27eq37sg9fkC3/hV4oks9ia8ZxIF9BLt3
                75ZUdl72/U19MMJtQW2ukNMLOj33zhPdOj/XokWjL1ou7zbmQEl+dlZBXn5JZl5JVn5xWnZ+dkFJ
                Rm7hmay8nMJSjvScgsy8ogs6qHUqIyevuBxryJKJneH1mz7TtsULrb9+vWmL+R/3OJWbn5tXfKag
                hBZp+nRmbm5RmTRHBy6iXQ5MUZFD2pUOZBQULfq83zsNmj7TuR2RV78Xm+5PyyzPLaUV2kWNFFmU
                L6JdKpJihOrSAclPL6rYVZx1dPDE1b95bOrvH5j720cW3fhC+opNMkwZtRzOibqgAyOkMhAOEU7m
                FubnFJdllZwsK5nTfuinX339fMuWT7Ru07P+Z0X7z+RkF2QW03RRVmZefl5xdn60zYs+TueXHsor
                ys8pP1JWfKSsaO20xV983PzVr9q+0qRxm3e/3LF8S2pubprbk5VfmpZfcqpK9Ys+TmdkFxWWFWAz
                I/t0Xn5uTmHalgOd//XxUx06PN+m9XtfNFvTf/KxipKijMKczIJTRWqemSgOBJk3yRFrVQ+ZWLmy
                +IWuIvlyisBFRCAd+OqXdb/67Lkubd75qtniBj3y/S6oR+34agTxZJ/i4uKjR48iEwoRBioGUtFP
                uMyrPvmw3KGNo2f0fPzTN79stvqRL725JSpMDViF6iGhfvAaOYIh9aAWQcVPF3hQy2M/+9QH1krn
                bpn9YIMXmn455eGG6TuPyDMCukURh1bTVaJs/tsjYP/GqD7ovNennggwwIy0zPlPfvlW468G1/mw
                eMJ6BqteU7CbEx05MCLpBR1S1+cPSgd0Dlt+9SlLet7Oek1W/erxDb9+el+jXtwGUKBduqc1JYf0
                gg6pQqN60kRgdMVsgyr8ljtYdCpr5cONudzD/tl0w9hknIDx0ge1OUdVPX9hy/kdsxd9cDWxrKwW
                qx/GtgrKF7/U+v3GLVu8//mCNoOtPDsAZzvIdg+lKtUv+lA3V1/A71VPViIdKAss6Du625MNPmz0
                1ZJnmvvzy9Q6s0s9lbOkKjoWnuSc9YhS4+CUy+f0DhGkNGPiillPNan/5RfjHv24cN9x9VCc/qn2
                awI1zT7yp7vcbje7rePHjyPLRizAJpQrYk8JF4Z/1eUv84fz3Gu3bbc/IAkVWB6/Wz12tZ8WqT00
                l1F9NmTL6jmCCBd7YMrvY6ut7KgnAwWu/Rt2BYrUE8ciyxWuUF8OUJrSUGVzHnfFxb17ffaKIX+o
                wuWv8O7edzBwPJ9JYEHYrlCpEA4yaoRvB34hhxpgpawtMPXkYtRnhdzuiqyjR8+k7lcv3RCB2lQf
                pX/xR9VrpJY6QqCIMfqCliuwZvPWirQi1Rv1+o9NAupjbyraNOSsewlH0G1/iF4eDCCHvFZ5gKZ3
                7tqXk5Zm5ZWoT2BpVD2gtP/+j0tNeLUc6gE2CAS9XvWT116/T43Kax09dLLgeFbA/sTdY/nd6k7L
                dH13vN9jhUddI7XAKmtFLmXlqc/rVtc94C9Lz9u7cRd9sD9XpnsEPyjUBOIQ+7hc6pmy0JCksIla
                WGqtsedUIlfF43IzS+zEjqSnZTE3oZBaoGH1KhoMLjPIVH47p+FgZK4v5KC6UEDETuX1K7MCZQHv
                6cwcdsHlyispDdhfxVYXjypaP3J81+y/PTQLsAhEEE6xVG/UC2+FpRUZJSU4Hj2had0cgsiRnCqW
                /81hG+FQw9SnMBqz71afM5KV73KfKS2hN7iAzIZMkfqBIT1qbfB7HtLhSuHb1vFw+4/NZxB7BdTA
                j54+TWPqMwj6qFYE1dXaYPLd+Ko2eImHfUUxy8VlhtVXYC1ra1Ya64ybjEetu5An6C21fOpZtwon
                qli4qIOG1aMG/lPv9agP9VTcH7IO5GQFyjyljNcKqted1MN4Yt3Kh/TIHLYFciKZZz1sTbleWtY3
                6cgacyw/Wiz2enIKSwrVnzjy+sJeyx+iDeamBhAH9lE+7IAwD1OiTkjs6JB/yVWfsLqsA5kZuIFa
                K/7IOylum6/kgRFAECMXDbUCoDyv8riIKdoKWeycKzhz0wvu0lDSt3RJSrtAOclFtc7VFmtATCnJ
                Dd2oICQ9O68UIlYvv9jhcmUnI2p2T1RQcOHQjQJ6Hhk1y5Q1Rwtuy1MROnE6syIcKpeLoufEBt3Q
                fbgg6FpYkLEAOqNy7RLcis3G3tOnGb7qjxUqdzEZLA37sHOUXnWAkIe4R21x7D++qGak1NqXnm6V
                q4/YFPWVqYkvkZdO7fmvFrCQ1Wvb2Pd51Wvf6gUbLnOoqLC8osxDV1RoVE4cEi5hsPasO6fr30Ku
                FAsDgVrOCyc5QK44boisdhUl/kOF+Wpq1RtmKlVveNcI4sA+asyy7OzlKM5AZsQryAupdxHtOEdt
                gU/k55fbvybBBHF/QAX2UktEvZ5KHKQE/UDE5q4LOHz+yCMVqS7bYzLZG9LSifyiMpYCDakX0tUr
                N6ImzUldjgCrtYrl8x9SkQNZP3VSj0UYnldd/tyissOeMkptDv62h+RIJ/XphR7yxIdGnXbU2+SA
                GQ9a5WErK18FmsiMMephk1TX1r7/IRU5tIBxhuZ2qxnmnDs6baaXlkI5XgnHaAjf5F97SajT79q8
                6EOBttnoiBCwKsJWQUax5Q57ZV1yvX2R+EiFKFUsXOQht5DKy8kpjbsCvsNlxaEydePxetQtR9r1
                2bdhDipKSj0tn/XQ64SDeSYHgQvt9ihWdR6Sw8yfcJeVlfoUyap7faiCnaialJpATbMP0131zqkY
                mtUZUF9u3Lx1C9dm9cpVy5YtU9+ypAR1r7Vk6XJ7paivI6vU5m8g1iR1Mv33B10CCFlZWWlpaZs2
                bZo7dy6XMSRvmnitrWtT8A+PxyX99ngUA4KLCz2ckE0o2LBhw8yZM3Nzc5MmflOUo75iUeHysAY3
                bNjkr1CvhwhEn7uWNK17chHQVyE1NXXKlCklBYVb9+8pIuxRPzGgXrQ7ffzE3hNHtCZ0LNN71iv4
                fSCT7BSKioomTZu679DB9WvXzZ08U11Xv2J25nxNynqlgQey0bbbk0VSXQj73EvnzF+/Yv3WzTtm
                zJqZ71WUx2zSwuyNq8pxyrBiQPuf6mxXTK1as3revHkMbdeOnWVlZUxrQG17rVUrVqtyV9jFWuee
                Go5EpgI1D/bMS3oeOGthf+XKlYsXL+ZCjxo1ihVOplD58uXLYSYaIg5jsSkHDKq9WXWO97yIT+wj
                6097r5pNm6QrvJ5mrVoyC/369O3RrTsLTs2N21duBSZMmLBw3fLuQ/qmrF87KWnkwgXzZO+qt7Iq
                FeGCDruW2khboVFJI1L37Bo9auSGlHVr163EOvEAx4ixSSe3bB/1TdKWLZt69+oxZnSS6EtzsqP+
                js3veYgF+0nw3tTdU6dMGjZ0cG5F0ZTJE70el3L0gDVq/Jic0oKRI4bNmT1rxPChgwcN8LgJjCIV
                1RFl898eBHHyGx32gXzyxLGjRw7Nnr9gYP8ByNxBlS94XUeOHd6yIWXypAn0auaMaZMmjj+wf69Y
                ON9zh3MdXHaZqEqBC3fm9Mmc7PRvRo/asHVj0jejudnkM9/qx1VCY8d+s2zJ8oF9BuTnFvTrPzBp
                7Di1UJj1KLMXe7iCFbtTdw0ZMuz4wcN04HTmKTUo7PsDI8YM37d31+jhQ3Yd3jNoSP/hg/oXB9mD
                Rlu4yIPBWdae/QcmT5l27Mjxxg2/yEjLVFkUBEMDJo+tcLtGTRqXNG7UjEmTRo8ZkXbmlNsVecH9
                OxMYZbbyiKxMZMfTn107tw8dMmjihG++GTemrLRYVx83dnQg7Bk8cujY5GmjJ3zTr3+vcEmx+uLF
                Zfzc56zQTDRunFpnAwYMGDlyJPdbiOnEiRPZ2dlJSUnTp0/v2rXr5MmTDx8+PHbsWKEwSeEpES4O
                tEIcgVnuD9wQhg4dunHjRvLpwJYtW2hr1apVBAgTJ06cNm0aPaFIN0ddIPIFQe4/gLETbRH+YL+0
                tJQBisGUlBRy6NXUqVN79uzJzMyfPz8nJ0dqgYsYsrO3cock1sMsE7h69eqTJ09Kr9avX7979266
                xGBbt27N1Scuo5MS/lwcqCvV9cBpbvTo0SUlJQx5586dknn06NHMzMxJkyYx4W3atDl48ODs2bMZ
                +6U0XRVYIxA4fvw49rm4DJbZIJPLPWbMmLVr144fP37OnDm0y+TTw0i1S4ZMPja3bds2a9asdu3a
                Mcm0Sz5LjsvNkmP5kU/TzA8XxTlwmbrzTAVFsir0JANM7dmz58iRI8RBztXFxc3Pz6e0W7duTPiK
                FSuYEOmMVIw1EoV9gMzXvn372F+w7VqwYIHMV0VFxfDhw0+fPs3ssDLWrVuHi+7atQtlpulSSAc4
                q+fl5RGXsizYBJWXl8NEZEI3tMganTFjBsSEb0g+TctFurgOCNtypQEC154ZPnPmDM1lZGTs3buX
                TAJmHID9IE3jgTt27GBxUJF2JVWGLhx0WBq1RxBmVvHDY8eOYRwKxg9RYJhMNZ5A06xRPIQJwRnE
                wkU3LZAOsH+kRZwcN4DfiW3xECiAOw0yywBSwFVYAwcOHDh06BDLw+lRlwgIhUW1cOFCWqF1lhwN
                YT85OZlNCj1hwvF8rjuj1hvkS4fMOTwO7WKWOyu0LpebztA6MwPtMufQcXFxsZ5zgSyb88+/LEi9
                LLlwLC1mkivL/Ru6QSBfRop/cYm51qwuVhot6oo1gARin6inGDLFkrIs9IyLoL2XU10kTnXR0NX1
                BcAyMsBVpBVS1CRTchBs3QuGVHd6lBhHYHRiVndJJkeqUHrRfuicImnC2X+xD1BDliLdlpTq/AuC
                GNQWJBOuwZRzLE7vokh6y+lFj/c80J+9St9EkBxADh0g1WrVCG6opM7mpBUZvkBkmWrpHtBdPRf0
                2kANZamuHwNpGnUOSnQAdcW4s2MxRQKxjx65vgbkyNToWeNUz6mGnKIsFi4C0hDQAn2QU0oRRMfW
                VZBSkS+6Ub0mWAqsGOclp0jMyirRbSFoGVyEY0QtLE5pGpuSr28A0jrDlHzpnmRe9HgFVHda0MPR
                S1+uvs4XQXfDzqsGaFMyh1GnMljpj6TVBbEmg5JGaYtTIGNEQUqBXA4plRzglM8PTGFTm5VMIDNM
                DgLjFQWglWsMicI+2pGqepRMN7sABD2JTJPk64txcROnF/254CwVvqjqAxfRtBih85qDAG0xfN0i
                Q5ZMlCUffRmv6GjNCwWNRjk5Q9A2RZAi3U9SAaXO0++JqlX0zNO0XHR9TUWm1Dk54CLm+VxQg/zu
                7DHbziuLrBWiNC8FMu3A/rEUBT1k58p3TpfOl26cvzNMEaAVPRYoTKxhB4Hq0getQKZuQuqKXANI
                oNgnCkwKc0HKdCBEcu0JYuJkQoFzEkW4OMi1oS2xg1kgV1pYQPdBu4RWuMSmgXNjhVmaINU5Tkg3
                pPQiELW29HgF2qy062wdWS6HyJL5/UFF6bmu6zQiZlGQzKjnLDLhev6rBVGd0VwAdMRByoRUuzcy
                HGmdVBt3NqRHGtVJnf99IBNOetalQqaY1byDpvOK1AwSiH0YvFyAc83CWfNllUjRRUwfVc5VVyw7
                ITqyCPTV1fkXBFkTpFGtOFeY1sE+MwMQtIJTvlDQqO6z7oA8jAASZko+atINkUXQORcB+izdFvty
                xTV0E0D6QI5kXvRgzwMZiO6JTLU0J00rJccUXTq0KRFkUHo+naW6dQ27a9GZUcCU1HVqIss8S5Fu
                RcOpjIVYTPVZkSjso8cvgp4CPREyZc550dcMCIVXndbvA6dlLUs39KWK6kZUQ85efX/Qf2lFP20R
                OzqfVkRwwrmMqpZ+f1DXOTRJnVygB6U1ZZIvbrBY0NdLrNndjwwT6FboA7Iok0omjYpydcFuU1mW
                FFQdOy2KoHWqBdqsjEiM61l1FslU6EwNyfw+kEFJdR3mCGgXO3pocipyjSGBYp8rCwF1sfEwDpYG
                l50jRGJ7KIn68gWZQZaFKlKZOKRSUu9lhtTf/IysG81WAMG5fJGBc1U5ZUHVnO8gHPlaPLuEEPbC
                6utgqgKNqy+HomB3TA3I/t6JbU36pnuoOyOp5DtvGLoPWodUe0vVHgpf66FhBEQZAVHGpZ+SaZAI
                MOwTJ8gXcMIht9/+sh8ncgTVbR8v8bIDUn9SzvYlW1kgXKP9yulp4l2AO16U4yHoGylFCEBMSamt
                dRaIHfUcyiYXOQURrnFwhBZ0lzCr9QXSQ2em9Ep3GFAd6C5hliIU9OhE0AoIukigRypAFuOSGdUl
                gzjCsE98IEGE8gQ8giNo/5nzII6Bp8E86juDgcqf/ggF/Sjbvwhmxx2VHqj+8qIta4+SB7RR3iiZ
                QNwP55RTTRNaqAr9RUcIgdNQIOxVf21VfSHCbwV8YQnRbAuiV9mKopBKs3RAGpV+0j3pYRQRCE1I
                pjZilyhIjkA0MeLMlLogcu6orjWjFAziC8M+8YF8gVu2UYAER+FwEfL41R8QUn9S2a8oCZFtBgoQ
                lU4jqJScTlhYWEiq9zU6JNEf5YjnA2etcwENWA/BX/mHqNTfulP18OHIIZ8JArHspAz9+Q6QUmej
                5MBKWoEiukcKNEUCbV9KZURagUwgclWgH5FsRJ0axBeGfeIHHCGk4hf1VWZFMWFXwBdw219EJLhQ
                f90pRL5yF9zN9hq7hvq1GyWECIkiH3BQJL4tfuj0MaVmu7cWBOgA8X+nfhTCFk5u/0lpmg0G7GdA
                tudDAbQl7EmcFgq57b99Jh1wwmmcUmk0qkXpmM7UPKU7jKBloAcrzaHpFEhREB0nSWFB1CQ1iDsM
                +8QP6lel1L8e9Xt6srGxf0SkxFW8df+x5KUnlq8j/MF/bFdT+iH2Xuj7vByqpr0P004LhErEUQl2
                pEhOxeXwyQvyvbD61WP5MT3FM/SkyO8/RXjFCUelJR2XSVu0gqBf27GpQA1C7wEBCqJMaKM/9aP/
                hDZ0W2vSW+mwpBQ5PyIUs1IEdKAHZCoiJzacpQaJAMM+8QFuoX7MU/2aBJGNzSK4RrHfHXB5Nu6Z
                esuzS657aPmfn93dtEdkJ4aXQVY+9fB17vx54ydOOHbs2OLFiysqKnCqgoIC3DU7Oxsz48aNKy0t
                xfPxScknMy0tbf78+RkZGcjl5eVlZWWkRUVFUio+fC4oTgmpv+0Il5wsd3efPPXdzl0PpGdUKN60
                Srzq76nSf4z069t77NixU6dO3W3/qRL91XCE4uJiYQ1pnW7n5qo/mUVRkyZNJk+eTA6dhFCouGzZ
                stOnTzMKGIScNWvWwFBUpLdYnjlzJhVlLIwdazTNfhNlZHJoCyEvL48ZYB6YFk6R0SFl1JxS1yDu
                MOwTH/jV/kl5Lc4WCKk/5l2x7+SyTkMPfNF9xb2vrvvN4yk/e3DJr+rMvKnuvoadtn/RZceKdZZH
                /dgRTNCnX9+u3bsdO3G8e/fuvXv3HjJkCOmUKVP69+/PZWrYsCGnQ4cOnTBhAlwwcuRI8pOTk8eP
                H79gwQJy2rdv37lzZ2rBU/J156gYwQloiWNlytakqckDJ89qMXTMi206Pdum04edO/eeNmXg1Mnf
                zJ1T6vcF7eEM6NULHhk9evSIESNGjRo1ePBgIaC1a9d26dKFpmEQGqUDLVu2HDBgAHyEQtOmTene
                oUOHkpKS+vTp07Nnz06dOkkVeg779O3bd9GiRciTJk1iFNjHyNy5cwcOHMhAyO/RowdDnjVrFsRE
                B9q2bduuXTvsb9iwYdiwYR06dEBz2rRp9AcLvXr1ooqMziC+MOwTH0R2MvYTE/VrjSGrfN/JJV2G
                7m/cY/WDb675zWMpP39o6f88NOvmJw416b7nq547V65XNBCyjh05OiJpZP+BA5avXIETQi7wyJgx
                Y3B4HA9+6dixI37O6fbt21NSUoYPH47XrV69GjLC1XHdVq1aoY8bHz58eOvWrecPfIhr3IHw2s07
                R0+fM2hKcttRE//VuuMzrTt+0KlLn2kzh85IHjd7Xpnfjt1C1pB+A/bt20dzdICABR4hQiFgQaAz
                8OORI0egRfpJ68RiErNADdQ6cODAxo0b6S2nVF+6dOmgQYNQwzCcAgiplixZwpDJhNo2b94Mj6BD
                Potz+fLlDDk1NRUFThnsqlWr6MOOHTuwBlVBQzNmzCBaBFS3B2cQZxj2iQ9wV/Upkv2HDTyy8/Kr
                nZfLX8HOa+JNTy28ts7SPz+zrUlXdl72HztRTMBmjf2Fy+P2+n1sRnbt2oV7412EEuwmVqxYQenR
                o0f37t3LvgOTmzZtYv/C1oMYhx0HOaRQALXy8/PZpFCqenNe0De3R330xs7rWEl535nJr7dtv/f0
                GU7ZSrFzg6Hsz8VCuTlZhDBsfDBLE/ILbYBMekXmtm3bZMdH6/QfYiLsgjLkh5zIYatIbw8ePEhY
                BGHJn7qlOqXr169nG4VxOHThwoWQLNS5c+fOrKwsKIyNG5ZpkRngNCcnB1qnRWyS0iJ1UZYdH6l0
                zCC+MOwTHyi6sX8v3WYf9Tu+Kkf+hm2FTz11nrv8xMoU9aF7MKgelgbDfrf6y2KocUSe70rMEQrh
                zLJ70k9kAQoUIeinvwLyJd6RUqdwNgTCIfvPBzmeOp/Iz5d+yO94BgLqz1/5Am77tWdlWfWtMoUO
                pG/Sig615JGT6MARdl4E+vEwdUmpTl3n0MiHa6QUiAKgIik5tCKWgQjkS4tA98EgvjDsEx/onRee
                4LM/WfdaYbdy4cgn7gECHSvktgkm5FZPVfAx5f8h9VPnqiqJcmplQVxOgxzxzKh824DyRslHkJwo
                te8CR7U/bg+Hwn5IQWkq7w2Fwjgz+SpHHWwiOSjVZnVDpLoh4QJ9ChCAZhxRkFPNL5IJfUQRB2rQ
                EIIYBLoKmmRiXxcBkcWaQdxh2Cc+iHzORWK7BokiGHsvo3YxuJg3oAIft/qLl2R6fOpvB4kaBIRr
                KRey60ZBxxHiqNq99Z2/qjeeB5FGgfxlm8pDVxT7+q/vanaABXTTGppigNaUTF2kLTtNiSAGJd9p
                SrOJxEdySoop5yRgR5sySAQY9okP/PJ1TTxN/Nn+pgUpnhL5swSOQ33ZAq+0iSnimjZgBHFCCQq0
                t4v7iRsjyynA9+RUaom+Lj0ror5pEfAF/fChvUP0q79+av9JMAGC3T6taOKgCd0rDXKgSOme5hpA
                LWEHuiT52o4enaSUAinV/de0iw6Zul0xJZadRgziDsM+cYLtdBCQ1+/7TljhYyemXi+EHlwB9QfF
                pNRWjziSdryqkOep4pA6OsDZnJFCFM5lSkM82ReIfKVLoDpW2SuBUIazb+SITHWgTwWiDJCjQhJp
                MSrTOQSpBcSyyEB0dCtYwBSQU9GkVFc3iC8M+8QJ+HJI/dUlHIUUb+BQXsJ55Z+sRFB/a9dJPaKn
                92uVXoSAjzmdSruc0+Gdviq+LVWcFaNBjFb5Z6HojnCQeruHDJsW/T5P0P47U8EwXKnaks7oDkSB
                Ut0laVc0JdNZqruKgsgIUiqnWgHofJ2phaoGDRIEhn0MDAziA8M+BgYG8YFhHwMDg/jAsI+BgUF8
                YNjHwMAgPjDsY2BgEB8Y9jEwMIgPDPsYGBjEB7FiH/2KlyAzMzM7OzsnJ4fUwMAgwZGenl5cXIzb
                ypd45UXN872VelGIYewj/VYvvQaD/fr1a968eYMGDRobGBgkPJo2bdqwYcMvvvhi4cKF+vvJ1Y4Y
                sg/QERBC1Nd2DAwMEhYS5mj/9Xg8sfDfWLGPfN+PqCcqWuPUwMAgwSH+qwUBpxGpmhDD2Me5VzSB
                j4FBLULUN3IJI2KxfYkV+0jMJqnmUYZkYGCQ+BCH1TuvGCGGsQ98GZHMjxsYGNQqEDFon1UfG9k/
                clL7dl7Qpx6G4SADg9oCHfhUO+loxJB9nL0XGfbRmSJIWjVKIkePWVeRbafzLzToiloHOGUx4rRv
                kFCQi8WdVq8EuViSshhEkFJZG7IM9J1M/60LvWCqVhGIjJpooiA5+sEqp/rRhjbr/FsaBtWLGLIP
                YB04n5kLZFmIoK+3/CivrBvn75/LQtFrwqkmmdqaKFOkcwD2xYJBwkIuEBdOLisQUpCUTBEEspwk
                Ry63na3UnBdaZJ3DktCasmz0KcBa1JrR603gtGxQjYgV+3DBnNdMlgthC5dZFpC+3nptOS+5lJLq
                Ug3nukEWqop6IYpaUcsrIhkkHvRK0AuGawc4lVSKtBpgqVCE4PwjFugAufSkyErVhhQhUFEWg5yi
                qVdd1CJxnla9gxpUC2Ib+2j6iLp+XHu9mCAOFoGcoqw19fogJVMEnQOEcUQNUBcjnKLgrGuQyJDl
                ISTCVSO2dV61qBhEimSpOK87qV4/dl4E6JMDnFUkxy5XEJvamnOfJWpRNzaDakSs2IfrHXVX2blz
                50MPPfT8889PnTpVcuAU0ZFrry8zpe+8805xcTH5QC9HvTR1joC2yMnNzf3000+feuopcqgVtUYN
                EhNt27Z97bXXXnrppTNnzkSyKiMUvX7kupeVlb355ptOltGcpZQsa/Xq1QsXLuzVq9eDDz74j3/8
                Y9q0aZJPrQYNGsife6W6XmZJSUmTJ08WWSDG5e+CSBPALKHYIbaxD1dOX8XU1NT69esnJyd/9tln
                a9euff/992fNmsUS+eCDD1JSUqCkOXPmrFu37ssvv2zatOkzzzzzySefDB06dPny5e+9997MmTOH
                Dx+O5t69ezmlaO7cuVOmTKE6VVjB2MzKysI+BEc+p4sWLerfv3/Dhg379esnq8ogAcGlnDRp0ttv
                v71r166vv/6aC7dnzx7WACQyYsSIo0ePImzbto27UY8ePf74xz++9dZbu3fvRhNCyczMbNasWatW
                rSReZkVBHFzrgQMHQivoN2rU6MiRI82bN2flsH5oq0WLFocPH3733Xf79OkzevToZ5999uOPP2YJ
                0YGWLVtifPbs2QcOHCBz7NixrPkPP/xwx44dZv3ECDFkH33N5FYGcTz66KNt2rRhbT355JMI8Air
                4fPPP1+yZAlrCLIgh3xudE8//XRpaekDDzxw5513siwgERYQ65JVNWzYsHbt2rG2evbs2bFjR9br
                vffe+8Ybb6xYseKVV16555577rvvPjJZc9xUT5w4wW1QumGQgPjoo4+4mi+88ALe/pe//AWCYKVx
                yTglZoF9HnvsMciCyOjUqVP16tUjuoERbr/9drhm+vTpdevWlWWWl5fXvn17Vg4rbdCgQZDLb37z
                m8aNG48aNeq55557/fXXmzRpsmDBAiy8/PLLxMhEW127dh0/fvyrr77au3dvGJCIiSZYgdz5WDwo
                sBS5yUk/DWKBGLIP0IEP2LRpE2zCzYoF17p1a9YcNolT/vWvf02cOPGJJ56AcYhTWE/du3d/8cUX
                XS7XXXfdBY906tSJdQa/FBYWEiWxmFiR48aNg8tYKNzoyIGJ9u3bhw5NsLa4YU6YMIEbZkFBwf33
                32/uXQkLqIRLzNUnVn388cchAjZQpI888ghsQhT8t7/9rUuXLl999dXgwYNZKvPnz+eWwyLhihMu
                QSWyQWPlEKSITdYPVx9rZG7cuBGBJcENjCaIbvr27UtMRBNEQyw/OtCtWzcEbl3cq6A8FLhBUkq7
                kJ3ZecUOsWIfuWak+uKxbz99+nRaWhqUhMxtrby8vKio6ODBg+icPHmSrRP5OTk5FMEa7M8zMjIg
                DkJl4qDc3FyMsNQ4ZV2yqgiqib3dbjcp1Qm/0aEKtHXs2LGSkpLs7GxuhpSSSh8MEg3ELFw7rhoy
                V4oLh3Do0CEuJeuETRAKXFl4gXsPK4SLywohPX78OCsEGX00uS0JDXGti4uLWT/osLQ4ZclJXVZX
                fn4+C4a6xTnFrpKKkqLi3MyMioK8iqKigoI8T8Cfl5Pv9wYOHTjsKnfn5xaUlZRbYUv91UdWcUId
                3NY5wqFg0B+0Qmp9k1l9z8eZUnFejUhBtSLmsQ+InNh7MR2GIAgpyKYdNREAq0qKJGW5kLK25BTA
                WaIs1pgaEbRNLOiGRFNm0CBekGtRFVwvSoU4gDxI1teO66g5BVkyxZro6CUhpfrU+Um8s6LksCT4
                xxUOUeBTrhtSBKP+gKwVsKmm3OMmRZZTSRPqqFA9twKKdkKBgM/j8zIpMWGIWCK27CPXW0hBL0G9
                1PRaEYZy6mhB6wDUyBdlraBPdZFAKkoVyTFIZDgZRwS5mvqa6ruITqWIC+3UcSo4BaCbUKBGWLkx
                Rr2KZex4QmXCQH6VBuEcWEkJYb8vIifM4Q2qP7VtD1wdQda+FfZTVE1gVgV6boFzMqsFsWKfqv2W
                rusVE3WKQI4abmURqZRKkSw+ZAFrVNTQJ9WClGqZFDXRNEhMyN1ILpzzVJXZ0EWASyly1DXVtzSn
                MpkiC+8407A/oNyWGIiNC3srMWb/fXyv36fThD3UJssH4QgpMxJ9xArMpJ7k6kJsYx8BnWYrnp2d
                zd6blH0TAmATrn5CNjubXTp7flI2+eSz5xcZQXQQJGWfjylSMSL5gI09+uQLKCInPT2d6uhIRYM4
                Qq5CVXChuXxcJi4op1xELpzoiyArAZ3S0lJOUeOUlBxSclCjOmoiA1k5KLBUnJaRKUUN4XRpfkZe
                Tn52jqegrCK/JCMtMzM9Iz83r6CwOC+/kCMrO5c0hwVYUMRBSUIdOVnZp86czi1k3AV5GVlMRFlp
                cU5eNsOsFjBLcnXKy8u/EzNWK2LIPpCl8wYlp6QMhlQEIMEOKTmkcgphARFEGQsul0tOKyoqSCX8
                QYD+xRQ5VEcgE0HykallGzaIG7gK5wJXVi4rAtdLlorIesFIplxNgRRpiD4NkQ8QpJakAB3JjygT
                Pbg9ausVClf4vR4rxOGzAuGgCigCPj/5yEoIW6GAio8S6ii2vGVWoFxtHO3BBFTsz3hk+JcOe1IV
                kFUTNjiNSNWE2MY+MhIQObehF4QAjpBROTXtgatMSfVK0grMtQhREH0N0de1DOIFdWnPBinlqmkZ
                iBx1KZ3rwaks0DnOIiGjyElULRYUO5egWlQ+RS+e9J2705evPbNuU3rKlrT1m0kzN25DIJWchDqy
                1m8qO37SCvrYISo+CikyPbtLVBOYve9MYHUgVuxTtaPkyB1JiIPbneQT5rndbkplCQqkiBzCZkIe
                qUhOSUkJsl6XBNIEhwhUEQWUKZUmzrVSDRIK8pkm0EviXA/1BM4iScmRTH3FnfpA1oZWRi3Av4qC
                7Ce1wdDBqfM6/c9dI3701z4//WuPq27qd93t3X/8F+SeV9/c+ye3cvS65paEOvr94JYetz5mZRcx
                JKZPDY+hqw++qh/27MbEcgxjH+f6AIcPH16wYMHGjRvXr1+/efPm/fv3b9iwAfp45513ON2zZ8+O
                HTsOHTrEnnz79u2nTp3auXNnWlpaUlLStm3b0tPTJR0zZgz7+bKyMsxSa8CAASkpKeRv2bKF/E2b
                Ns2dOxeB0zZt2pSWlsZu4gyqBUePHm3YsCECjCDfuuLqt2zZ0i6MRnVeSrb1pCGrIuR3WaHtPYd/
                c9Xf+v3whr7X3FIrjuE/umnMVbef3Lfbfl3AUrTNeBQJ1SbEkH30WoEp2F5BJYsXL169ejUEAYl0
                6dIFJlqzZk3Hjh179+5NULNw4cKPP/548ODBrMj27duzBL/44osRI0YkJyfTOswyatSotm3bskYx
                KCzWv3//4cOHDxw4EMZBn1NIp1u3blDYsGHD9J5O31QNEhDcQrigYNasWXLJuI762oFY3D8irmrf
                Gdl5be45rO81t3b8xc19fnprrTg6/vSGHlffnHXqiGJQt1+GY7/+U5sQQ/YBep8FiFB69eoFU6Sm
                ps6bN2/QoEFwxJIlS6CbkSNHEmx37969efPmBDszZ85s167d6NGjd+3aNW7cONgHHD9+fOLEiXAK
                HMRui9CJ02bNmnEKH9EQixhqmzJlClUgJtgN+7KspQMGCYjMzMwPPviAtcH9Y9WqVeQQ9pIj3xcV
                HRW+VjcBYc4N90Q+bg/u7j+u51W3dvnZLf2vurlWHL1/fnv7//xTwdGj6iVJX5DheKyQV70UWZsQ
                W/YR4P9yH2PHxFYLwePxkENIwp6fUnkzNTc3l1MWIhSzdetW+XAUHWrBTSiwBKmFjqSES5QiSyk5
                eXl58vMILFz5/EtqkRokJrhMLpeLBQDpkMpSkSuOEFGKxUW0PRbqYYlUlJfu7Du27w//OuRHt/X/
                ya214hj649v7XXNHQepBK6AGwijstW4PrfYghuyjSUfgjKWFFwQiU6pTWYWqzAHWH1yj74dAOEty
                hIZkjWJEMoWVDBIczksPuHb6UkahOjmIBRKyV5Ty2VDRpt2Tn/942F0vDL3rmVpxDLrjn0ve/9py
                udR7z1aYpa6m5ltvqx2IFftoz+fOJlwgS0cISJYX+Vz+KKLRq1ALKIgFDTGuTWEEQVINqU4pMZHk
                GCQm9MXlYgGRBSwScjTpRJVeCtSjWuwH7VfMlBTy5RVa6u2eQO04mDQXbhDyebyq/0EV+7h8tex2
                G8PYB+h1wyUWOpBrLaleTCLoU4IakSW6EUiONghkaSKINcCpLGXNfaKvFQwSDXLPcF5BLplcNYAg
                OfpUhEsHaxFb6t3CkPreFMvFxbKhIEQkVAsOeotvlME59uYR4oE4I7fr2oNYsY8sFGEHfeqkHhC1
                mHS0IvmaQYRQqCX5ThbTFqQhZwrQ1LJBwkKvChHOg6gFcykQQ8EwrqzMIqhzj3qGUisOy6++k6Yi
                uKD9vnNAcZDKr1WIbexjYGBgcC4Y9jEwMIgPDPsYGBjEB4Z9DAwM4gPDPgYGBvGBYR8DA4P4wLCP
                gYFBfGDYx8DAID4w7GNgYBAfGPYxMDCIDwz7GBgYxAeGfQwMDOIDwz4GBgbxgWEfAwOD+MCwj4GB
                QXxg2MfAwCA+MOxjYGAQHxj2MTAwiA8M+xgYGMQHhn0MDAziA8M+BgYG8YFhHwMDg/jAsI+BgUF8
                YNjHwMAgPjDsY2BgEB8Y9jEwMIgPDPsYGBjEB4Z9DAwM4gPDPgYGBvGBYR8DA4P4wLCPgYFBfGDY
                x8DAID4w7GNgYBAfGPYxMDCIDwz7GBgYxAeGfQwMDOIDwz4GBgbxgWEfAwOD+MCwj4GBQXxg2MfA
                wCA+MOxjYGAQHxj2MTAwiA8M+xgYGMQHhn0MDAziA8M+BgYG8YFhHwMDg/jAsI+BgUF8YNjHwMAg
                PjDsY2BgEB8Y9jEwMIgPDPsYGBjEB4Z9DAwM4gPDPgYGBvGBYR8DA4P4wLCPgYFBfGDYx8DAID4w
                7GNgYBAfGPYxMDCIDwz7GBgYxAeGfQwMDOIDwz4GBgbxgWEfAwOD+MCwj4GBQXxg2MfAwCA+MOxj
                YGAQHxj2MTAwiA8M+xgYGMQHhn0MDAziA8M+BgYG8YFhHwMDg/jAsI+BgUF8YNjHwMAgPjDsY2Bg
                EB8Y9jEwMIgPDPsYGBjEB4Z9DAwM4gPDPgYGBvGBYR8DA4P4wLCPgYFBfGDYx8DAID4w7GNgcOUi
                HA77/f7U1NRQKMRpMBgMBALZ2dmknFK0e/dukQ8dOoQa+lJLp1Lx4mDYx8DgygUMsnnz5i+++MLj
                8USyLGvw4MEiLFu2rEWLFgiHDx9OSkqaPn36zp07ORU+gqqUUiUNXQQM+xgYXNFwuVxff/21DmFK
                S0vHjh3r8/mQ4ZevvvoKYdGiRatWrTpw4MCIESPI1IGSMwi6CBj2MTC4ciHE0ahRI5EhlKlTpxYU
                FHAKxZAj7LN27do5c+bs379/xowZTq4RGrrozZdhHwODKxrsqlq2bAnjCImMHDmSaEiK2Ge1bds2
                JyenrKxs/PjxQ4cOLSwsJB+SAgjCRIZ9DAwMLhjQh3CH8Ijf78/MzEQQcqFIRzqSA6L0L5p6gGEf
                AwMDxSMiwCbyUBnAOPIASLjG4/GQo0sBpyBycuEw7GNgcOVCaEUHOE5mkU/BpIh8p2YUB110+GPY
                x8DAID4w7GNgYBAfGPYxMDCIDwz7GBgYxAeGfQwMDOIDwz4GBgbxgWEfAwOD+MCwj4GBQXxg2MfA
                wCA+MOxjYGAQHxj2MTAwiA8M+xgYGMQHhn0MDAziA8M+BgYG8YFhHwMDg/jAsI+BgUF8YNjHwMAg
                PjDsY2BgEB8Y9jEwMIgPDPsYGBjEB4Z9DAwM4gPDPgYGBvGBYR8DA4P44ALYx8DAwKB6EeGXSpyd
                fQwMDAxiDcM+BgYG8YFhHwMDg/jAsI+BgUF8YNjHwMAgHrCs/x8OiLwAK70kiAAAAABJRU5ErkJg
                gg==`;
    }

}

