/*
 * (c) by Scopevisio AG 2021
 */

import store, { StateType, buildCenterdeviceCache } from "@/store/store";
import { LoginResponse } from "./scopevisiotypes";
import { Util } from "./util";
import '@/util/startup'
import { ScopeUtil } from "./scopeutil";
import { AppContext } from "./context";

export class StoreUtil {

    static saveState(state: StateType) {
        const copiedState = Util.deepCopy(state);
        const val = JSON.stringify(copiedState, null, 4);
        window.localStorage.setItem("state", val)
    }
    
    static async loadStore() {
        return new Promise((resolve, reject) => {
            {
                /* Use localStorage as default
                * we use setTimeout to have similar semantics to the delayed loading on ios
                */
                setTimeout(() => {
                    /* local data */
                    const val = window.localStorage.getItem("state") as string;
                    StoreUtil.parseAndLoadStore(val);
                    resolve(true)
                }, 1)
            }
        })
    }
    
    private static async parseAndLoadStore(val: any) {
        if (val) { 
            const persistedState: StateType = JSON.parse(val) as StateType   
            const loginResponse: LoginResponse = persistedState.loginResponse;
            if (loginResponse) {
                store.commit("connected", loginResponse)
                store.commit("organisation", {
                    organisationName: loginResponse.organisationName,
                    organisationId: loginResponse.organisationId,
                });
                const myAccount = await ScopeUtil.getMyAccountInfos(AppContext.instance.getDefaultHeaders());
                store.commit("accountInfo", {
                    customer: myAccount?.customer.publicId,
                    username: myAccount?.user.login
                })
                buildCenterdeviceCache(store);
            }
        }
    }

}

