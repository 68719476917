import { AppContext } from "./context";
import { showNotification } from "./eventbus";
import { HypatosResponse, NotificationType } from "./scopevisiotypes";
import { Util } from "./util";

export class HypatosUtil {

    static async getImgInfo(accessToken: any, base64: any): Promise<HypatosResponse>{
       const myUrl =
        "https://api.hypatos.ai/v2/subscription/receipts-de?probabilities=false&regions=false&ocr=false&dpi=200&ocrFormat=json";

        const args = {};

        let response: Response = new Response;
        try {
            response = await AppContext.instance.getImageInfoFromHypatos(
                myUrl,
                Util.b64toBlob(base64[0]),
                accessToken
            );
        } catch (e) {
            console.error("failed to invoke token call: " + e);
            showNotification({
                type: NotificationType.ERROR,
                message: "Internal-Error: " + e,
            });
            //return false
        }
        
        let hypatosResponse: any
        try {
            hypatosResponse = await response.json();
        } catch (error) {
            console.log(error)
        }
        //console.log(hypatosResponse)
        return hypatosResponse;
    }

    static async getHypatosToken(): Promise<string> {
        const myUrl =
            "https://customers.hypatos.ai/auth/realms/hypatos/protocol/openid-connect/token";

        const args = {};

        let response: Response | null = null;
        try {
            response = await AppContext.instance.callFormPostHypatos(myUrl, {
                client_id: "extractionapi",
                username: "frank.becker@scopevisio.com",
                password: "Vksfdo239lfo5",
                grant_type: "password",
            });
        } catch (e) {
            console.error("failed to invoke token call: " + e);
            showNotification({
                type: NotificationType.ERROR,
                message: "Internal-Error: " + e,
            });
            //return false;
        }

        const hypatosResponse: HypatosResponse = await response!.json();
        return hypatosResponse.access_token;
    }
}