
import { Component, Vue } from "vue-property-decorator";
import DocumentCamera from "@/components/DocumentCamera.vue";
import { GenericCamera } from "@/util/genericcamera";
import HtmlCamera from "@/components/HtmlCamera.vue";
import IosCamera from "@/components/IosCamera.vue";
import { StateType } from "@/store/store";
import { Store } from "vuex";
import { SvDocument } from "@/util/documenttypes";
import { Util } from "@/util/util";

enum CameraMode {
    HTML,
    IOS,
    DOCUMENT,
}

@Component({
    components: {
        HtmlCamera,
        IosCamera,
        DocumentCamera,
    },
})
export default class Scanner extends Vue {
    cameraMode = CameraMode.HTML;

    created() {
        if (Util.isIos()) {
            this.cameraMode = CameraMode.IOS
        }else{
            this.cameraMode = CameraMode.DOCUMENT;
        }
        console.log("created scanner: " + this.cameraMode);
    }    

    mounted() {
        const store = this.$store as Store<StateType>;
        if (!store.state.connected) {
            this.$router.replace("/");
            return;
        }

        ((this.getCamera() as unknown) as Vue)
            .$off("photo")
            .$on("photo", () => {
                if (this.getCamera()) {
                    const camera: GenericCamera = this.getCamera();
                    const images = camera.getImages();
                    let isFileUpload;
                    if (camera.isFileUpload) {
                        isFileUpload = camera?.isFileUpload();
                    }
                    let fileName;
                    if (camera.getFileName) {
                        fileName = camera?.getFileName();
                    }
                    console.table(images);

                    const info = camera.getDetectedImgInfo();
                    store.state.imgInfo = info;

                    store.commit("currentDocument", {
                        name: fileName,
                        isUpload: isFileUpload,
                        createdTs: new Date(),
                        documentDate: new Date(),
                        images: images.slice(),
                        refundable: false,
                    } as SvDocument);

                    this.$router.push("/documentdetails");
                }
            }
        );
    }

    getCamera(): GenericCamera {
        switch (this.cameraMode) {
            case CameraMode.HTML: {
                const camera = (this.$refs.htmlcamera as unknown) as GenericCamera;
                return camera;
            }
            case CameraMode.IOS: {
                const camera = (this.$refs.ioscamera as unknown) as GenericCamera;
                return camera;
            }
            case CameraMode.DOCUMENT: {
                const camera = (this.$refs.documentcamera as unknown) as GenericCamera;
                return camera;
            }
        }
        throw "camera state not handled";
    }

    isDocumentCamera() {
        return this.cameraMode == CameraMode.DOCUMENT;
    }

    isHtmlCamera() {
        return this.cameraMode == CameraMode.HTML;
    }

    isIosCamera() {
        return this.cameraMode == CameraMode.IOS;
    }
}
