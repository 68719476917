
import { Component, Vue } from "vue-property-decorator";
import ContentWrapper from '@/components/ContentWrapper.vue';

@Component({
    components: {
        ContentWrapper
    },
    props: {
    }
})
class Imprint extends Vue {
}

export default Imprint;
