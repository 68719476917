
import { Component, Vue } from "vue-property-decorator";
import { GenericCamera } from "@/util/genericcamera";
import { NotificationType } from "@/util/scopevisiotypes";
import { showNotification } from "@/util/eventbus";

@Component
export default class HtmlCamera extends Vue implements GenericCamera {
    images: HTMLImageElement[] = [];
    videoReady = false;
    
    mounted() {
        this.images = []
        const video = document.querySelector("#player") as HTMLVideoElement;
        window.navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((stream) => {
                video.srcObject = stream;
                video.onloadedmetadata = (e) => {
                    video.play();
                    this.videoReady = true
                };
            })
            .catch((error) => {
                showNotification({
                    type: NotificationType.ERROR,
                    message: "Camera not available: " + error,
                });
            });
    }

    getImages() {
        return this.images;
    }

    getDetectedImgInfo(){}

    onClick() {
        const video = document.querySelector("#player") as HTMLVideoElement
        const canvas = document.createElement("canvas")
        const width = video.videoWidth
        const height = video.videoHeight
        canvas.width = width
        canvas.height = height
        const context = canvas.getContext("2d")
        if (context) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height)
            const img = document.createElement("img")
            img.onload = () => { console.log("image loaded")}
            const dataUrl = canvas.toDataURL("image/png")
            img.src = dataUrl
            console.info(img.src)
            this.images.push(img)
        }
        this.$emit("photo", this)
    }
}
