/*
 * (c) Scopevisio 2019, all rights reserved
 */

import { AppContext } from '@/util/context';
import { StateType } from '@/store/store';
import { Store } from 'vuex';
import { UsersResponse, CenterdeviceUser, CollectionResponse, CenterdeviceCollection } from "./centerdevice";
import { CenterdeviceUtil } from './centerdeviceutil';

export class CenterdeviceCache {
    static instance = new CenterdeviceCache()

    store: Store<StateType> | null = null;
    users: CenterdeviceUser[] = [];
    collections: CenterdeviceCollection[] = [];
    currentUser: CenterdeviceUser | null = null;
    cacheBuild = false;



    build(forceRebuild?: boolean): Promise<boolean> {
        if (this.cacheBuild && !forceRebuild) {
            return new Promise<boolean>((resolve, reject) => {
                resolve(true);
            });
        }
        console.log("building centerdevice cache");
        this.cacheBuild = true;
        return this.buildWorker();
    }

    async buildWorker(): Promise<any> {
        const accessToken = this.store?.state.loginResponse.access_token
        {

            const response = await AppContext.instance.callGet("teamworkbridge/collections?all=true", 
            {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            {});
            if (response.ok) {
                const raw = await response.json();
                const collections = raw as CollectionResponse;
                this.collections = collections.collections;
            } else {
                console.error("collection-lookup failed: " + response.status + " " + response.statusText)
            }
        }
        {
            const response = await AppContext.instance.callGet("teamworkbridge/users?all=true",
                {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + accessToken,
                },
                {});
            if (response.ok) {
                const raw = await response.json();
                const users = raw as UsersResponse;
                this.users = users.users;
            } else {
                console.error("users-lookup failed: " + response.status + " " + response.statusText)
            }
        }
        {
            const response = await AppContext.instance.callGet("teamworkbridge/user/current",
                {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + accessToken,
                },
                {});
            if (response.ok) {
                const raw = await response.json();
                this.currentUser = raw as CenterdeviceUser;
            } else {
                console.error("current-user-lookup failed: " + response.status + " " + response.statusText)
            }
        }
        {
            const collection = this.collections.find(
                (e) => e.name == "Spesen2GoFiles"
              );

            if(!collection){
                const res = await CenterdeviceUtil.createSpesenCollection();
            }
        }
    }

    getUser(uid: string): CenterdeviceUser | undefined{
        return this.users.find(u => u.id == uid);
    }

    getUserDisplay(uid: string): string {
        const user = this.getUser(uid);
        if (!user) {
            return uid;
        }
        const firstName = user["first-name"];
        const lastName = user["last-name"];
        let s = "";
        if (firstName) {
            s += firstName;
        }
        if (lastName) {
            if (s.length > 0)
                s += " ";
            s += lastName;
        }
        return s;
    }

}

