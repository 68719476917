
import { ACTION_CONFIRM, ACTION_SAVE, ACTION_NOTIFICATION, EVENT_BUS } from "@/util/eventbus";
import BottomNavigation from "@/components/BottomNavigation.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { ConfirmInfo, SaveInfo, NotificationInfo, NotificationType } from "@/util/scopevisiotypes";
import { Store } from "vuex";
import { StateType } from "@/store/store";

const DEFAULT_TIMEOUT = 600000;

@Component({
    components: {
        BottomNavigation,
        ConfirmDialog,
    },
})
export default class MyApp extends Vue {
    headerText = "Reisekosten- und Spesen-App";
    showSaveButton = false;
    snackbarColor = 'red'
    snackbarText = `Info`;
    snackBarTimeout = DEFAULT_TIMEOUT;
    snackbarVisible = false;
    transitionName = 'slide-left';

    @Watch('$route')
    onRouteChanged() {
        this.rehashTitleBasedOnRoute();
        this.showMagnifier();
    }

    created() {
        /* confirmations and notifications can be triggered with
         * global methods
         * these global Methods are defined in the eventbus file
         *
         * developers can just invoke  confirmDialog() programmatically
         * and should not add their own vue components to do so.
         */
        EVENT_BUS.$on(ACTION_NOTIFICATION, (notification: NotificationInfo) => {
            this.snackbarVisible = true;
            this.snackbarText = notification.message;
            this.snackBarTimeout = notification.timeoutMs ?? DEFAULT_TIMEOUT
            switch (notification.type) {
                case NotificationType.ERROR:
                    this.snackbarColor = "red"
                    break
                case NotificationType.WARNING:
                    this.snackbarColor = "yellow"
                    break
                case NotificationType.INFO:
                    this.snackbarColor = "green"
                    break
            }
        });
        EVENT_BUS.$on(ACTION_CONFIRM, (confirm: ConfirmInfo) => {
            this.getConfirmDialog().hideCancel(confirm?.hideCancelButton ?? false);
            this.getConfirmDialog()
                .open("Bestätigung", confirm.message)
                .then((value: any) => {
                    const confirmed = value as boolean
                    if (confirmed) {
                        confirm.onSuccess()
                    } else {
                        if (confirm.onCancel) {
                            confirm.onCancel();
                        }
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        });
        EVENT_BUS.$on(ACTION_SAVE, (save: SaveInfo) => {
            if(save.saveButton){
                this.showSaveButton = true;
            }
            else {
                this.showSaveButton = false;
            }
        });
    }

    getConfirmDialog() {
        return this.$refs.confirmdialog as ConfirmDialog;
    }

    goBack() {
        this.$router.go(-1);
    }
    
    goBackDetail() {
        this.$router.push("/documents");
    }

    goBackSub(){
        this.$router.go(-1);
        const storeAll = this.$store as Store<StateType>;
        storeAll.commit("routedFromSubSite", false);
    }

    getHeaderText() {
        return this.headerText
    }
    
    // "'onEdit' is declared but its value is never read." <-- wrong. so don't delete this method, pls. :)
    //private onEdit() {    
    //    this.$router.push("/documentedit");
    //}

    /* adjust the title based on the route */
    rehashTitleBasedOnRoute() {
        this.headerText = this?.$router?.currentRoute?.meta?.title;
    }

    showSave() {
        if (this?.$router?.currentRoute?.meta?.title != "Dokumentendetails") {
            return false;
        }
        return this.showSaveButton;
    }

    private showMagnifier() {
        const positivePaths = new Set();
        positivePaths.add("/documents");
        const currentRoute = this.$router.currentRoute.path
        return positivePaths.has(currentRoute);
    }

    // "'showOrangeBar' is declared but its value is never read." <-- wrong. so don't delete this method, pls. :)
    private showOrangeBar() {
        
        const positivePaths = new Set([
            "/documents",
            "/about",
            //"/scanner"
        ]);
        const currentRoute = this.$router.currentRoute.path
        return positivePaths.has(currentRoute)
    }

    // "'showDetailsBar' is declared but its value is never read." <-- wrong. so don't delete this method, pls. :)
    private showDetailsBar() {
        
        const positivePaths = new Set([
            "/documentdetails",
        ]);
        const currentRoute = this.$router.currentRoute.path
        return positivePaths.has(currentRoute)
    }
    
    // "'showWhiteBar' is declared but its value is never read." <-- wrong. so don't delete this method, pls. :)
    private showWhiteBar() {
        
        const positivePaths = new Set([
            "/changePassword",
            "/passwordlogin",
            "/organisationchange",
            "/imprint",
        ]);
        const currentRoute = this.$router.currentRoute.path
        return positivePaths.has(currentRoute)
    }

    // "'showWhiteBar' is declared but its value is never read." <-- wrong. so don't delete this method, pls. :)    
    private showWhiteBarSub(){        
        const positivePaths = new Set([
            "/documentprojectid",
            "/documentreceipttype",
            "/documentrefundableoption",
            "/documenttravelallowance",
        ]);
        const currentRoute = this.$router.currentRoute.path
        return positivePaths.has(currentRoute)
    }
    
}
