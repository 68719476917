
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
    color = "#ffbe19";
    dialog = false;
    message = "";
    title = "";
    showCancelButton = true

    hideCancel(value: boolean){
        this.showCancelButton = !value;
    }

    agree() {
        this.resolve(true)
        this.dialog = false
    }

    cancel() {
        this.resolve(false)
        this.dialog = false
    }

    open(title: string, message: string) {
        this.dialog = true
        this.title = title
        this.message = message
        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    resolve: (args: any) => void = (args) => {}

    reject: (args: any) => void = (args) => {}
}
