import { AppContext, StringMap } from "./context";
import { MyAccountResponse, TravelEntryResponse } from "./scopevisiotypes"

export class ScopeUtil {
    
    static async getMyAccountInfos(headers: StringMap): Promise<MyAccountResponse> {
        const response: Response = await AppContext.instance.callGet("myaccount", headers, null)
        if (response.ok) {
            const raw = await response.json()
            return raw as MyAccountResponse
        } else {
            throw new Error("call returned with " + response.status)
        }
    }

    static async getTravelEntries(headers: StringMap): Promise<TravelEntryResponse> {
        const response: Response = await AppContext.instance.callRawPost("travelentries", headers, null)
        if (response.ok) {
            const raw = await response.json();
            const travelentries = raw as TravelEntryResponse;
            return travelentries;
        } else {
            throw new Error("error occurred while getting travel entries. " + response.status)
        }
    }

    static async setNewPostionTravelentry(headers: StringMap, body: any, postionName: string): Promise<Response> {
        const response: Response = await AppContext.instance.callJsonPost("travelentry/position/" + postionName + "/new", headers, body)
        if (response.ok) {
            return response;
        } else {
            throw new Error("error occurred while setting travel entrie position. " + response.status)
        }
    }

}