
import Camera from "../../public/images/camera-white.svg";
import { Component, Vue } from "vue-property-decorator";
import Document from "../../public/images/document.svg";
import DocumentPrimaryColor from "../../public/images/document-primarycolor.svg";
import Settings from "../../public/images/settings.svg";
import SettingsPrimaryColor from "../../public/images/settings-primarycolor.svg";
import { StateType } from "@/store/store";
import { Store } from "vuex";

@Component
export default class BottomNavigation extends Vue {
    camera = Camera;
    document = Document;
    documentprimarycolor = DocumentPrimaryColor;
    selectedPage="";
    settings = Settings;
    settingsprimarycolor = SettingsPrimaryColor;
    onAbout() {
        this.selectedPage="about";
        this.$router.push("/about")
    }

    isConnected() {
        const store = this.$store as Store<StateType>
        return store.state.connected
    }

    onDocuments() {
        this.selectedPage="document";
        this.$router.push("/documents")
    }

    onScanner() {
        this.selectedPage="camera";
        this.$router.push("/scanner")
    }
}
