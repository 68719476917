
import { AppContext } from "@/util/context";
import { Component, Vue } from "vue-property-decorator";
import { LoginResponse, NotificationType } from "@/util/scopevisiotypes";
import { showNotification } from "@/util/eventbus";
import store from "@/store/store";

interface DeviceAuthorizationCode {
    authorizationCode: string | null;
    clientId: string;
    clientName: string;
    code: string;
    createdTs: number;
    customer: null;
    ip: string;
    login: null;
    organisationId: number | null;
    userAgent: string;
}

@Component
export default class CodeLogin extends Vue {
    code: DeviceAuthorizationCode | null = null;
    timeoutId: number | null = null;

    beforeUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    async mounted() {
        if (!AppContext.instance) {
            this.$router.push("/")
        } else {
            this.onNewCode()
        }
    }

    async login() {
        let response: Response | null = null
        try {
            response = await AppContext.instance.callFormPost("token", {
                client_id: AppContext.instance.restClientId,
                grant_type: "authorization_code",
                code: this.code?.authorizationCode,
                requestcookie: true,
            })
        } catch (e) {
            console.error("failed to invoke token call");
        }
        if (!response || !response.ok) {
            this.code = null
            showNotification({
                type: NotificationType.ERROR,
                message: "Anmeldung fehlgeschlagen: " + response?.statusText,
            })
            return false
        }

        // perform state changes after successful login
       const loginResponse: LoginResponse = await response.json()
       try {
            await store.dispatch("switchOrganisation", loginResponse);
       } catch (errr) {
            showNotification({
                type: NotificationType.ERROR,
                message: "Anmeldung fehlgeschlagen.",
            })
            return false
       }

        this.$router.push("/scanner")
        return true
    }

    onCopyText() {
        if (this.code?.code) {
            const textToCopy = this.$el.querySelector('input')
            if (textToCopy) {
                textToCopy.select()
                document.execCommand("copy");
                showNotification({
                    type: NotificationType.INFO,
                    message: "Der Code wurde in die Zwischenablage kopiert",
                    timeoutMs: 1500
                })
            }
        }
    }
    
    onLogin() {
        //URL
        window.open('https://appload.scopevisio.com/apps/portal/code.html', '_blank')
        //window.open('http://localhost:4000/apps/portal/code.html', '_blank');
    }

    async onNewCode() {
        try {
            const resp = await AppContext.instance.callFormPost(
                "authorize/device",
                {
                    client_id: AppContext.instance.restClientId,
                }
            )
            this.code = await resp.json()
            if (this.timeoutId) {
                clearTimeout(this.timeoutId)
            }
            this.timeoutId = setTimeout(async () => {
                await this.pollAuthorizationCode()
            }, 10000)
        } catch (err) {
            console.error("Failed to get code", err)
        }
    }

    onPasswordLogin() {
        /*
        showNotification({
            type: NotificationType.WARNING,
            message: "Not implemented yet"
        })
        */
        /*
         * todo: replace route with a password login view
         */
        this.$router.push("/passwordlogin");
    }

    async pollAuthorizationCode() {
        try {
            const resp = await AppContext.instance.callFormPost(
                "authorize/device/exchange",
                {
                    code: this.code?.code,
                }
            )

            if (resp.status === 404) {
                this.code = null
                showNotification({
                    type: NotificationType.ERROR,
                    message:
                        "Der Code ist ungültig geworden. Bitte fordern Sie einen Neuen an.",
                })
                return
            }

            if (!resp.ok) {
                throw new Error("call returned with " + resp.status)
            }
            this.code = await resp.json()

            if (this.code?.authorizationCode) {
                this.login()
            } else {
                this.timeoutId = setTimeout(async () => {
                    await this.pollAuthorizationCode()
                }, 5000)
            }
        } catch (err) {
            console.error("Failed to poll for authorization code", err)
            this.code = null
            showNotification({
                type: NotificationType.ERROR,
                message: "Es ist ein Fehler aufgetreten. Bitte fordern Sie einen neuen Code an.",
            })
        }
    }
}
