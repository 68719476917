
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
    },
    props: {
        "defaultlabel": {
            type: String,
            required: true,
        }
    }
})
class SettingsAction extends Vue {

    label = "";  // reactive

    mounted() {
        this.label = this.$props.defaultlabel
    }

    onClick() {
        this.$emit("action");
    }

}

export default SettingsAction;
