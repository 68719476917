import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import 'vuetify/dist/vuetify.css';
import '../vuetify.scss';
import '../styles.scss';


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#ffbe19'
            },
          },
        
    }
});
