
import ChangePassword from "@/components/ChangePassword.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        ChangePassword,
    },
})
export default class Password extends Vue {

}
