
import { Component, Vue } from "vue-property-decorator";

/* This class wraps all major views so that
 * scrolling and sizing works correctly
 */

@Component({
})
class ContentWrapper extends Vue {
}

export default ContentWrapper;
