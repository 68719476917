
import { Component, Vue } from "vue-property-decorator";
import { GenericCamera } from "@/util/genericcamera";
import { NotificationType } from "@/util/scopevisiotypes";
import { showConfirm, showNotification } from "@/util/eventbus";
import { StateType } from "@/store/store";
import { Store } from "vuex";
import { Util } from "@/util/util";
import LoadingTransition from "./LoadingTransition.vue";
import { HypatosUtil } from "@/util/hypatosutil";

@Component
export default class DocumentCamera extends Vue implements GenericCamera {
    base64 = "";
    fileName = "";
    fileUpload = false;
    images: HTMLImageElement[] = [];
    uploadFile: any = null;
    accessToken = "";
    imgInfo: any = "";

    mounted() {
        this.images = [];
    }

    getFileName() {
        return this.fileName;
    }

    handleDevPhoto() {
        const img: HTMLImageElement = document.createElement('img')
        img.src = "data:image/jpeg;base64," + Util.getTestImg();
        this.images = [img];
        this.base64 = Util.getTestImg();
        this.$emit("photo", this);
        this.$forceUpdate();
    }

    getImages() {
        return this.images;
    }

    isFileUpload() {
        return this.fileUpload;
    }

    getDetectedImgInfo(){}

    onClick() {
        const loadingTransition = this.$refs.loadingtransition as LoadingTransition;
        
        const scan = (window as any).scan
        const camera = (window.navigator as any).camera
        console.log("scan: " + scan);
        console.log("camera: " + camera)
        const store = this.$store as Store<StateType>

        if (scan) {
            const successCallback = async (imageData: any) => {
                const img: HTMLImageElement = document.createElement('img')
                img.src = "data:image/jpeg;base64," + imageData;
                this.images = [img];
                this.base64 = imageData
                //cibst image = this.$refs['scanimage'] as HTMLImageElement;
                //image.src = "data:image/jpeg;base64," + imageData; // Base64 rendering
                
                HypatosUtil.getHypatosToken().then((token) => {
                    if (token) {
                        console.log(token)
                        this.accessToken = token;
                        HypatosUtil.getImgInfo(this.accessToken, this.base64).then(
                            (result) => {
                                if (result) {
                                    console.log(result)
                                    loadingTransition.disable();
                                    this.imgInfo = result;
                                    this.$emit("photo", this);
                                    //this.$forceUpdate()
                                    } else {
                                        showConfirm({
                                            message: "Etwas ist schiefgelaufen. Bitte versuchen Sie es noch ein mal.",
                                            onSuccess: () => {
                                                this.onClick();
                                                },
                                                hideCancelButton: true,
                                                });
                                            }
                                        });
                                }
                            });
            }
            const errorCallback = (message: any) => {
                this.base64 = "";
                if (store.state.customerInfo.username.toLowerCase() == "marko.kovacevic@scopevisio.com" || 
                        store.state.customerInfo.username.toLowerCase() == "developer.internal@scopevisio.com" || 
                            store.state.customerInfo.username.toLowerCase() == "nicole.schoenenberg@scopevisio.com") {    
                    this.handleDevPhoto();
                }
                showNotification({ type: NotificationType.ERROR, message: "Scan-Fehler: " + message })
            }
            // we run on the emulator or on the device here, use
            // the api of the cordova-plugin-document-scanner
            scan.scanDoc(
                successCallback,
                errorCallback,
                {
                    sourceType: 1,
                    fileName: "myfilename",
                    quality: 2.0,
                    returnBase64: true
                });
        } else {
            /*
            * scan is not defined, we run in the browser thus:
            * here we simulate the process when running in the browser 
            * this means in chrome, not even inside a cordova
            */
           this.handleDevPhoto();
        }


    }

    // onClick() {
    //     this.fileUpload = false;
    //     this.fileName = "";
        
    //     const scan = (window as any).scan
    //     const camera = (window.navigator as any).camera
    //     console.log("scan: " + scan);
    //     console.log("camera: " + camera)
    //     const store = this.$store as Store<StateType>
        // if (scan) {
        //     const successCallback = async (imageData: any) => {
        //         const img: HTMLImageElement = document.createElement('img')
        //         img.src = "data:image/jpeg;base64," + imageData;
        //         this.images = [img];
        //         this.base64 = imageData
        //         //cibst image = this.$refs['scanimage'] as HTMLImageElement;
        //         //image.src = "data:image/jpeg;base64," + imageData; // Base64 rendering
        //         this.$emit("photo", this);
        //         this.$forceUpdate()
        //     }
        //     const errorCallback = (message: any) => {
        //         this.base64 = "";
        //         if (store.state.customerInfo.username.toLowerCase() == "marko.kovacevic@scopevisio.com" || 
        //                 store.state.customerInfo.username.toLowerCase() == "developer.internal@scopevisio.com" || 
        //                     store.state.customerInfo.username.toLowerCase() == "nicole.schoenenberg@scopevisio.com") {    
        //             this.handleDevPhoto();
        //         }
        //         showNotification({ type: NotificationType.ERROR, message: "Scan-Fehler: " + message })
        //     }
        //     // we run on the emulator or on the device here, use
        //     // the api of the cordova-plugin-document-scanner
        //     scan.scanDoc(
        //         successCallback,
        //         errorCallback,
        //         {
        //             sourceType: 1,
        //             fileName: "myfilename",
        //             quality: 2.0,
        //             returnBase64: true
        //         });
        // } else {
        //     /*
        //     * scan is not defined, we run in the browser thus:
        //     * here we simulate the process when running in the browser 
        //     * this means in chrome, not even inside a cordova
        //     */
        //    this.handleDevPhoto();
        // }

    //     // sourceType will by default take value 1 if no value is set | 0 for gallery | 1 for camera. 
    //     // fileName will take default value "image" if no value set. Supported only on 4.x.x plugin version
    //     // quality will take default value 1.0 (highest). Lowest value is 5.0. Any value in between will be accepted
    //     // returnBase64 will take default boolean value false, meaning image URL is returned. If true base64 is returned
    // }

    async onLoadDocument() {
        this.fileUpload = true;
        if (this.uploadFile) {
            this.fileName = this.uploadFile.name;

            try {
                this.$forceUpdate()
                const base64 = await Util.readFile(this.uploadFile)
                const img: HTMLImageElement = document.createElement('img')
                img.src = base64;
                this.images = [img];
                this.base64 = base64;
                this.$emit("photo", this);
                this.$forceUpdate();
            } catch (e) {
                console.error("failed to read file: " + e)
            }
        } else {
            console.log("file not given");
        }
    }
}
