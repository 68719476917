
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoadingTransition extends Vue {
    enabled = false;

    enable() {
        this.enabled = true;
    }

    disable() {
        this.enabled = false;
    }
}
