
import { AppContext } from "@/util/context";
import {
  CenterdeviceComment,
  CenterdeviceDocument,
  MetadataAddExtra,
} from "@/util/centerdevice";
import { CenterdeviceUtil } from "@/util/centerdeviceutil";
import { Component, Vue, Prop } from "vue-property-decorator";
import EmptyImage from "../../public/images/empty.png";
import Forward from "../../public/images/forward.svg";
import { HypatosResponse, NotificationType } from "@/util/scopevisiotypes";
import MenuDotted from "../../public/images/menu-dotted.svg";
import { Store } from "vuex";
import { showConfirm, showNotification, showSave } from "@/util/eventbus";
import store, { StateType } from "@/store/store";
import { SvDocument } from "@/util/documenttypes";
import { Util } from "@/util/util";
import { ScopeUtil } from "@/util/scopeutil";
import { CenterdeviceCache } from "@/util/centerdevicecache";

export type ViewMode = "commentnothing" | "commentadd" | "commentedit";

@Component
export default class DocumentDetails extends Vue {
  @Prop({ type: Object as () => CenterdeviceDocument }) readonly selectedDoc!: CenterdeviceDocument;
  @Prop({ type: Object as () => MetadataAddExtra }) readonly subMetadata!: MetadataAddExtra;
  @Prop({ type: Object as () => MetadataAddExtra }) readonly subSavedMetadata!: MetadataAddExtra;
  activedoc: CenterdeviceDocument = {
    id: "",
    owner: "",
    "upload-date": "",
    version: 0,
    uploader: "",
    filename: "",
    size: 0,
    "version-date": "",
    mimetype: "",
    title: "",
    author: "",
    pages: 0,
    representations: {
      pdf: "yes",
      fulltext: "yes",
      jpg: "yes",
      png: "yes",
      mp4: "yes",
    },
    users: {
      visible: [],
      "not-visible-count": 0,
    },
    groups: {
      visible: [],
      "not-visible-count": 0,
    },
    collections: {
      visible: [],
      "not-visible-count": 0,
    },
    folders: [],
    tags: [],
    locks: [],
    "locked-by": "",
    link: "",
    comments: 0,
    "version-details": [],
    "extended-metadata": "",
  };
  belegboxMode = false;
  comments: CenterdeviceComment[] = [];
  deletedComment: CenterdeviceComment = {
    id: "",
    author: "",
    "creation-date": "",
    "edit-date": "",
    text: "",
  };
  // Document Infos
  documentName = "";
  documentDate= "";
  documentType= "Reise";
  documentRefundable = true;
  documentTravel = true;
  documentTravelAllowance = "";
  savedDocumentType= "Reise";
  savedDocumentRefundable = true;
  savedDocumentTravel = true;
  savedDocumentTravelAllowance = "";
  showSaveButton = true;
  editedComment: CenterdeviceComment = {
    id: "",
    author: "",
    "creation-date": "",
    "edit-date": "",
    text: "",
  };
  editSelectedComment = "";
  forward = Forward;
  menudotted = MenuDotted;
  metadata: MetadataAddExtra = {
    Scopevisio_TravelEntry_CompanyName: "",
    Scopevisio_TravelEntry_ReceiptDate: "",
    Scopevisio_TravelEntry_ReceiptNumber: "",
    Scopevisio_TravelEntry_Amount: "",
    Scopevisio_TravelEntry_TaxAmount: "",
    Scopevisio_TravelEntry_Rate: "",
    Scopevisio_TravelEntry_ReceiptType: "",
    Scopevisio_TravelEntry_TravelAbsenceDuration: "",
    Scopevisio_TravelEntry_TravelNumberOfNights: "",
    Scopevisio_TravelEntry_TravelCountry: "",
    Scopevisio_TravelEntry_ProjectId: "",
    Scopevisio_TravelEntry_Refundable: "",
    Scopevisio_TravelEntry_Type: undefined,
    Scopevisio_TravelEntry_RefundOption: "",
    "Scopevisio_Eigene Gesellschaft": "",
    Scopevisio_TravelEntry_PositionValue: "",
  };
  savedMetadata: MetadataAddExtra = {
    Scopevisio_TravelEntry_CompanyName: "",
    Scopevisio_TravelEntry_ReceiptDate: "",
    Scopevisio_TravelEntry_ReceiptNumber: "",
    Scopevisio_TravelEntry_Amount: "",
    Scopevisio_TravelEntry_TaxAmount: "",
    Scopevisio_TravelEntry_Rate: "",
    Scopevisio_TravelEntry_ReceiptType: "",
    Scopevisio_TravelEntry_TravelAbsenceDuration: "",
    Scopevisio_TravelEntry_TravelNumberOfNights: "",
    Scopevisio_TravelEntry_TravelCountry: "",
    Scopevisio_TravelEntry_ProjectId: "",
    Scopevisio_TravelEntry_Refundable: "",
    Scopevisio_TravelEntry_Type: undefined,
    Scopevisio_TravelEntry_RefundOption: "",
    "Scopevisio_Eigene Gesellschaft": "",
    Scopevisio_TravelEntry_PositionValue: "",
  };
  newComment = "";
  viewMode: ViewMode = "commentnothing";
  currentDocument: SvDocument | null = null;

  mounted() {
    const storeAll = this.$store as Store<StateType>;
    if (this.selectedDoc != null) {
      this.activedoc = this.selectedDoc;
      storeAll .commit("currentCDDocument", this.activedoc);
    } else {
      this.activedoc = storeAll .state.currentCDDocument;
    }
    const tab1 = this.$el.querySelector("#tab1") as HTMLElement;
    if (this.belegboxMode) {
      tab1.style.width = "100vw";
    }
    this.currentDocument = store.state.currentDocument;
    if (this.currentDocument) {
      const thumb = this.$el.querySelector(
        ".preview-image"
      ) as HTMLImageElement;
      thumb.src = this.currentDocument.images[0].src;
      this.metadata.Scopevisio_TravelEntry_CompanyName = ( store.state.imgInfo as HypatosResponse ).entities.vendor.companyName;
      this.metadata.Scopevisio_TravelEntry_ReceiptDate = (store.state.imgInfo as HypatosResponse).entities.date;
      this.metadata.Scopevisio_TravelEntry_ReceiptNumber = ( store.state.imgInfo as HypatosResponse ).entities.vendor.receiptNumber;
      this.metadata.Scopevisio_TravelEntry_Amount = ( store.state.imgInfo as HypatosResponse ).entities.payment.amount + "€";
      this.metadata.Scopevisio_TravelEntry_TaxAmount = ( store.state.imgInfo as HypatosResponse ).entities.totals.tax[0].amount + "€";
      this.metadata.Scopevisio_TravelEntry_Rate = ( store.state.imgInfo as HypatosResponse ).entities.totals.tax[0].rate + "% Mwst";
    } else {
      this.createDynamicIMG();
    }
    this.fetchComments();
    this.getInfos();
    this.checkUndefined();
    // Testweise Einsetzen der Metadaten
    // this.setMetadata ("Obi GmbH", "26.05.2021", "36381631", "17,57€", "2,81€", "19% Mwst", "Fahrtkosten", "24", "0", "Deutschland", "876876 - Projekt 1", "Ja", "travel", "Bar", "Scopevisio AG");
    if(store.state.routedFromSubSite){
      // nehme die Werte der anderen Seite an
      this.metadata = this.subMetadata;
      this.getRefundable(this.metadata.Scopevisio_TravelEntry_Refundable, "metadata");
      this.getTravelAllowance(this.metadata.Scopevisio_TravelEntry_TravelAbsenceDuration, this.metadata.Scopevisio_TravelEntry_TravelNumberOfNights, "metadata");
      this.getType(this.metadata.Scopevisio_TravelEntry_Type, "metadata");
      this.savedMetadata = this.subSavedMetadata;
      this.getRefundable(this.savedMetadata.Scopevisio_TravelEntry_Refundable, "savedmetadata");
      this.getTravelAllowance(this.savedMetadata.Scopevisio_TravelEntry_TravelAbsenceDuration, this.savedMetadata.Scopevisio_TravelEntry_TravelNumberOfNights, "savedmetadata");
      this.getType(this.savedMetadata.Scopevisio_TravelEntry_Type, "savedmetadata");
      storeAll.commit("routedFromSubSite", false);
      this.setSaveButton();
    }
    else{      
      this.getMetadata();
    }
  }

  async addComment() {
    if (!this.newComment || this.newComment.length < 1) return;
    CenterdeviceUtil.addComment(this.activedoc?.id, this.newComment)
      .then((response) => {
        if (response) {
          this.fetchComments();
        }
      })
      .catch((reason) => {
        console.error("failed to add comment: " + reason);
        alert(
          "Kommentar konnte nicht hinzugefügt werden. Code=" + reason.status
        );
        return;
      });
    this.newComment = "";
    this.viewMode = "commentnothing"; 
  }

  changeAndSetSaveButton(){
    this.checkUndefined();
    this.setSaveButton();
  }

  changeRefundable(){
    if(this.documentRefundable){
      this.metadata.Scopevisio_TravelEntry_Refundable = "Ja";
    }
    else{
      this.metadata.Scopevisio_TravelEntry_Refundable = "Nein";
    }
    this.setSaveButton();

  }

  changeType(){
    if(this.documentType=="Reise"){
      this.documentType="Spesen";
      this.metadata.Scopevisio_TravelEntry_Type = "expense";
    }
    else{
      this.documentType="Reise";
      this.metadata.Scopevisio_TravelEntry_Type = "travel";
    }
    this.setSaveButton();
  }

  checkUndefined(){
      if(this.metadata.Scopevisio_TravelEntry_CompanyName == undefined || this.metadata.Scopevisio_TravelEntry_CompanyName == ""){
        this.metadata.Scopevisio_TravelEntry_CompanyName = "Belegname ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_ReceiptDate == undefined || this.metadata.Scopevisio_TravelEntry_ReceiptDate == ""){
        this.metadata.Scopevisio_TravelEntry_ReceiptDate = "Belegdatum ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_ReceiptNumber == undefined || this.metadata.Scopevisio_TravelEntry_ReceiptNumber == ""){
        this.metadata.Scopevisio_TravelEntry_ReceiptNumber= "Belegnummer ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_Amount == undefined || this.metadata.Scopevisio_TravelEntry_Amount == ""){
        this.metadata.Scopevisio_TravelEntry_Amount= "Gesamtpreis ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_TaxAmount == undefined || this.metadata.Scopevisio_TravelEntry_TaxAmount == ""){
        this.metadata.Scopevisio_TravelEntry_TaxAmount= "Steuerbetrag ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_Rate == undefined || this.metadata.Scopevisio_TravelEntry_Rate == ""){
        this.metadata.Scopevisio_TravelEntry_Rate= "Steuersatz ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_ProjectId == undefined || this.metadata.Scopevisio_TravelEntry_ProjectId == ""){
        this.metadata.Scopevisio_TravelEntry_ProjectId = "Reise wählen ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_RefundOption == undefined || this.metadata.Scopevisio_TravelEntry_RefundOption == ""){
        this.metadata.Scopevisio_TravelEntry_RefundOption = "Erstattungsoption wählen ..."
      }
      if(this.metadata.Scopevisio_TravelEntry_ReceiptType == undefined || this.metadata.Scopevisio_TravelEntry_ReceiptType == ""){
        this.metadata.Scopevisio_TravelEntry_ReceiptType = "Bitte Belegtyp wählen ..."
      }
  }

  closeEdit() {
    this.viewMode = "commentnothing";
  }

  copyMetadata(){
    this.savedMetadata.Scopevisio_TravelEntry_CompanyName=this.metadata.Scopevisio_TravelEntry_CompanyName;
    this.savedMetadata.Scopevisio_TravelEntry_ReceiptDate=this.metadata.Scopevisio_TravelEntry_ReceiptDate;
    this.savedMetadata.Scopevisio_TravelEntry_ReceiptNumber=this.metadata.Scopevisio_TravelEntry_ReceiptNumber;
    this.savedMetadata.Scopevisio_TravelEntry_Amount=this.metadata.Scopevisio_TravelEntry_Amount;
    this.savedMetadata.Scopevisio_TravelEntry_TaxAmount=this.metadata.Scopevisio_TravelEntry_TaxAmount;
    this.savedMetadata.Scopevisio_TravelEntry_Rate=this.metadata.Scopevisio_TravelEntry_Rate;
    this.savedMetadata.Scopevisio_TravelEntry_ReceiptType=this.metadata.Scopevisio_TravelEntry_ReceiptType;
    this.savedMetadata.Scopevisio_TravelEntry_TravelAbsenceDuration=this.metadata.Scopevisio_TravelEntry_TravelAbsenceDuration;
    this.savedMetadata.Scopevisio_TravelEntry_TravelNumberOfNights=this.metadata.Scopevisio_TravelEntry_TravelNumberOfNights;
    this.savedMetadata.Scopevisio_TravelEntry_TravelCountry=this.metadata.Scopevisio_TravelEntry_TravelCountry;
    this.savedMetadata.Scopevisio_TravelEntry_ProjectId=this.metadata.Scopevisio_TravelEntry_ProjectId;
    this.savedMetadata.Scopevisio_TravelEntry_Refundable=this.metadata.Scopevisio_TravelEntry_Refundable;
    this.savedMetadata.Scopevisio_TravelEntry_Type=this.metadata.Scopevisio_TravelEntry_Type;
    this.savedMetadata.Scopevisio_TravelEntry_RefundOption=this.metadata.Scopevisio_TravelEntry_RefundOption;
     this.savedMetadata.Scopevisio_TravelEntry_PositionValue=this.metadata.Scopevisio_TravelEntry_PositionValue;
    this.savedDocumentType=this.documentType;
    this.savedDocumentRefundable=this.documentRefundable;
    this.savedDocumentTravel=this.documentTravel;
    this.savedDocumentTravelAllowance=this.documentTravelAllowance;
  }

  createDynamicIMG(): void {
    const accessToken = AppContext.instance.getAccessToken();
    const headers = {
      Authorization: "Bearer " + accessToken,
      Accept: "image/jpeg, image/png",
    };
    const imageElement = this.$el.querySelector(
      ".preview-image"
    ) as HTMLImageElement;
    if (!imageElement) {
      return;
    }
    imageElement.src = EmptyImage;

    AppContext.instance
      .callGet(
        "teamworkbridge/document/" +
          this.activedoc?.id +
          ";preview=" +
          240 +
          "x" +
          240 +
          ";pages=1",
        headers,
        {}
      )
      .then((res) => res.blob())
      .then((blob) => {
        if (imageElement && imageElement.parentElement) {
          imageElement.parentElement.style.opacity = "1";
          imageElement.parentElement.style.transform = "none";
          imageElement.src = URL.createObjectURL(blob);
        }
      });
  }

  async deleteFile() {
    showConfirm({
      message: "Möchten Sie den Beleg wirklich löschen?",
      onSuccess: () => {
        this.deleteFileConfirm();
      },
    });
  }

  async deleteComment(comment: CenterdeviceComment) {
    showConfirm({
      message: "Möchten Sie den Kommentar wirklich löschen?",
      onSuccess: () => {
        this.deletedComment = comment;
        this.deleteCommentConfirm();
      },
    });
  }

  deleteCommentConfirm() {
    CenterdeviceUtil.deleteComment(this.activedoc?.id, this.deletedComment.id)
      .then((response) => {
        if (response) {
          this.fetchComments();
        }
      })
      .catch((reason) => {
        console.error("failed to delete comment: " + reason);
        alert("Kommentar konnte nicht gelöscht werden. Code=" + reason.status);
        return;
      });
  }

  async deleteFileConfirm() {
    CenterdeviceUtil.delete(this.activedoc?.id)
      .then((response) => {
        if (response) {
          this.$router.push("../documents");
        }
      })
      .catch((reason) => {
        console.error(
          "failed to delete file: " + reason.status + " " + reason.statusText
        );
        alert("Der Beleg konnte nicht gelöscht werden. Code=" + reason.status);
      });
  }

  async editComment(comment: CenterdeviceComment) {
    this.viewMode = "commentedit";
    this.editedComment = comment;
    this.editSelectedComment = comment.text;
    this.$nextTick(() => {
      const edith = this.$refs.editRef as any;
      edith.focus();
    });
  }

  async fetchComments() {
    CenterdeviceUtil.loadCommentsWorker(this.activedoc?.id)
      .then((response) => {
        if (response) {
          this.comments = response;
          this.$forceUpdate();
        }
      })
      .catch((reason) => {
        console.error("failed to load comments: " + reason);
        alert("Kommentare konnten nicht geladen werden. Code=" + reason.status);
      });
  }

  async getInfos() {
    this.documentName = this.activedoc?.filename;
    this.documentDate = Util.formatDate(
      new Date(this.activedoc?.["upload-date"]).getTime()
    );
    if (this.activedoc?.id) {
      const response = await CenterdeviceUtil.getMetadata(this.activedoc?.id);
      if (Math.floor(response.status / 100) != 2) {
        console.error("failed to load metadata: " + response.status);
        return;
      }
    }
  }

  async getMetadata() {
    if (this.activedoc?.id) {
      const response = await CenterdeviceUtil.getMetadata(this.activedoc?.id);
      if (Math.floor(response.status / 100) != 2) {
        console.error("failed to load metadata: " + response.status);
        return;
      }
      const json: CenterdeviceDocument = await response.json();
      this.metadata.Scopevisio_TravelEntry_CompanyName = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_CompanyName;
      this.metadata.Scopevisio_TravelEntry_ReceiptDate = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_ReceiptDate;
      this.metadata.Scopevisio_TravelEntry_ReceiptNumber = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_ReceiptNumber;
      this.metadata.Scopevisio_TravelEntry_Amount = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_Amount;
      this.metadata.Scopevisio_TravelEntry_TaxAmount = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_TaxAmount;
      this.metadata.Scopevisio_TravelEntry_Rate = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_Rate;
      this.metadata.Scopevisio_TravelEntry_ReceiptType = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_ReceiptType;
      this.metadata.Scopevisio_TravelEntry_TravelAbsenceDuration= json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_TravelAbsenceDuration;
      this.metadata.Scopevisio_TravelEntry_TravelNumberOfNights= json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_TravelNumberOfNights;
      this.metadata.Scopevisio_TravelEntry_TravelCountry = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_TravelCountry;
      this.metadata.Scopevisio_TravelEntry_ProjectId = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_ProjectId;
      this.metadata.Scopevisio_TravelEntry_Refundable = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_Refundable;
      this.metadata.Scopevisio_TravelEntry_Type = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_Type;
      this.metadata.Scopevisio_TravelEntry_RefundOption = json[ "extended-metadata" ].DESCRIPTION?.extra?.Scopevisio_TravelEntry_RefundOption;
      this.metadata["Scopevisio_Eigene Gesellschaft"] = json["extended-metadata"].DESCRIPTION?.extra?.[ "Scopevisio_Eigene Gesellschaft" ];
      this.getType(json["extended-metadata"].DESCRIPTION?.extra ?.Scopevisio_TravelEntry_Type, "metadata");
      this.getRefundable(json["extended-metadata"].DESCRIPTION?.extra ?.Scopevisio_TravelEntry_Refundable, "metadata");
      this.getTravelAllowance(json["extended-metadata"].DESCRIPTION?.extra ?.Scopevisio_TravelEntry_TravelAbsenceDuration, json["extended-metadata"].DESCRIPTION?.extra ?.Scopevisio_TravelEntry_TravelNumberOfNights, "metadata");
      this.checkUndefined();
      this.copyMetadata(); 
    }
  }

  getRefundable( refundable: string | undefined, twooptions: string ){
    if(twooptions == "metadata"){
      if ( refundable == "Ja" ) {
        this.documentRefundable = true;
      } else if ( refundable == "Nein" ) {
        this.documentRefundable = false;
      }
    }
    else{
      if ( refundable == "Ja" ) {
        this.savedDocumentRefundable = true;
      } else if ( refundable == "Nein" ) {
        this.savedDocumentRefundable = false;
      }
    }
  }

  getTravelAllowance(absenceDuration: string, numberOfNights: string, twooptions: string ){
    const absenceDurationInt = parseInt(absenceDuration);
    const numberOfNightsInt = parseInt(numberOfNights);
    const travelAllowanceMoreThan24H = 28+(numberOfNightsInt*20);
    if(twooptions == "metadata"){
      if (absenceDurationInt < 8) {
        this.documentTravelAllowance = "0";
      } else if (absenceDurationInt >= 8 && absenceDurationInt < 24 ) {
        this.documentTravelAllowance = "14";
      } else if (absenceDurationInt >= 24) {
        this.documentTravelAllowance = travelAllowanceMoreThan24H.toString();
      }
    } else{
      if (absenceDurationInt < 8) {
        this.savedDocumentTravelAllowance = "0";
      } else if (absenceDurationInt >= 8 && absenceDurationInt < 24 ) {
        this.savedDocumentTravelAllowance = "14";
      } else if (absenceDurationInt >= 24) {
        this.savedDocumentTravelAllowance = travelAllowanceMoreThan24H.toString();
      }
    }
  }

  getType( type: string | undefined, twooptions: string ){
    if(twooptions == "metadata"){
      if ( type == "expense" ) {
        this.documentType = "Spesen";
        this.documentTravel = false;
      } else if ( type == "travel" ) {
        this.documentType = "Reise";
        this.documentTravel = true;
      }
    }
    else{
      if ( type == "expense" ) {
        this.savedDocumentType = "Spesen";
        this.savedDocumentTravel = false;
      } else if ( type == "travel" ) {
        this.savedDocumentType = "Reise";
        this.savedDocumentTravel = true;
      }
    }
  }

  onProjectId(){
    this.$router.push({
        name: "Projekt",
        params: {
           actualMetadata: this.metadata as any,
           savedActualMetadata: this.savedMetadata as any,
        },
    });
  }  

  onReceiptType(){
    this.$router.push({
        name: "Belegtyp",
        params: {
           actualMetadata: this.metadata as any,
           savedActualMetadata: this.savedMetadata as any,
        },
    });
  }

  onRefundableOption(){
    this.$router.push({
        name: "Erstattungsoption",
        params: {
           actualMetadata: this.metadata as any,
           savedActualMetadata: this.savedMetadata as any,
        },
    });
  }
  /*
  onTravelAllowance(){
    this.$router.push({
        name: "Reisekostenpauschale",
        params: {
           actualMetadata: this.metadata as any,
           savedActualMetadata: this.savedMetadata as any,
        },
    });
  }
  */
  async onSave(){
    // this.savedMetadata.Scopevisio_TravelEntry_CompanyName = this.metadata.Scopevisio_TravelEntry_CompanyName;
    // this.savedMetadata.Scopevisio_TravelEntry_ReceiptDate = this.metadata.Scopevisio_TravelEntry_ReceiptDate;
    // this.savedMetadata.Scopevisio_TravelEntry_ReceiptNumber = this.metadata.Scopevisio_TravelEntry_ReceiptNumber;
    // this.savedMetadata.Scopevisio_TravelEntry_Amount = this.metadata.Scopevisio_TravelEntry_Amount;
    // this.savedMetadata.Scopevisio_TravelEntry_TaxAmount = this.metadata.Scopevisio_TravelEntry_TaxAmount;
    // this.savedMetadata.Scopevisio_TravelEntry_Rate = this.metadata.Scopevisio_TravelEntry_Rate;
    // this.savedMetadata.Scopevisio_TravelEntry_ReceiptType = this.metadata.Scopevisio_TravelEntry_ReceiptType;
    // this.savedMetadata.Scopevisio_TravelEntry_TravelAbsenceDuration = this.metadata.Scopevisio_TravelEntry_TravelAbsenceDuration;
    // this.savedMetadata.Scopevisio_TravelEntry_TravelNumberOfNights = this.metadata.Scopevisio_TravelEntry_TravelNumberOfNights;
    // this.savedMetadata.Scopevisio_TravelEntry_TravelCountry = this.metadata.Scopevisio_TravelEntry_TravelCountry;
    // this.savedMetadata.Scopevisio_TravelEntry_ProjectId = this.metadata.Scopevisio_TravelEntry_ProjectId;
    // this.savedMetadata.Scopevisio_TravelEntry_RefundOption = this.metadata.Scopevisio_TravelEntry_RefundOption;
    // this.setRefundable();
    // this.setType();
    // this.showSaveButton=false;
    // if (this.activedoc?.id) {
    //   CenterdeviceUtil.setMetadata(this.activedoc?.id, this.metadata);
    // }


  if(!this.metadata.Scopevisio_TravelEntry_ReceiptType || this.metadata.Scopevisio_TravelEntry_ReceiptType == ""){
    showNotification({
       type: NotificationType.ERROR,
        message: "Internal-Error: " + "Bitte Belegtype auswählen",
      });
      return false
    }

    if(!store.state.selectedTravelEntry){
      showConfirm({
        message: "Es ist keine Reise ausgewählt. Möchten Sie den Beleg ohne Auswahl einer Reise hochgeladen?",
        onSuccess: () => {
          this.uploadReceipt().then(() => 
            this.$router.push("/documents")
          );
          
        },
        onCancel: () => {
        },
    })
        return false;
    }

    this.createTravelPosition();
    this.uploadReceipt();
    this.$router.push("/documents");

  }

  async createTravelPosition(){
    const accessToken = AppContext.instance.getAccessToken();

    const respose = await ScopeUtil.setNewPostionTravelentry({
      Authorization: "Bearer " + accessToken,
      }, 
      {
        travelEntryId: store.state.selectedTravelEntry.id,
        typeDisplay: this.metadata.Scopevisio_TravelEntry_ReceiptType,
        amount: this.metadata.Scopevisio_TravelEntry_Amount.replace("€", "").replace(",", "."),
        taxRate: 19,
        distance: 0
      },
      this.metadata.Scopevisio_TravelEntry_PositionValue
      );
  }

  async uploadReceipt(){
    const collection = CenterdeviceCache.instance.collections.find(
        (e) => e.name == "Spesen2GoFiles"
      );

      await fetch(this.currentDocument!.images[0].src)
        .then((res) => res.blob())
        .then((blob) => {
          return Util.blobToFile(blob, "testFileJPRG" + ".jpeg");
        })
        .then((currentFile) => {
          return CenterdeviceUtil.upload({
            file: currentFile,
            collectionId: collection?.id,
          });
        })
        .then((response) => {
          //console.log(response);
          this.$router.push("/documents");
        });
  }

  setType(){
    if (this.documentType == "Reise"){
    this.metadata.Scopevisio_TravelEntry_Type = "travel";
    this.savedMetadata.Scopevisio_TravelEntry_Type= "travel";
    this.savedDocumentType = "Reise";
    }
    else{
      this.metadata.Scopevisio_TravelEntry_Type = "expense";
      this.savedMetadata.Scopevisio_TravelEntry_Type = "expense";
      this.savedDocumentType= "Spesen";
    }
  }

  setRefundable(){
    if (this.documentRefundable == true){
      this.metadata.Scopevisio_TravelEntry_Refundable = "Ja";
      this.savedMetadata.Scopevisio_TravelEntry_Refundable = "Ja";
      this.savedDocumentRefundable = true;
    }
    else{
      this.metadata.Scopevisio_TravelEntry_Refundable = "Nein";
      this.savedMetadata.Scopevisio_TravelEntry_Refundable = "Nein";
      this.savedDocumentRefundable = false;
    }
  }

  async saveEdit() {
    CenterdeviceUtil.editComment(
      this.activedoc?.id,
      this.editedComment.id,
      this.editSelectedComment
    )
      .then((response) => {
        if (response) {
          this.fetchComments();
        }
      })
      .catch((reason) => {
        console.error("failed to edit comment: " + reason);
        alert(
          "Kommentar konnte nicht bearbeitet werden. Code=" + reason.status
        );
        return;
      });
    this.closeEdit();
  }

  setSaveButton() {
    if (
      this.metadata.Scopevisio_TravelEntry_CompanyName == this.savedMetadata.Scopevisio_TravelEntry_CompanyName &&
      this.metadata.Scopevisio_TravelEntry_ReceiptDate == this.savedMetadata.Scopevisio_TravelEntry_ReceiptDate &&
      this.metadata.Scopevisio_TravelEntry_ReceiptNumber == this.savedMetadata.Scopevisio_TravelEntry_ReceiptNumber &&
      this.metadata.Scopevisio_TravelEntry_Amount == this.savedMetadata.Scopevisio_TravelEntry_Amount &&
      this.metadata.Scopevisio_TravelEntry_TaxAmount == this.savedMetadata.Scopevisio_TravelEntry_TaxAmount &&
      this.metadata.Scopevisio_TravelEntry_Rate == this.savedMetadata.Scopevisio_TravelEntry_Rate &&
      this.metadata.Scopevisio_TravelEntry_ReceiptType == this.savedMetadata.Scopevisio_TravelEntry_ReceiptType &&
      this.metadata.Scopevisio_TravelEntry_TravelAbsenceDuration == this.savedMetadata.Scopevisio_TravelEntry_TravelAbsenceDuration &&
      this.metadata.Scopevisio_TravelEntry_TravelNumberOfNights == this.savedMetadata.Scopevisio_TravelEntry_TravelNumberOfNights &&
      this.metadata.Scopevisio_TravelEntry_TravelCountry == this.savedMetadata.Scopevisio_TravelEntry_TravelCountry &&
      this.metadata.Scopevisio_TravelEntry_ProjectId == this.savedMetadata.Scopevisio_TravelEntry_ProjectId &&
      this.metadata.Scopevisio_TravelEntry_Refundable == this.savedMetadata.Scopevisio_TravelEntry_Refundable &&
      this.metadata.Scopevisio_TravelEntry_Type == this.savedMetadata.Scopevisio_TravelEntry_Type &&
      this.metadata.Scopevisio_TravelEntry_RefundOption == this.savedMetadata.Scopevisio_TravelEntry_RefundOption &&
      this.metadata.Scopevisio_TravelEntry_PositionValue == this.savedMetadata.Scopevisio_TravelEntry_PositionValue
    ) {
      this.showSaveButton= true;
    } else {
      this.showSaveButton= true;
    }
  }

  showAddComment(){
    this.viewMode = "commentadd";
  }
   
  setMetadata(
    companyName: string,
    receiptDate: string,
    receiptNumber: string,
    amount: string,
    taxAmount: string,
    rate: string,
    receiptType: string,
    absenceDuration: string,
    numberOfNights: string,
    travelCountry: string,
    projectID: string,
    refundable: string,
    type: "expense" | "travel",
    refundOption: "Bar" | "Bankkarte",
    gesellschaft: string,
    positionValue: string
  ) {
    this.metadata.Scopevisio_TravelEntry_CompanyName = companyName;
    this.metadata.Scopevisio_TravelEntry_ReceiptDate = receiptDate;
    this.metadata.Scopevisio_TravelEntry_ReceiptNumber = receiptNumber;
    this.metadata.Scopevisio_TravelEntry_Amount = amount;
    this.metadata.Scopevisio_TravelEntry_TaxAmount = taxAmount;
    this.metadata.Scopevisio_TravelEntry_Rate = rate;
    this.metadata.Scopevisio_TravelEntry_ReceiptType = receiptType;
    this.metadata.Scopevisio_TravelEntry_TravelAbsenceDuration = absenceDuration;
    this.metadata.Scopevisio_TravelEntry_TravelNumberOfNights = numberOfNights;
    this.metadata.Scopevisio_TravelEntry_TravelCountry = travelCountry;
    this.metadata.Scopevisio_TravelEntry_ProjectId = projectID;
    this.metadata.Scopevisio_TravelEntry_Refundable = refundable;
    this.metadata.Scopevisio_TravelEntry_Type = type;
    this.metadata.Scopevisio_TravelEntry_RefundOption = refundOption;
    this.metadata["Scopevisio_Eigene Gesellschaft"] = gesellschaft;
    this.metadata.Scopevisio_TravelEntry_PositionValue = positionValue;
    if (this.activedoc?.id) {
      CenterdeviceUtil.setMetadata(this.activedoc?.id, this.metadata);
    }
  }
}
